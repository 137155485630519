import { ISvgIconProps } from '.';

export const ArrowRightIcon = ({
	className = '',
	viewBox = '0 0 35 30',
	refComponent = undefined,
}: ISvgIconProps) => {
	return (
		<svg
			className={className}
			ref={refComponent}
			viewBox={viewBox}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M20 30C20 21.7157 26.7157 15 35 15"
				stroke="currentColor"
				strokeWidth="3"
			/>
			<path
				d="M20 -4.23195e-06C20 8.28427 26.7157 15 35 15"
				stroke="currentColor"
				strokeWidth="3"
			/>
			<path d="M35 15L-3.8147e-06 15" stroke="currentColor" strokeWidth="3" />
		</svg>
	);
};
