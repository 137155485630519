import { useTranslation } from 'react-i18next';
import bg from '../../../assets/img/card-bg.jpg';
import bgPurple from '../../../assets/img/card-bg.png';
import cl from './card-mission-info.module.scss';

export const CardMissionInfo = ({
	icon,
	text,
	purple = false,
}: {
	icon: JSX.Element;
	text: string;
	purple?: boolean;
}) => {
	const { i18n } = useTranslation();
	return (
		<div className={cl.card + ' ' + (purple ? cl.purple : '')}>
			<div className={cl.bg}>
				<img
					src={purple ? bgPurple : bg}
					alt=""
					loading="lazy"
					decoding="async"
				/>
			</div>

			<div className={cl.icon}>{icon}</div>
			<p
				className={cl.text + ' ' + (purple ? cl.white : '')}
				data-lang={i18n.language}
			>
				{text}
			</p>
		</div>
	);
};
