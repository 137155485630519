import { t } from 'i18next';
import * as Yup from 'yup';

const ERRORS = {
	req: t('error.req'),
	phone: t('error.phone'),
	min: t('error.min'),
	max: t('error.max'),
	email: t('error.email'),
	confirm: t('error.confirm'),
};
const MAX_LEN_FIELD = 150;

export const profileEditPasswordFormSchema = Yup.object().shape({
	password_old: Yup.string().required(ERRORS.req).min(2, ERRORS.min),
	password: Yup.string()
		.required(ERRORS.req)
		.min(2, ERRORS.min)
		.max(MAX_LEN_FIELD, ERRORS.max),
	password_confirm: Yup.string()
		.required(ERRORS.req)
		.oneOf([Yup.ref('password'), ''], ERRORS.confirm),
});

export type ProfileEditPasswordFormData = Yup.InferType<
	typeof profileEditPasswordFormSchema
>;
