export const SCHEDULE_GRID_LOADER = [
	{
		date: 'd-1',
		items: [1, 2, 3, 4],
	},
	{
		date: 'd-2',
		items: [1, 2, 3, 4],
	},
	{
		date: 'd-3',
		items: [1, 2, 3, 4],
	},
	{
		date: 'd-4',
		items: [1, 2, 3, 4],
	},
	{
		date: 'd-5',
		items: [1, 2, 3, 4],
	},
	{
		date: 'd-6',
		items: [1, 2, 3, 4],
	},
	{
		date: 'd-7',
		items: [1, 2, 3, 4],
	},
];
