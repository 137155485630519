import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Preview } from 'src/@types';
import { SECTION_STUDEIO_ITEMS } from 'src/components/sections/section-studio/section-studio.mock';

interface IPracticeMock {
	key: string;
	genre_slug: string;
	post: {
		title: string;
		preview: Preview;
		slug: string;
	};
	banner: {
		title: string;
		preview: Preview;
	};
	info: {
		title: string;
		description: string;
	};
	start: {
		title: string;
		items: {
			preview: Preview;
			title: string;
			subtitle: string;
		}[];
	};
	gallery: {
		title: string;
		items: { img: Preview; title?: string }[];
	};
	posts: {
		title: string;
		description: string;
		items: { preview: Preview; title: string; background: string }[];
	};
	works?: {
		endpoints: number[];
		title: string;
		description: string;
		items: {
			preview: Preview;
			text: string;
		}[];
	};
	reverse?: {
		right?: boolean;
		left?: boolean;
		center?: boolean;
	};
}

export const usePracticeMock = () => {
	const { t } = useTranslation();

	return useMemo(() => {
		const data: { [key: string]: IPracticeMock } = {
			movement: {
				key: 'one',
				genre_slug: 'movement',
				post: {
					slug: 'movement',
					title: t('practice.one.banner.title'),
					preview: [
						{
							type: 'webp',
							uri: '/image/practice/one/preview.webp',
						},
						{
							type: 'jpg',
							uri: '/image/practice/one/preview.png',
						},
					],
				},
				banner: {
					title: t('practice.one.banner.title'),
					preview: [
						{
							type: 'webp',
							uri: '/image/practice/one/banner.jpg',
						},
						{
							type: 'jpg',
							uri: '/image/practice/one/banner.jpg',
						},
					],
				},
				info: {
					title: t('practice.one.info.title'),
					description: t('practice.one.info.description'),
				},
				start: {
					title: t('practice.one.start.title'),
					items: [
						{
							title: t('practice.one.start.title_one'),
							subtitle: t('practice.one.start.desc_one'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/one/s1.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/one/s1.jpg',
								},
							],
						},
						{
							title: t('practice.one.start.title_two'),
							subtitle: t('practice.one.start.desc_two'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/one/s2.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/one/s2.jpg',
								},
							],
						},
						{
							title: t('practice.one.start.title_tree'),
							subtitle: t('practice.one.start.desc_tree'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/one/s3.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/one/s3.jpg',
								},
							],
						},
						{
							title: t('practice.one.start.title_four'),
							subtitle: t('practice.one.start.desc_four'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/one/s4.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/one/s4.jpg',
								},
							],
						},
					],
				},
				gallery: {
					title: t('practice.one.gallery.title'),
					items: [
						{
							img: [
								{
									type: 'webp',
									uri: '/image/practice/one/g1.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/one/g1.png',
								},
							],
							title: '',
						},
						{
							img: [
								{
									type: 'webp',
									uri: '/image/practice/one/g2.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/one/g2.png',
								},
							],
							title: '',
						},
						{
							img: [
								{
									type: 'webp',
									uri: '/image/practice/one/g3.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/one/g3.png',
								},
							],
							title: '',
						},
					],
				},
				posts: {
					title: t('practice.one.posts.title'),
					description: t('practice.one.posts.description'),
					items: [
						{
							title: t('practice.one.posts.item.one'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/one/p2.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/one/p2.jpg',
								},
							],
							background: '/image/practice/backgrounds/1.jpg',
						},
						{
							title: t('practice.one.posts.item.two'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/one/p6.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/one/p6.jpg',
								},
							],
							background: '/image/practice/backgrounds/2.jpg',
						},

						{
							title: t('practice.one.posts.item.tree'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/one/p7.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/one/p7.jpg',
								},
							],
							background: '/image/practice/backgrounds/3.jpg',
						},
						{
							title: t('practice.one.posts.item.four'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/one/p5.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/one/p5.jpg',
								},
							],
							background: '/image/practice/backgrounds/4.jpg',
						},
						{
							title: t('practice.one.posts.item.five'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/one/p3.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/one/p3.jpg',
								},
							],
							background: '/image/practice/backgrounds/5.jpg',
						},
					],
				},
			},
			breathwork: {
				key: 'breathwork',
				genre_slug: 'breathwork',
				post: {
					slug: 'breathwork',
					title: t('practice.breathwork.banner.title'),
					preview: [
						{
							type: 'webp',
							uri: '/image/practice/breathwork/preview.webp',
						},
						{
							type: 'jpg',
							uri: '/image/practice/breathwork/preview.png',
						},
					],
				},
				banner: {
					title: t('practice.breathwork.banner.title'),
					preview: [
						{
							type: 'webp',
							uri: '/image/practice/breathwork/banner.webp',
						},
						{
							type: 'jpg',
							uri: '/image/practice/breathwork/banner.jpg',
						},
					],
				},
				info: {
					title: t('practice.breathwork.info.title'),
					description: t('practice.breathwork.info.description'),
				},
				start: {
					title: t('practice.breathwork.start.title'),
					items: [
						{
							title: t('practice.breathwork.start.title_one'),
							subtitle: t('practice.breathwork.start.desc_one'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/breathwork/s1.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/breathwork/s1.jpg',
								},
							],
						},
						{
							title: t('practice.breathwork.start.title_two'),
							subtitle: t('practice.breathwork.start.desc_two'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/breathwork/s2.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/breathwork/s2.jpg',
								},
							],
						},
						{
							title: t('practice.breathwork.start.title_tree'),
							subtitle: t('practice.breathwork.start.desc_tree'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/breathwork/s3.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/breathwork/s3.jpg',
								},
							],
						},
						{
							title: t('practice.breathwork.start.title_four'),
							subtitle: t('practice.breathwork.start.desc_four'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/breathwork/s4.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/breathwork/s4.jpg',
								},
							],
						},
						{
							title: t('practice.breathwork.start.title_tree'),
							subtitle: t('practice.breathwork.start.desc_tree'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/breathwork/s3.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/breathwork/s3.jpg',
								},
							],
						},
					],
				},
				gallery: {
					title: t('practice.breathwork.gallery.title'),
					items: [
						{
							img: [
								{
									type: 'webp',
									uri: '/image/practice/breathwork/g1.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/breathwork/g1.jpeg',
								},
							],
							title: '',
						},
						{
							img: [
								{
									type: 'webp',
									uri: '/image/practice/breathwork/g2.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/breathwork/g2.png',
								},
							],
							title: '',
						},
						{
							img: [
								{
									type: 'webp',
									uri: '/image/practice/breathwork/g3.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/breathwork/g3.png',
								},
							],
							title: '',
						},
						{
							img: [
								{
									type: 'webp',
									uri: '/image/practice/breathwork/g4.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/breathwork/g4.png',
								},
							],
							title: '',
						},
						{
							img: [
								{
									type: 'webp',
									uri: '/image/practice/breathwork/g5.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/breathwork/g5.jpeg',
								},
							],
							title: '',
						},
					],
				},
				posts: {
					title: t('practice.breathwork.posts.title'),
					description: t('practice.breathwork.posts.description'),
					items: [
						{
							title: t('practice.breathwork.posts.item.one'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/breathwork/p1.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/breathwork/p1.jpg',
								},
							],
							background: '/image/practice/backgrounds/1.jpg',
						},
						{
							title: t('practice.breathwork.posts.item.two'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/breathwork/p2.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/breathwork/p2.jpg',
								},
							],
							background: '/image/practice/backgrounds/2.jpg',
						},

						{
							title: t('practice.breathwork.posts.item.tree'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/breathwork/p3.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/breathwork/p3.jpg',
								},
							],
							background: '/image/practice/backgrounds/3.jpg',
						},
						{
							title: t('practice.breathwork.posts.item.five'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/breathwork/p5.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/breathwork/p5.jpg',
								},
							],
							background: '/image/practice/backgrounds/4.jpg',
						},
						{
							title: t('practice.breathwork.posts.item.four'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/breathwork/p4.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/breathwork/p4.jpg',
								},
							],
							background: '/image/practice/backgrounds/5.jpg',
						},
						{
							title: t('practice.breathwork.posts.item.six'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/breathwork/p6.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/breathwork/p6.jpg',
								},
							],
							background: '/image/practice/backgrounds/6.jpg',
						},
					],
				},
			},
			'tea-ceremonies': {
				genre_slug: 'tea-ceremonies',
				key: 'tea-ceremonies',
				post: {
					slug: 'tea-ceremonies',
					title: t('practice.tea-ceremonies.banner.title'),
					preview: [
						{
							type: 'webp',
							uri: '/image/practice/tea-ceremonies/preview.webp',
						},
						{
							type: 'jpg',
							uri: '/image/practice/tea-ceremonies/preview.png',
						},
					],
				},
				banner: {
					title: t('practice.tea-ceremonies.banner.title'),
					preview: [
						{
							type: 'webp',
							uri: '/image/practice/tea-ceremonies/banner.webp',
						},
						{
							type: 'jpg',
							uri: '/image/practice/tea-ceremonies/banner.jpg',
						},
					],
				},
				info: {
					title: t('practice.tea-ceremonies.info.title'),
					description: t('practice.tea-ceremonies.info.description'),
				},
				start: {
					title: t('practice.tea-ceremonies.start.title'),
					items: [
						{
							title: t('practice.tea-ceremonies.start.title_one'),
							subtitle: t('practice.tea-ceremonies.start.desc_one'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/tea-ceremonies/s1.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/tea-ceremonies/s1.jpg',
								},
							],
						},
						{
							title: t('practice.tea-ceremonies.start.title_two'),
							subtitle: t('practice.tea-ceremonies.start.desc_two'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/tea-ceremonies/s2.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/tea-ceremonies/s2.jpg',
								},
							],
						},
						{
							title: t('practice.tea-ceremonies.start.title_tree'),
							subtitle: t('practice.tea-ceremonies.start.desc_tree'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/tea-ceremonies/s3.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/tea-ceremonies/s3.jpg',
								},
							],
						},
						{
							title: t('practice.tea-ceremonies.start.title_four'),
							subtitle: t('practice.tea-ceremonies.start.desc_four'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/tea-ceremonies/s4.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/tea-ceremonies/s4.jpg',
								},
							],
						},
					],
				},
				gallery: {
					title: t('practice.tea-ceremonies.gallery.title'),
					items: SECTION_STUDEIO_ITEMS,
				},
				posts: {
					title: t('practice.tea-ceremonies.posts.title'),
					description: t('practice.tea-ceremonies.posts.description'),
					items: [
						{
							title: t('practice.tea-ceremonies.posts.item.two'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/tea-ceremonies/p2.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/tea-ceremonies/p2.jpg',
								},
							],
							background: '/image/practice/backgrounds/1.jpg',
						},
						{
							title: t('practice.tea-ceremonies.posts.item.one'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/tea-ceremonies/p1.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/tea-ceremonies/p1.jpg',
								},
							],
							background: '/image/practice/backgrounds/2.jpg',
						},
						{
							title: t('practice.tea-ceremonies.posts.item.tree'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/tea-ceremonies/p3.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/tea-ceremonies/p3.jpg',
								},
							],
							background: '/image/practice/backgrounds/3.jpg',
						},
						{
							title: t('practice.tea-ceremonies.posts.item.five'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/tea-ceremonies/p5.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/tea-ceremonies/p5.jpg',
								},
							],
							background: '/image/practice/backgrounds/4.jpg',
						},
						{
							title: t('practice.tea-ceremonies.posts.item.four'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/tea-ceremonies/p4.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/tea-ceremonies/p4.jpg',
								},
							],
							background: '/image/practice/backgrounds/5.jpg',
						},
					],
				},
			},
			'sound-healing': {
				genre_slug: 'sound-healing',
				key: 'sound-healing',
				post: {
					slug: 'sound-healing',
					title: t('practice.sound-healing.banner.title'),
					preview: [
						{
							type: 'webp',
							uri: '/image/practice/sound-healing/preview.webp',
						},
						{
							type: 'jpg',
							uri: '/image/practice/sound-healing/preview.png',
						},
					],
				},
				banner: {
					title: t('practice.sound-healing.banner.title'),
					preview: [
						{
							type: 'webp',
							uri: '/image/practice/sound-healing/banner.webp',
						},
						{
							type: 'jpg',
							uri: '/image/practice/sound-healing/banner.jpg',
						},
					],
				},
				info: {
					title: t('practice.sound-healing.info.title'),
					description: t('practice.sound-healing.info.description'),
				},
				start: {
					title: t('practice.sound-healing.start.title'),
					items: [
						{
							title: t('practice.sound-healing.start.title_one'),
							subtitle: t('practice.sound-healing.start.desc_one'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/sound-healing/s1.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/sound-healing/s1.jpg',
								},
							],
						},
						{
							title: t('practice.sound-healing.start.title_two'),
							subtitle: t('practice.sound-healing.start.desc_two'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/sound-healing/s2.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/sound-healing/s2.jpg',
								},
							],
						},
						{
							title: t('practice.sound-healing.start.title_tree'),
							subtitle: t('practice.sound-healing.start.desc_tree'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/sound-healing/s3.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/sound-healing/s3.jpg',
								},
							],
						},
						{
							title: t('practice.sound-healing.start.title_four'),
							subtitle: t('practice.sound-healing.start.desc_four'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/sound-healing/s4.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/sound-healing/s4.jpg',
								},
							],
						},
					],
				},
				gallery: {
					title: t('practice.sound-healing.gallery.title'),
					items: [
						{
							img: [
								{
									type: 'webp',
									uri: '/image/practice/sound-healing/g1.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/sound-healing/g1.jpeg',
								},
							],
							title: '',
						},
						{
							img: [
								{
									type: 'webp',
									uri: '/image/practice/sound-healing/g2.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/sound-healing/g2.png',
								},
							],
							title: '',
						},
						{
							img: [
								{
									type: 'webp',
									uri: '/image/practice/sound-healing/g3.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/sound-healing/g3.png',
								},
							],
							title: '',
						},
						{
							img: [
								{
									type: 'webp',
									uri: '/image/practice/sound-healing/g4.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/sound-healing/g4.png',
								},
							],
							title: '',
						},
						{
							img: [
								{
									type: 'webp',
									uri: '/image/practice/sound-healing/g5.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/sound-healing/g5.jpeg',
								},
							],
							title: '',
						},
					],
				},
				posts: {
					title: t('practice.sound-healing.posts.title'),
					description: t('practice.sound-healing.posts.description'),
					items: [
						{
							title: t('practice.sound-healing.posts.item.one'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/sound-healing/p2.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/sound-healing/p2.jpg',
								},
							],
							background: '/image/practice/backgrounds/1.jpg',
						},
						{
							title: t('practice.sound-healing.posts.item.two'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/sound-healing/p1.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/sound-healing/p1.jpg',
								},
							],
							background: '/image/practice/backgrounds/2.jpg',
						},
						{
							title: t('practice.sound-healing.posts.item.tree'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/sound-healing/p3.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/sound-healing/p3.jpg',
								},
							],
							background: '/image/practice/backgrounds/3.jpg',
						},
						{
							title: t('practice.sound-healing.posts.item.five'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/sound-healing/p5.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/sound-healing/p5.jpg',
								},
							],
							background: '/image/practice/backgrounds/4.jpg',
						},
						{
							title: t('practice.sound-healing.posts.item.four'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/sound-healing/p4.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/sound-healing/p4.jpg',
								},
							],
							background: '/image/practice/backgrounds/5.jpg',
						},
					],
				},
				reverse: {
					left: true,
				},
			},
			'guided-meditations': {
				genre_slug: 'guided-meditations',
				key: 'guided-meditations',
				post: {
					slug: 'guided-meditations',
					title: t('practice.guided-meditations.banner.title'),
					preview: [
						{
							type: 'webp',
							uri: '/image/practice/guided-meditations/preview.webp',
							media: '(min-width: 1280px)',
						},
						{
							type: 'jpg',
							uri: '/image/practice/guided-meditations/preview.png',
							media: '(min-width: 1280px)',
						},
						{
							type: 'webp',
							uri: '/image/practice/guided-meditations/preview-mob.webp',
							media: '(min-width: 0px)',
						},
						{
							type: 'jpg',
							uri: '/image/practice/guided-meditations/preview-mob.png',
							media: '(min-width: 0px)',
						},
					],
				},
				banner: {
					title: t('practice.guided-meditations.banner.title'),
					preview: [
						{
							type: 'webp',
							uri: '/image/practice/guided-meditations/banner.webp',
						},
						{
							type: 'jpg',
							uri: '/image/practice/guided-meditations/banner.jpg',
						},
					],
				},
				info: {
					title: t('practice.guided-meditations.info.title'),
					description: t('practice.guided-meditations.info.description'),
				},
				start: {
					title: t('practice.guided-meditations.start.title'),
					items: [
						{
							title: t('practice.guided-meditations.start.title_one'),
							subtitle: t('practice.guided-meditations.start.desc_one'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/guided-meditations/s1.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/guided-meditations/s1.jpg',
								},
							],
						},
						{
							title: t('practice.guided-meditations.start.title_two'),
							subtitle: t('practice.guided-meditations.start.desc_two'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/guided-meditations/s2.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/guided-meditations/s2.jpg',
								},
							],
						},
						{
							title: t('practice.guided-meditations.start.title_tree'),
							subtitle: t('practice.guided-meditations.start.desc_tree'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/guided-meditations/s3.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/guided-meditations/s3.jpg',
								},
							],
						},
						{
							title: t('practice.guided-meditations.start.title_four'),
							subtitle: t('practice.guided-meditations.start.desc_four'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/guided-meditations/s4.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/guided-meditations/s4.jpg',
								},
							],
						},
					],
				},
				gallery: {
					title: t('practice.guided-meditations.gallery.title'),
					items: [
						{
							img: [
								{
									type: 'webp',
									uri: '/image/practice/guided-meditations/g1.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/guided-meditations/g1.jpeg',
								},
							],
							title: '',
						},
						{
							img: [
								{
									type: 'webp',
									uri: '/image/practice/guided-meditations/g2.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/guided-meditations/g2.png',
								},
							],
							title: '',
						},
						{
							img: [
								{
									type: 'webp',
									uri: '/image/practice/guided-meditations/g3.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/guided-meditations/g3.jpeg',
								},
							],
							title: '',
						},
						{
							img: [
								{
									type: 'webp',
									uri: '/image/practice/guided-meditations/g4.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/guided-meditations/g4.jpeg',
								},
							],
							title: '',
						},
						{
							img: [
								{
									type: 'webp',
									uri: '/image/practice/guided-meditations/g5.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/guided-meditations/g5.png',
								},
							],
							title: '',
						},
					],
				},
				posts: {
					title: t('practice.guided-meditations.posts.title'),
					description: t('practice.guided-meditations.posts.description'),
					items: [
						{
							title: t('practice.guided-meditations.posts.item.one'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/guided-meditations/p6.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/guided-meditations/p6.jpg',
								},
							],
							background: '/image/practice/backgrounds/1.jpg',
						},
						{
							title: t('practice.guided-meditations.posts.item.two'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/guided-meditations/p2.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/guided-meditations/p2.jpg',
								},
							],
							background: '/image/practice/backgrounds/2.jpg',
						},
						{
							title: t('practice.guided-meditations.posts.item.tree'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/guided-meditations/p3.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/guided-meditations/p3.jpg',
								},
							],
							background: '/image/practice/backgrounds/3.jpg',
						},
						{
							title: t('practice.guided-meditations.posts.item.four'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/guided-meditations/p4.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/guided-meditations/p4.jpg',
								},
							],
							background: '/image/practice/backgrounds/5.jpg',
						},
						{
							title: t('practice.guided-meditations.posts.item.five'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/guided-meditations/p5.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/guided-meditations/p5.jpg',
								},
							],
							background: '/image/practice/backgrounds/4.jpg',
						},
					],
				},
			},
			'guided-meditations-demo': {
				genre_slug: 'guided-meditations',
				key: 'guided-meditations',
				post: {
					slug: 'guided-meditations',
					title: t('practice.guided-meditations.banner.title'),
					preview: [
						{
							type: 'webp',
							uri: '/image/practice/guided-meditations/preview.webp',
							media: '(min-width: 1280px)',
						},
						{
							type: 'jpg',
							uri: '/image/practice/guided-meditations/preview.png',
							media: '(min-width: 1280px)',
						},
						{
							type: 'webp',
							uri: '/image/practice/guided-meditations/preview-mob.webp',
							media: '(min-width: 0px)',
						},
						{
							type: 'jpg',
							uri: '/image/practice/guided-meditations/preview-mob.png',
							media: '(min-width: 0px)',
						},
					],
				},
				banner: {
					title: t('practice.guided-meditations.banner.title'),
					preview: [
						{
							type: 'webp',
							uri: '/image/practice/guided-meditations/banner.webp',
						},
						{
							type: 'jpg',
							uri: '/image/practice/guided-meditations/banner.jpg',
						},
					],
				},
				info: {
					title: t('practice.guided-meditations.info.title'),
					description: t('practice.guided-meditations.info.description'),
				},
				start: {
					title: t('practice.guided-meditations.start.title'),
					items: [
						{
							title: t('practice.guided-meditations.start.title_one'),
							subtitle: t('practice.guided-meditations.start.desc_one'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/guided-meditations/s1.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/guided-meditations/s1.jpg',
								},
							],
						},
						{
							title: t('practice.guided-meditations.start.title_two'),
							subtitle: t('practice.guided-meditations.start.desc_two'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/guided-meditations/s2.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/guided-meditations/s2.jpg',
								},
							],
						},
						{
							title: t('practice.guided-meditations.start.title_tree'),
							subtitle: t('practice.guided-meditations.start.desc_tree'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/guided-meditations/s3.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/guided-meditations/s3.jpg',
								},
							],
						},
						{
							title: t('practice.guided-meditations.start.title_four'),
							subtitle: t('practice.guided-meditations.start.desc_four'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/guided-meditations/s4.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/guided-meditations/s4.jpg',
								},
							],
						},
					],
				},
				gallery: {
					title: t('practice.guided-meditations.gallery.title'),
					items: [
						{
							img: [
								{
									type: 'webp',
									uri: '/image/practice/guided-meditations/g1.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/guided-meditations/g1.jpeg',
								},
							],
							title: '',
						},
						{
							img: [
								{
									type: 'webp',
									uri: '/image/practice/guided-meditations/g2.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/guided-meditations/g2.png',
								},
							],
							title: '',
						},
						{
							img: [
								{
									type: 'webp',
									uri: '/image/practice/guided-meditations/g3.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/guided-meditations/g3.jpeg',
								},
							],
							title: '',
						},
						{
							img: [
								{
									type: 'webp',
									uri: '/image/practice/guided-meditations/g4.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/guided-meditations/g4.jpeg',
								},
							],
							title: '',
						},
						{
							img: [
								{
									type: 'webp',
									uri: '/image/practice/guided-meditations/g5.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/guided-meditations/g5.png',
								},
							],
							title: '',
						},
					],
				},
				posts: {
					title: t('practice.guided-meditations.posts.title'),
					description: t('practice.guided-meditations.posts.description'),
					items: [
						{
							title: t('practice.guided-meditations.posts.item.two'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/guided-meditations/p2.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/guided-meditations/p2.jpg',
								},
							],
							background: '/image/practice/backgrounds/1.jpg',
						},
						{
							title: t('practice.guided-meditations.posts.item.one'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/guided-meditations/p1.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/guided-meditations/p1.jpg',
								},
							],
							background: '/image/practice/backgrounds/2.jpg',
						},
						{
							title: t('practice.guided-meditations.posts.item.tree'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/guided-meditations/p3.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/guided-meditations/p3.jpg',
								},
							],
							background: '/image/practice/backgrounds/3.jpg',
						},
						{
							title: t('practice.guided-meditations.posts.item.five'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/guided-meditations/p5.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/guided-meditations/p5.jpg',
								},
							],
							background: '/image/practice/backgrounds/4.jpg',
						},
						{
							title: t('practice.guided-meditations.posts.item.four'),
							preview: [
								{
									type: 'webp',
									uri: '/image/practice/guided-meditations/p4.webp',
								},
								{
									type: 'jpg',
									uri: '/image/practice/guided-meditations/p4.jpg',
								},
							],
							background: '/image/practice/backgrounds/5.jpg',
						},
					],
				},
			},
		};
		return data;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};
