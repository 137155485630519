import { useMutation } from '@tanstack/react-query';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { ICartItem, ICartProduct, ICartResponse } from '../../@types';
import { ICartStore } from './cart.model';
import { useAuth } from '../../application/application.auth.context';
import { apiServices } from '../../services/api';

export const CART_SESSION_TOKEN = 'cart_session_token';

export const CartContext = createContext<ICartStore>({
	state: {
		isSuccess: false,
		isLoading: false,
		products: [],
		date_updated: '',
		token: '',
		error: null,
		isPendingUpdate: false,
		isSuccessUpdate: false,
	},
	actions: {
		onClear: () => void 0,
		getCart: () => void 0,
		updateCartStore: (data: ICartResponse) => void 0,
		mutateCart: ({ items, isAuth }: { items: ICartItem[]; isAuth: boolean }) =>
			void 0,
	},
});

export const CartContextProvider = ({
	children,
}: {
	children: JSX.Element[] | JSX.Element;
}) => {
	const { isLoading: isLoadingAuth, user } = useAuth();
	const [products, setProducts] = useState<ICartItem[]>([]);
	const [token, setToken] = useState<string>('');
	const [dateUpdated, setDateUpdated] = useState<string>('');
	const { isPending, data, isSuccess, mutate, error } = useMutation({
		mutationKey: ['get_cart_products'],
		mutationFn: (isAuth: boolean) => {
			return apiServices.getCart(isAuth);
		},
	});

	const {
		isPending: isPendingUpdate,
		data: dataUpdate,
		isSuccess: isSuccessUpdate,
		mutate: mutateUpdate,
	} = useMutation({
		mutationKey: ['update_cart_products'],
		mutationFn: (data: { items: ICartItem[]; isAuth: boolean }) => {
			return apiServices.updateCart(data.items, data.isAuth);
		},
		onError: (error) => {
			console.log('CART_UPDATE_ERROR', error);
		},
	});

	const updateCartStore = (data: ICartResponse) => {
		setProducts(data.items);
		setToken(data.token);
		setDateUpdated(data.date_updated);
		window.localStorage.setItem(CART_SESSION_TOKEN, data.token);
	};

	const onClear = () => {
		mutateUpdate({ isAuth: !!user, items: [] });
	};
	const getCart = () => mutate(!!user);

	useEffect(() => {
		mutate(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!isLoadingAuth) {
			mutate(!!user);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, isLoadingAuth]);

	useEffect(() => {
		if (data && isSuccess && !isPending) {
			updateCartStore(data);
		}
	}, [data, isPending, isSuccess]);

	useEffect(() => {
		if (dataUpdate && isSuccessUpdate && !isPendingUpdate) {
			updateCartStore(dataUpdate);
		}
	}, [dataUpdate, isPendingUpdate, isSuccessUpdate]);

	const contextValue: ICartStore = {
		state: {
			products: products,
			isLoading: isPending,
			isSuccess: isSuccess,
			date_updated: dateUpdated,
			token,
			error,
			isPendingUpdate,
			isSuccessUpdate,
		},
		actions: {
			getCart,
			onClear,
			updateCartStore,
			mutateCart: mutateUpdate,
		},
	};

	return (
		<CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
	);
};

export const useCart = () => {
	const { user } = useAuth();
	const { state, actions } = useContext(CartContext);

	const productsMemo = useMemo(() => state.products, [state.products]);
	const productsCount = useMemo(() => {
		return state.products.reduce(
			(accumulator, currentValue) => accumulator + currentValue.amount,
			0,
		);
	}, [state.products]);

	const totalPrice = useMemo(() => {
		return state.products.reduce(
			(accumulator, currentValue) =>
				accumulator + currentValue.amount * Number(currentValue.product.price),
			0,
		);
	}, [state.products]);

	const getCountProduct = (
		productId: number,
		size: number | undefined | null,
		color: number | undefined | null,
	) => {
		const currentSize = size ?? null;
		const currentColor = color ?? null;

		return (
			state.products.find(
				(item) =>
					item.product.id === productId &&
					item.size === currentSize &&
					item.color === currentColor,
			)?.amount || 0
		);
	};

	const onAdd = (
		product: ICartProduct,
		size: number | undefined | null,
		color: number | undefined | null,
	) => {
		const products = [...state.products];
		const currentSize = size === undefined ? null : size;
		const currentColor = color === undefined ? null : color;
		const currentPosition = products.findIndex(
			(item) =>
				item.product.id === product.id &&
				item?.size === currentSize &&
				item?.color === currentColor,
		);
		if (currentPosition === -1) {
			products.push({
				product,
				size,
				color,
				amount: 1,
			});
		} else {
			products[currentPosition].amount = products[currentPosition].amount + 1;
		}

		actions.mutateCart({ items: products, isAuth: !!user });
	};
	const onSub = (
		product: ICartProduct,
		size: number | undefined | null,
		color: number | undefined | null,
	) => {
		const products = [...state.products];
		const currentSize = size === undefined ? null : size;
		const currentColor = color === undefined ? null : color;
		const currentPosition = products.findIndex(
			(item) =>
				item.product.id === product.id &&
				item?.size === currentSize &&
				item?.color === currentColor,
		);

		if (products[currentPosition].amount - 1 > 0) {
			products[currentPosition].amount = products[currentPosition].amount - 1;
		} else {
			products.splice(currentPosition, 1);
		}

		actions.mutateCart({ items: products, isAuth: !!user });
	};
	const onRemove = (
		productID: number,
		size: number | undefined | null,
		color: number | undefined | null,
	) => {
		const products = [...state.products];
		const currentSize = size === undefined ? null : size;
		const currentColor = color === undefined ? null : color;
		const currentPosition = products.findIndex(
			(item) =>
				item.product.id === productID &&
				item?.size === currentSize &&
				item?.color === currentColor,
		);
		if (currentPosition !== -1) {
			products.splice(currentPosition, 1);
			actions.mutateCart({ items: products, isAuth: !!user });
		}
	};

	return {
		products: productsMemo,
		productsCount,
		totalPrice,
		onAdd,
		onSub,
		onRemove,
		getCountProduct,
		...actions,
	};
};
