import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { IAudioData, RoutsPath } from '../../@types';
import Audio from '../../components/audio/index';
import ContentLayout from '../../components/layout/content-layout/content-layout.component';
import { PageLayout } from '../../components/layout/page.layout';
import { Breadcrumbs } from '../../components/ui/breadcrumbs/breadcrumbs.component';
import { apiServices } from '../../services/api';
import mmb from '../../assets/img/mm.svg';
import spb from '../../assets/img/sm.svg';
import ymb from '../../assets/img/ym.svg';
import cl from './index.module.scss';

const createAudioLinks = (data: IAudioData) => {
	const links: { link: string; icon: string }[] = [];
	if (data.apple_music && data.apple_music !== '') {
		links.push({
			link: data.apple_music,
			icon: mmb,
		});
	}

	if (data.yandex_music && data.yandex_music !== '') {
		links.push({
			link: data.yandex_music,
			icon: ymb,
		});
	}

	if (data.spotify && data.spotify !== '') {
		links.push({
			link: data.spotify,
			icon: spb,
		});
	}

	return links;
};

const useAudio = () => {
	return useQuery({
		queryKey: ['audio'],
		queryFn: async () => {
			return await apiServices.getAudioCatalog();
		},
	});
};

const CatalogAudioPage = () => {
	const { t } = useTranslation();
	const { data, isLoading, error } = useAudio();
	const navigation = useNavigate();
	const [audio, setAudio] = useState<null | HTMLAudioElement>(null);
	const onChangeAudio = (value: null | HTMLAudioElement) => {
		if (value && audio) {
			audio.pause();
		}

		setAudio(value);
	};

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, []);

	useEffect(() => {
		if (error) {
			navigation(RoutsPath.not_found_base);
		}
	}, [error, navigation]);

	return (
		<PageLayout positionHeader="fixed">
			<div className={cl.page}>
				<div className={'header-offset'} />
				<div className="container">
					<div className={'box20'}></div>
					<Breadcrumbs
						items={[{ name: 'page.profile.audio', translate: true }]}
					/>
					<h1 className="title-section-small">{t('page.profile.audio')}</h1>

					<ContentLayout>
						<div>
							<div className={cl.sounds}>
								<a
									className={cl.sounds__item}
									href="/#"
									rel="noflow noindex noreferrer"
									target="_blank"
									data-tooltip-id={'yandex'}
									data-tooltip-content={t('tooltip.ym')}
								>
									<img src={ymb} alt="" loading="lazy" />
								</a>
								<a
									className={cl.sounds__item}
									href="/#"
									rel="noflow noindex noreferrer"
									target="_blank"
									data-tooltip-id={'apple'}
									data-tooltip-content={t('tooltip.mm')}
								>
									<img src={mmb} alt="" loading="lazy" />
								</a>
								<a
									className={cl.sounds__item}
									href="/#"
									rel="noflow noindex noreferrer"
									target="_blank"
									data-tooltip-id={'spotify'}
									data-tooltip-content={t('tooltip.sp')}
								>
									<img src={spb} alt="" loading="lazy" />
								</a>
							</div>

							<ul className={cl.list}>
								{!isLoading && data
									? data.map((item, index) => (
											<li className={cl.list__item} key={index}>
												<Audio
													src={item.sample}
													title={item.title}
													onSetAudio={onChangeAudio}
													download={createAudioLinks(item)}
												/>
											</li>
										))
									: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
											<li className={cl.list__item} key={item + '-loading'}>
												<div className={cl.audio__loading + ' skeleton'} />
											</li>
										))}
							</ul>
						</div>
					</ContentLayout>
				</div>
				<Tooltip id={'yandex'} style={{ zIndex: 10 }} />
				<Tooltip id={'spotify'} style={{ zIndex: 10 }} />
				<Tooltip id={'apple'} style={{ zIndex: 10 }} />
			</div>
		</PageLayout>
	);
};

export default CatalogAudioPage;
