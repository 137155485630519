import { useQuery, useQueryClient } from '@tanstack/react-query';
import { addDays } from 'date-fns';
import { format } from 'date-fns/esm';
import { Suspense, lazy, memo, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
	IMasterData,
	IScheduleFilters,
	IScheduleItemData,
	Schedule as ScheduleEnum,
} from '../../@types';
import { FilterKeys, FilterValue } from '../../pages/schedule/index.models';
import { ScheduleFilters } from './schedule-filters/schedule-filters.component';
import { ScheduleGrid } from './schedule-grid/schedule-grid.component';
import { useAuth } from '../../application/application.auth.context';
import { ModalAuthContext } from '../modals/modal-auth/modal-auth.context';
import { useScheduleFilters } from './schedule-filters/schedule-filters.context';
import { useWindowSize } from '../../hooks/useWindowSize';
import { apiServices } from '../../services/api';
import cl from './shedule.module.scss';

const ScheduleFiltersMemo = memo(ScheduleFilters);

const ModalEvent = lazy(
	() => import('../modals/modal-event/modal-event.component'),
);
const ModalUser = lazy(
	() => import('../modals/modal-user/modal-user.component'),
);

const useSchedule = (
	filters?: IScheduleFilters,
	isAuth?: boolean,
	isMobile?: boolean,
) => {
	return useQuery({
		queryKey: [
			filters?.start_date,
			filters?.end_date,
			filters?.type,
			filters?.masters,
			filters?.event_location,
			filters?.genre,
			isAuth,
			isMobile,
			'schedule',
		],
		queryFn: async () => {
			const today = new Date();
			const copyFilters = { ...filters };
			const isDateRangeEmpty = !filters?.end_date && !filters?.start_date;

			if (isDateRangeEmpty) {
				const startDate = format(today, 'yyyy-MM-dd');
				const endDate = isMobile
					? format(addDays(today, 6), 'yyyy-MM-dd')
					: format(addDays(today, 21), 'yyyy-MM-dd');

				copyFilters.start_date = startDate;
				copyFilters.end_date = endDate;
			}
			return await apiServices.getSchedule({
				...copyFilters,
			});
		},
	});
};

export const Schedule = () => {
	const { isAuth } = useAuth();
	const { isMobile } = useWindowSize(1280);
	const { open } = useContext(ModalAuthContext);
	const [searchParams, setSearchParams] = useSearchParams();
	const queryClient = useQueryClient();

	const [eventData, setEventData] = useState<IScheduleItemData | null>(null);
	const [mastersData, setMastersData] = useState<IMasterData | null>(null);

	const practice = searchParams.get('practice');
	const { dateRange, eventType, filterMaster, filterGenre, filterPlace, resetFilter } =
		useScheduleFilters();

	useEffect(() => {
		return () => {
			resetFilter();
		};
	}, []);

	useEffect(() => {
		if (practice) {
			const findGenre = filterGenre.options.find(
				(genre) => genre.slug === practice,
			);
			if (findGenre) {
				filterGenre.onChangeGenre({
					label: findGenre?.name,
					values: String(findGenre?.id),
				});
			}
			searchParams.delete('practice');
			setSearchParams(searchParams);
		}
	}, [practice, filterGenre, searchParams, setSearchParams]);

	const handleEventUpdate = () => {
		queryClient.invalidateQueries();
	};

	const handleClose = () => {
		const params = new URLSearchParams(searchParams);
		let hasChanges = false;

		if (params.has('eventId')) {
			params.delete('eventId');
			hasChanges = true;
		}
		if (params.has('date')) {
			params.delete('date');
			hasChanges = true;
		}
		
		if (hasChanges) {
			setSearchParams(params);
		}
	};

	const handleEventClick = (event: IScheduleItemData | null) => {
		setEventData(event);
	};

	const handleSpeakerClick = (user: IMasterData | null) => {
		setEventData(null);
		setMastersData(user);
	};

	const formatValue = (
		filter: { [key in FilterKeys]?: FilterValue },
		key: FilterKeys,
	) => {
		const filterValue = filter[key]?.values;
		return filterValue === '-1' ? undefined : filterValue;
	};

	const { data, isLoading } = useSchedule(
		{
			start_date: dateRange[0] && format(dateRange[0], 'yyyy-MM-dd'),
			end_date: dateRange[1] && format(dateRange[1], 'yyyy-MM-dd'),
			type: eventType !== ScheduleEnum.ALL ? eventType : undefined,
			masters: formatValue(filterMaster, 'master'),
			genre: formatValue(filterGenre, 'genre'),
			event_location: formatValue(filterPlace, 'place'),
		},
		isAuth,
		isMobile,
	);

	return (
		<div className={cl.schedule}>
			<ScheduleGrid
				items={data}
				filters={<ScheduleFiltersMemo />}
				loading={isLoading}
				date={dateRange}
				onClickEvent={handleEventClick}
				onClickSpeaker={handleSpeakerClick}
			/>

			<Suspense fallback={<div />}>
				<ModalEvent
					event={open ? null : eventData}
					setEvent={setEventData}
					onUpdateEvent={handleEventUpdate}
					handleClose={handleClose}
					onClickSpeaker={handleSpeakerClick}
				/>
			</Suspense>

			<Suspense fallback={<div />}>
				<ModalUser data={mastersData} setData={setMastersData} />
			</Suspense>
		</div>
	);
};
