import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RoutsPath } from '../../../@types';
import { Button } from '../../ui/buttons/button/button.component';
import { isSupportWebp } from '../../../utils/check.utils';
import { createLangHref } from '../../../utils/href.utils';
import imgContact from './assets/contact.jpg';
import imgWebpContact from './assets/contact.webp';
import starBg from './assets/star.png';
import img from './assets/team.jpg';
import imgWebp from './assets/team.webp';
import cl from './section-go-team.module.scss';

const getBg = (type: string) => {
	if (type === 'default') {
		return { descktop: starBg };
	}
	const isWebp = isSupportWebp();

	if (isWebp) {
		if (type === 'contact') {
			return { descktop: imgWebpContact };
		} else {
			return { descktop: imgWebp };
		}
	}

	return type === 'contact' ? { descktop: imgContact } : { descktop: img };
};

export const SectionGoTeam = ({
	contact = false,
	type = 'default',
	classes,
}: {
	contact?: boolean;
	type?: 'default' | 'contact' | 'image';
	classes?: { desc?: string };
}) => {
	const { desc = '' } = classes || {};
	const preview = getBg(type);
	const { t, i18n } = useTranslation();
	const navigation = useNavigate();
	const singup = () =>
		navigation(createLangHref(i18n.language, RoutsPath.schedule));

	return (
		<section
			className={
				cl.section + ' ' + (type === 'default' ? cl.section_animation : '')
			}
			style={{
				backgroundImage: `url(${preview.descktop})`,
			}}
		>
			<div className="container">
				<div className={cl.content}>
					<h3 className={cl.title}>
						{!contact ? t('team.form.title') : t('contact.form.title')}
					</h3>
					<p className={cl.desc + ' ' + desc}>
						{!contact ? t('team.form.desc') : t('contact.form.desc')}
					</p>
					<Button className={cl.btn} onClick={singup} theme="rainbow">
						<>{t('btn.book-first-section')}!</>
					</Button>
				</div>
			</div>
		</section>
	);
};
