//TODO: ЗАМЕНИТЬ ANY
export const styles_input_select = {
	input: (styles: any) => ({
		...styles,
		paddingTop: 0,
		height: '40px',
		background: 'none',
	}),
	singleValue: (styles: any) => ({
		...styles,
		color: 'rgba(235, 233, 240, 0.80)',
		fontSize: '15px',
		fontWeight: 500,
		lineHeight: '20px',
	}),
	option: (styles: any, { data, isDisabled }: any) => ({
		...styles,
		color: 'rgba(235, 233, 240, 0.80)',
		zIndex: 5,
		fontSize: '15px',
		fontWeight: 500,
		lineHeight: '20px',
		background: 'none',
		textAlign: 'left',
		cursor: 'pointer',
		padding: '8px 10px',
		backgroundColor: 'rgba(194, 208, 251, 0.20)',
		transition:
			'color 0.3s cubic-bezier(0.4, 0, 0.2, 1), background-color cubic-bezier(0.4, 0, 0.2, 1)',
		'&:hover': {
			color: 'rgba(235, 233, 240, 0.80)',
			backgroundColor: 'rgba(194, 208, 251, 0.10)',
			transition:
				'color 0.3s cubic-bezier(0.4, 0, 0.2, 1), background-color cubic-bezier(0.4, 0, 0.2, 1)',
		},
		'&:active': {
			backgroundColor: 'rgba(194, 208, 251, 0.10)',
			color: 'rgba(235, 233, 240, 0.80)',
			transition:
				'color 0.3s cubic-bezier(0.4, 0, 0.2, 1), background-color cubic-bezier(0.4, 0, 0.2, 1)',
		},
	}),
	control: (styles: any) => {
		return {
			...styles,
			cursor: 'pointer',
			border: '1px solid rgba(194, 208, 251, 0.01)',
			boxShadow: 'none',
			borderRadius: '40px',
			background: 'rgba(194, 208, 251, 0.20)',
			outline: 'none',
			fontSize: '16px',
			minHeight: '40px',
			height: '40px',
			transition: 'border 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
			':hover': {
				...styles[':hover'],
				border: '1.5px solid rgba(194, 208, 251, 0.2)',
			},
		};
	},
	valueContainer: (styles: any) => ({
		...styles,
		paddingLeft: '20px',
		background: 'none',
	}),
	indicatorSeparator: (styles: any) => ({
		...styles,
		display: 'none',
	}),
	indicatorsContainer: (styles: any) => ({
		...styles,
		padding: 0,
		width: '30px',
		height: '35px',
		marginRight: '10px',
		color: 'rgba(235, 233, 240, 0.80)',
	}),
	indicatorContainer: (styles: any) => ({
		...styles,
		padding: 0,
		width: '32px',
		height: '32px',
		color: 'rgba(235, 233, 240, 0.80)',
	}),
	container: (styles: any) => ({
		...styles,
		width: '100%',
		background: 'none',
	}),
	placeholder: (styles: any) => ({
		...styles,
		whiteSpace: 'nowrap',
		paddingRight: '5px',
		color: 'rgba(235, 233, 240, 0.80)',
		fontWeight: '600',
		fontSize: '15px',
	}),
	menu: (styles: any) => ({
		...styles,
		background: 'rgba(194, 208, 251, 0.20)',
		borderRadius: '12px',
		boxShadow: 'none',
		zIndex: 4000,
		border: '1px solid rgba(194, 208, 251, 0.20)',
		overflow: 'hidden',
		backdropFilter: 'blur(50px)',
	}),
	menuList: (styles: any) => ({
		...styles,
		'::-webkit-scrollbar': {
			width: '4px',
		},

		'::-webkit-scrollbar-track': {
			background: 'rgba(194, 208, 251, 0.20)',
		},

		'::-webkit-scrollbar-thumb': {
			backgroundColor: 'rgba(194, 208, 251, 0.10)',
		},
	}),
	menuPortal: (styles: any) => ({ ...styles, zIndex: 9999, width: '13%' }),
};

export const styles_input_select_mobile = {
	...styles_input_select,
	menuPortal: (styles: any) => ({ ...styles, zIndex: 9999, width: '50%' }),
};
