import { useTranslation } from 'react-i18next';
import ToggleDescription from '../../../ui/toggle-description/toggle-description.component';
import cl from './event-description.module.scss';

interface EventDescriptionProps {
	description: string;
}

const EventDescription = ({ description }: EventDescriptionProps) => {
	const { t } = useTranslation();

	return (
		<div className={cl.about}>
			<div className={cl.aboutHeader}>{t('title.description')}</div>
			<ToggleDescription description={description} />
		</div>
	);
};

export default EventDescription;