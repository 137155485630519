import React, { useEffect, useState } from 'react';
import cs from './custom-slider.module.scss';

export const CustomSlider = ({
	posts,
	perPage,
	isVertical = false,
	gap,
	initialIndex = 0,
}: {
	posts: any[];
	perPage: number;
	isVertical?: boolean;
	gap?: number | null;
	initialIndex?: number;
}) => {
	const [activeIndex, setActiveIndex] = useState<number>(initialIndex);
	const [currentSlides, setCurrentSlides] = useState(
		posts.slice(initialIndex, initialIndex + (perPage + 1)),
	);
	const [switchAnimate, setSwitchAnimate] = useState<boolean>(false);
	const [isFirstRender, setIsFirstRender] = useState<boolean>(true);

	useEffect(() => {
		if (isFirstRender) {
			setIsFirstRender(false);
			return;
		}

		let newSlides: any[];
		const totalPosts = posts.length;

		if (activeIndex !== totalPosts - 1) {
			// В противном случае, показываем обычное количество `perPage`
			newSlides = posts.slice(activeIndex, activeIndex + (perPage + 1));
		} else {
			// Если оставшиеся слайды меньше, чем `perPage`, показываем только оставшиеся
			const remainingSlides = totalPosts - activeIndex;
			newSlides = posts.slice(activeIndex, activeIndex + remainingSlides);
		}

		let timeout: NodeJS.Timeout;
		if (activeIndex === 0) {
			setCurrentSlides(newSlides);
			setSwitchAnimate(false);
		} else {
			const animationDuration = 1000;
			setSwitchAnimate(true);
			timeout = setTimeout(() => {
				setCurrentSlides(newSlides);
				setSwitchAnimate(false);
			}, animationDuration);
		}

		return () => clearTimeout(timeout); // Чистим таймер при размонтировании
	}, [activeIndex, perPage]);

	const handleSlideClick = () => {
		if (!switchAnimate) {
			// Если мы на последнем слайде или предпоследнем
			if (activeIndex === posts.length - 1) {
				// Вернуться в начало
				setActiveIndex(0);
			} else {
				// Иначе просто увеличиваем индекс
				const newIndex = (activeIndex + 1) % posts.length;
				setActiveIndex(newIndex);
			}
		}
	};

	return (
		<div
			style={gap ? { gap } : {}}
			className={`${cs.slider} ${isVertical ? cs.slider_col : cs.slider_row}`}
		>
			{currentSlides.map((post, index) => {
				const generateClassName = () => {
					const isCurrent = index === 0;
					const isNext = index === 1;
					const isNextNext = index === 2;
					const isNextNextNext = index === 3;
					const isLast = activeIndex === posts.length - 1;

					if (switchAnimate) {
						return [
							cs.slider__item,
							isCurrent ? cs.slider__item_is_hide : '',
							isNext ? cs.slider__item_is_active : '',
							isNextNext ? cs.slider__item_is_next : '',
							isNextNextNext ? cs.slider__item_is_next_next : '',
							isLast ? cs.slider__item_is_last : '',
						].join(' ');
					} else {
						return [
							cs.slider__item,
							isCurrent ? cs.slider__item_is_active : '',
							isNext ? cs.slider__item_is_next : '',
							isNextNext ? cs.slider__item_is_next_next : '',
							isNextNextNext ? cs.slider__item_is_hide : '',
							isLast ? cs.slider__item_is_last : '',
						].join(' ');
					}
				};

				return (
					<div
						key={String(post.title)}
						onClick={() => handleSlideClick()}
						className={`${generateClassName()} ${cs.slider__item_wrapper_background}`}
						style={{ backgroundImage: `url(${post.background})` }}
					>
						<div className={cs.slider__item_content}>
							<div className={cs.slider__item_title}>Who It&#39;s For</div>
							<p className={cs.slider__item_description}>{post.title}</p>
							<div className={cs.slider__item_image_container}>
								<img
									className={cs.slider__item_image}
									src={post.preview[0].uri}
									alt={post.title}
								/>
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};
