import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { IProductDetail, RoutsPath } from '../../@types';
import {
	CarouselImagesMemo,
	CarouselImagesSkeleton,
} from '../../components/carousel-images/carousel-images.component';
import { useCart } from '../../components/cart/cart.context';
import { PageLayout } from '../../components/layout/page.layout';
import { Breadcrumbs } from '../../components/ui/breadcrumbs/breadcrumbs.component';
import { Button } from '../../components/ui/buttons/button/button.component';
import {
	CheckedList,
	CheckedListSkeleton,
} from '../../components/ui/radio-image/radio-image.component';
import { Fade } from 'src/components/ui/fade/fade.component';
import { Specification } from 'src/components/ui/specification/specification.component';
import { CURRENCY } from '../../const/settings.const';
import { useWindowSize } from 'src/hooks/useWindowSize';
import { apiServices } from '../../services/api';
import { DtoCartProduct } from '../../services/dto';
import { createLangHref } from '../../utils/href.utils';
import cl from './index.module.scss';
import 'swiper/css';

const useShopProduct = (id: string) => {
	return useQuery({
		queryKey: [id, 'shop-product'],
		queryFn: async () => {
			return await apiServices.getShopProduct(id);
		},
	});
};

const CatalogContent = ({ data }: { data: IProductDetail }) => {
	const { t, i18n } = useTranslation();
	const navigation = useNavigate();
	const { onAdd, products, getCountProduct } = useCart();
	const [color, setColor] = useState(data.colors[0]);
	const [size, setSize] = useState(data.sizes[0]);
	const refDesc = useRef<HTMLDivElement>(null);
	const cartItem = new DtoCartProduct(data).toJSON();
	const { width = window.innerWidth } = useWindowSize();
	const isMobile = useMemo(() => {
		return width < 1280;
	}, [width]);
	const gocart = () =>
		navigation(createLangHref(i18n.language, RoutsPath.cart));

	const currentCount = useMemo(() => {
		return getCountProduct(data.id, size?.id, color?.id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [size, data, color, products]);

	const onSetAvailableSizes = (value: {
		value: string | number;
		data: null;
	}) => {
		const size = data.sizes.find((item) => item.id === value.value);
		if (size) {
			setSize(size);
		}
	};
	const onSetColor = (value: { value: string | number; data: null }) => {
		const color = data.colors.find((item) => item.id === value.value);
		if (color) {
			setColor(color);
		}
	};

	return (
		<>
			<div className={'row ' + cl.row + ' ' + cl.pb20}>
				<div className={cl.col + ' ' + cl.carousel}>
					<CarouselImagesMemo
						vertical={!isMobile}
						dotsImage={true}
						navigation={false}
						images={data.images.map((item) => item.image)}
					/>
				</div>
				<div className={cl.col}>
					<div>
						<h1 className={cl.title + ' ' + cl.pb28}>{data.name}</h1>
						{data?.weight && (
							<Specification
								items={[
									{
										label: t('weight'),
										value: data.weight.toString() + t('units'),
									},
								]}
							/>
						)}

						{data.colors.length > 0 && (
							<div className={cl.pb28}>
								<CheckedList
									title={t('label-color')}
									items={data.colors.map((item) => ({
										label: item.name,
										value: item.id,
										color: item.html_code,
										data: null,
									}))}
									active={[color.id]}
									onClickItem={onSetColor}
								/>
							</div>
						)}
						{data.sizes.length > 0 && (
							<div className={cl.pb28}>
								<CheckedList
									title={t('label-size')}
									items={data.sizes.map((item) => ({
										label: item.name,
										value: item.id,
										data: null,
									}))}
									active={[size.id]}
									onClickItem={onSetAvailableSizes}
								/>
							</div>
						)}

						{data.description && (
							<div className={cl.pb28}>
								<h3 className={cl.second__title}>{t('title.description')}</h3>
								<div
									className={cl.description}
									dangerouslySetInnerHTML={{
										__html: data.description,
									}}
									ref={refDesc}
								/>
							</div>
						)}

						<div className={cl.price}>
							<span>{CURRENCY}</span>
							{data.price}
						</div>
						<div className={cl.box}>
							<div className={cl.actions}>
								<Fade fade={currentCount === 0}>
									<Button
										className={cl.cart}
										theme="rainbow"
										onClick={() => onAdd(cartItem, size?.id, color?.id)}
									>
										{t('add.cart')}
									</Button>
								</Fade>
								<Fade fade={currentCount !== 0}>
									<Button
										theme="rainbow"
										className={cl.cart + ' ' + cl.cart_go}
										onClick={gocart}
									>
										{t('btn.go.cart')}
									</Button>
								</Fade>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const CatalogLoading = () => {
	return (
		<div className={cl.loading}>
			<div className={'row ' + cl.row}>
				<div className={cl.col + ' ' + cl.carousel + ' ' + cl.null}>
					<CarouselImagesSkeleton />
				</div>
				<div className={cl.col}>
					<div className={cl.title__skeleton + ' skeleton'} />
					<div className={cl.price__skeleton + ' skeleton'} />
					<div className={cl.box}>
						<CheckedListSkeleton len={3} type="preview" />
					</div>
					<div className={cl.box}>
						<CheckedListSkeleton len={5} />
					</div>
					<div className={cl.box}>
						<div className={cl.cart__skeleton + ' skeleton'} />
					</div>
				</div>
			</div>
		</div>
	);
};

const CatalogSlugPage = () => {
	const { slug } = useParams();
	const navigation = useNavigate();
	const { data, isLoading, error } = useShopProduct(slug || '');
	const { i18n } = useTranslation();

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, []);

	useEffect(() => {
		if (error) {
			navigation(RoutsPath.not_found_base);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error]);

	return (
		<PageLayout positionHeader="fixed">
			<div className={cl.page}>
				<div className={'header-offset'} />
				<div className={'container'}>
					<Breadcrumbs
						items={[
							{
								name: 'page.catalog',
								translate: true,
								href: createLangHref(i18n.language, RoutsPath.catalog),
							},
							{ name: data?.name || '' },
						]}
					/>
				</div>
				<div className={'container-catalog'}>
					{(isLoading && data === undefined) || data === undefined ? (
						<CatalogLoading />
					) : (
						<CatalogContent data={data} />
					)}
				</div>
			</div>
		</PageLayout>
	);
};

export default CatalogSlugPage;
