import { ISvgIconProps } from '.';

export const InstaIcon = ({
	className = '',
	viewBox = '0 0 26 26',
	refComponent = undefined,
}: ISvgIconProps) => {
	return (
		<svg
			className={className}
			ref={refComponent}
			viewBox={viewBox}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M17.8353 2H8.16473C4.76396 2 2 4.75739 2 8.16473V17.8353C2 21.236 4.75739 24 8.16473 24H17.8353C21.236 24 24 21.2426 24 17.8353V8.16473C23.9869 4.76395 21.2229 2 17.8353 2ZM21.9254 17.8353C21.9254 20.1003 20.0871 21.9385 17.8221 21.9385H8.15159C5.8866 21.9385 4.04834 20.1003 4.04834 17.8353V8.16473C4.04834 5.89973 5.8866 4.06147 8.15159 4.06147H17.8221C20.0871 4.06147 21.9254 5.89973 21.9254 8.16473V17.8353Z"
				fill="currentColor"
			/>
			<path
				d="M15.577 8.09887C10.6072 5.80105 5.78177 10.6265 8.07959 15.5963C8.55228 16.6205 9.37948 17.4477 10.4037 17.9204C15.3735 20.2182 20.1989 15.3928 17.9011 10.423C17.4153 9.39222 16.6012 8.565 15.577 8.09887ZM11.0799 15.918C10.6532 15.6751 10.3118 15.3337 10.0688 14.907C8.59824 12.2612 10.4824 9.56948 12.9903 9.56948C14.8877 9.56948 16.4305 11.1123 16.4305 13.0096C16.4305 15.511 13.7257 17.4018 11.0799 15.918Z"
				fill="currentColor"
			/>
			<path
				d="M19.0641 8.38118C19.7748 8.38118 20.3509 7.80507 20.3509 7.0944C20.3509 6.38373 19.7748 5.80762 19.0641 5.80762C18.3534 5.80762 17.7773 6.38373 17.7773 7.0944C17.7773 7.80507 18.3534 8.38118 19.0641 8.38118Z"
				fill="currentColor"
			/>
		</svg>
	);
};
