import { useTranslation } from 'react-i18next';
import { Schedule } from '../../../@types';
import { ButtonGroup } from '../../ui/button-group/button-group.component';
import InputSelect from '../../ui/input-select/input-select.component';
import {
	DEFAULT_GENRE,
	DEFAULT_MASTER,
	DEFAULT_PLACES,
	useScheduleFilters,
} from './schedule-filters.context';
import { CalendarIcon, CloseInputIcon } from '../../ui/icons';
import cl from './schedule-filters.module.scss';
import { InputDateRange } from '../../ui/input';

export const ScheduleFilters = () => {
	const { i18n, t } = useTranslation();

	const {
		dateRange,
		eventType,
		setDateRange,
		onChangeEvent,
		filterMaster,
		filterGenre,
		filterPlace,
	} = useScheduleFilters();
	const isNullDate = dateRange[0] === null && dateRange[1] === null;

	return (
		<div className={cl.filters}>
			<div className={cl.flter__item__input + ' ' + cl.filter__item}>
				<InputDateRange
					value={isNullDate ? undefined : dateRange}
					onChangeDate={setDateRange}
					placeholder={t('select.label.date')}
					startAdornment={<CalendarIcon />}
					endAdornment={
						isNullDate ? undefined : (
							<span onClick={() => setDateRange([null, null])}>
								<CloseInputIcon />
							</span>
						)
					}
					lang={i18n.language}
					classes={{
						startAdornment: 'input-calendar-adornment__end',
						endAdornment: 'input-calendar-adornment__end',
						input: cl.input_date,
					}}
					isSelectWeek={false}
					isClearable={true}
				/>
			</div>
			<div className={cl.flter__item__input + ' ' + cl.flter__item__group}>
				{filterMaster.isLoading ? (
					<div className={cl.filter__item__input__skeleton + ' skeleton'}></div>
				) : (
					<InputSelect<{ values: string; label: string }>
						placeholder={t('select.label.masters')}
						onChange={(value) => {
							filterMaster.onChangeMaster(value);
						}}
						options={[
							{ ...DEFAULT_MASTER, label: t(DEFAULT_MASTER.label) },
							...filterMaster.options.map((item) => ({
								label: item.name,
								values: item.id.toString(),
							})),
						]}
						id={'filter-master'}
						value={
							filterMaster.master.values === DEFAULT_MASTER.values
								? undefined
								: filterMaster.master
						}
						name="master"
						endAdornment={
							<span
								className={cl.clear}
								onClick={() => filterMaster.onChangeMaster(DEFAULT_MASTER)}
							>
								<CloseInputIcon />
							</span>
						}
						paddingBottom
					/>
				)}
			</div>
			<div className={cl.flter__item__input + ' ' + cl.flter__item__group}>
				{filterGenre.isLoading ? (
					<div className={cl.filter__item__input__skeleton + ' skeleton'}></div>
				) : (
					<InputSelect<{ values: string; label: string }>
						placeholder={t('select.label.practices')}
						onChange={(value) => {
							filterGenre.onChangeGenre(value);
						}}
						options={[
							{ ...DEFAULT_GENRE, label: t(DEFAULT_GENRE.label) },
							...filterGenre.options.map((item) => ({
								label: item.name,
								values: item.id.toString(),
							})),
						]}
						id={'filter-direction'}
						name="practices"
						paddingBottom
						value={
							filterGenre.genre.values === DEFAULT_GENRE.values
								? undefined
								: filterGenre.genre
						}
						endAdornment={
							<span
								className={cl.clear}
								onClick={() => filterGenre.onChangeGenre(DEFAULT_GENRE)}
							>
								<CloseInputIcon />
							</span>
						}
					/>
				)}
			</div>

			<div className={cl.flter__item__input + ' ' + cl.flter__item__group}>
				{filterPlace.isLoading ? (
					<div className={cl.filter__item__input__skeleton + ' skeleton'}></div>
				) : (
					<InputSelect<{ values: string; label: string }>
						placeholder={t('select.label.places')}
						onChange={(value) => {
							filterPlace.onChangePlace(value);
						}}
						options={[
							{ ...DEFAULT_PLACES, label: t(DEFAULT_PLACES.label) },
							...filterPlace.options.map((item) => ({
								label: item.title,
								values: item.pk.toString(),
							})),
						]}
						id={'filter-direction'}
						name="places"
						paddingBottom
						value={
							filterPlace.place.values === DEFAULT_PLACES.values
								? undefined
								: filterPlace.place
						}
						endAdornment={
							<span
								className={cl.clear}
								onClick={() => filterPlace.onChangePlace(DEFAULT_PLACES)}
							>
								<CloseInputIcon />
							</span>
						}
					/>
				)}
			</div>

			<div
				className={
					cl.padding + ' ' + cl.filter__item + ' ' + cl.filter__item__btn__group
				}
			>
				<ButtonGroup
					columnOff
					active={eventType}
					buttons={[
						{
							label: t('filter.' + Schedule.ALL),
							data: Schedule.ALL,
							onClick: onChangeEvent,
						},
						{
							label: t('filter.' + Schedule.MEDITATION),
							data: Schedule.MEDITATION,
							onClick: onChangeEvent,
						},
						{
							label: t('filter.' + Schedule.EVENT),
							data: Schedule.EVENT,
							onClick: onChangeEvent,
						},
					]}
				/>
			</div>
		</div>
	);
};
