import { useTranslation } from 'react-i18next';
import { ICardTeamProps } from './card-team.model';
import cl from './card-team.module.scss';

export const CardTeam = ({
	title,
	preview,
	className = '',
	translate = false,
	onClick,
	position,
}: ICardTeamProps) => {
	const { t } = useTranslation();

	return (
		<div className={cl.card + ' ' + className} onClick={onClick}>
			{typeof preview === 'string' ? (
				<img src={preview} className={cl.card__img} alt="" loading="lazy" />
			) : (
				<picture className={cl.card__img}>
					{preview.map((img) => (
						<source
							type={'image/' + img.type}
							srcSet={img.uri}
							key={img.type}
						/>
					))}
					<img
						src={preview[1].uri}
						className={cl.card__img}
						alt=""
						loading="lazy"
					/>
				</picture>
			)}

			<div className={cl.card__info}>
				<p className={cl.card__info__position}>{position || ''}</p>
				<h4 className={cl.card__info__name}>{translate ? t(title) : title}</h4>
			</div>
		</div>
	);
};

export const CardTeamSkeleton = () => {
	return (
		<div className={cl.card}>
			<div className={cl.card__img__skeleton + ' skeleton'}></div>
			<div
				className={cl.card__info + ' ' + cl.card__info__skeleton + ' skeleton'}
			></div>
		</div>
	);
};
