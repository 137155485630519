import { useEffect } from 'react';


/**
 * React hook, который добавляет или удаляет класс из элемента body.
 *
 * @param {string} className - Класс, который нужно добавить или удалить из body.
 * @param {boolean} isActive - Флаг, определяющий, должен ли класс быть добавлен или удален.
 * @returns {void}
 */
const useBodyClass = (className: string, isActive: boolean): void => {
	useEffect(() => {
		if (isActive) {
			document.body.classList.add(className);
		} else {
			document.body.classList.remove(className);
		}

		return () => document.body.classList.remove(className);
	}, [className, isActive]);
};

export default useBodyClass;
