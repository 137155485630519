import {
	addDays,
	endOfMonth,
	endOfWeek,
	format,
	getDay,
	startOfMonth,
	startOfWeek,
} from 'date-fns';
import { ru } from 'date-fns/esm/locale';
import { useWindowSize } from '../hooks/useWindowSize';

const langs: { [key: string]: { mount: string[] } } = {
	ru: {
		mount: [
			'',
			'Январь',
			'Февраль',
			'Март',
			'Апрель',
			'Май',
			'Июнь',
			'Июль',
			'Август',
			'Сентябрь',
			'Октябрь',
			'Ноябрь',
			'Декабрь',
		],
	},
	en: {
		mount: [
			'',
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December',
		],
	},
};

export const dateParse = (
	date: string | Date,
	{ incline = false, lang = 'en' }: { incline: boolean; lang?: string },
) => {
	const dateNew = new Date(date);
	const mm = dateNew.getMonth() + 1;
	const yyyy = dateNew.getFullYear();
	const dd = dateNew.getDate();
	const mount_label = getMountDictionary(mm, { incline, lang });
	return {
		yyyy,
		mm,
		dd,
		full: `${yyyy}.${mm > 9 ? mm : '0' + mm}.${dd > 9 ? dd : '0' + dd}`,
		mount_label,
	};
};

export const getMountDictionary = (
	index: number,
	{ incline = false, lang = 'en' }: { incline: boolean; lang?: string },
) => {
	if (langs[lang] === undefined) {
		return '';
	}
	let value = langs[lang].mount[index].toLowerCase();

	if (incline && lang === 'ru') {
		if (index === 3 || index === 8) {
			value = value + 'a';
		} else {
			value = value.substring(0, value.length - 1);
			value = value + 'я';
		}
	}

	return value;
};

export const convertDotDate = (date: string) => {
	const _date = date.split('.');
	const year_time = _date[2].split(' ');
	return (
		year_time[0] +
		'-' +
		_date[1] +
		'-' +
		_date[0] +
		(year_time[1] !== undefined ? ' ' + year_time[1] : '')
	);
};

export const reverseDate = (date: string) => {
	const split = date.split(' ');
	if (split[1] !== undefined) {
		return [split[0].split('.').reverse().join('.'), split[1]].join(' ');
	}
	return split[0].split('.').reverse().join('.');
};

export const dateFnsParse = (
	date_string: string | null,
	lang: string,
	options?: {
		replace_week_ru?: boolean;
		week_format?: 'EEEE' | 'EEE';
		incline?: boolean;
	},
) => {
	const {
		replace_week_ru = false,
		week_format = 'EEE',
		incline = true,
	} = options || {};
	if (date_string === null) {
		return {
			day: '',
			week: '',
			month: '',
		};
	}

	const date = Date.parse(date_string.replaceAll('.', '/'));
	const locale = lang === 'ru' ? ru : undefined;
	const day = date ? format(date, 'dd', { locale }) : '';
	const month = date ? format(date, 'LLLL', { locale }) : '';
	const week = date ? format(date, week_format, { locale }) : '';
	const week_update =
		replace_week_ru && lang === 'ru' ? week.slice(0, -1) : week;
	const month_number = parseInt(format(date, 'MM'));
	const mount_label = getMountDictionary(month_number, { incline, lang });
	return {
		day,
		week: week_update,
		month,
		mount_label,
		month_number,
	};
};

// Функция для создания недели
export const createWeek = <T>(
	lang: string,
	dateRange?: [Date | null, Date | null],
	isMobile?: boolean,
) => {
	// Определение локали на основе языка
	const locale = lang === 'ru' ? ru : undefined;

	// Инициализируем пустой объект недели
	const week: Record<string, { date: string; items: T[] }> = {};

	const [startDate, endDate] = dateRange || [null, null];

	let firstDay: Date | undefined = undefined;
	let lastDay: Date | undefined = undefined;

	const currentDate: Date = new Date();

	// Первая генерация для мобилки с текущего дня и до конца месяца, для десктопов с начала недели и до конца месяца
	if (!startDate && !endDate) {
		if (isMobile) {
			firstDay = currentDate;
			lastDay = addDays(currentDate, 6);
		} else {
			firstDay = startOfWeek(currentDate, { locale, weekStartsOn: 1 });
			lastDay = addDays(currentDate, 21);
		}
	} else if (startDate instanceof Date) {
		// Если указана начальная дата, но не конечная, или обе даты
		firstDay = startOfWeek(startDate, { locale, weekStartsOn: 1 });
		if (endDate instanceof Date) {
			const diffInDays =
				(endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);
			if (diffInDays < 7) {
				lastDay = endOfWeek(startDate, { locale, weekStartsOn: 1 });
			} else {
				lastDay = endDate;
			}
		} else {
			lastDay = endOfWeek(startDate, { locale, weekStartsOn: 1 });
		}
	}

	// Проверка на случай, если переменные не были инициализированы
	if (!firstDay || !lastDay) {
		throw new Error('firstDay or lastDay is undefined');
	}

	// Переменная для итерации по дням
	let currentDay = firstDay;

	// Заполняем неделю с указанными датами
	while (currentDay <= lastDay) {
		const formattedDate = format(currentDay, 'dd.MM.yyyy'); // Форматируем дату
		week[formattedDate] = {
			date: formattedDate, // Сохраняем отформатированную дату
			items: [], // Инициализируем пустой массив элементов
		};
		currentDay = addDays(currentDay, 1); // Переходим к следующему дню
	}

	return week; // Возвращаем объект недели
};
