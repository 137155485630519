import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from '../../components/layout/page.layout';
import { Schedule } from '../../components/schedule';
import { SliderBanner } from '../../components/slider-banner/slider-banner.component';
import { Breadcrumbs } from '../../components/ui/breadcrumbs/breadcrumbs.component';
import { apiServices } from '../../services/api';
import cl from './index.module.scss';
import 'react-datepicker/dist/react-datepicker.css';

const useBanners = () => {
	return useQuery({
		queryKey: ['banners'],
		queryFn: async () => {
			return await apiServices.getBanners();
		},
	});
};

const SchedulePage = () => {
	const { data: banners, isLoading: isLoadingBanners } = useBanners();
	const { t } = useTranslation();

	const meta = {
		title: t('helmet.schedule.title'),
		description: t('helmet.schedule.description'),
	};

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, []);

	return (
		<PageLayout positionHeader="fixed" meta={meta}>
			<>
				<div className={'header-offset'}></div>
				<div className="container">
					<Breadcrumbs
						items={[{ name: t('home.schedule'), translate: true }]}
					/>
					<h1 className="title-section-small">{t('home.schedule')}</h1>
					<div className={cl.box40}></div>
				</div>
				<div className="alexey-make-normal-containers">
					{banners && !isLoadingBanners ? (
						<SliderBanner
							slides={banners.map((item) => ({
								title: item.title,
								description: item.description,
								subtitle: item.sub_title,
								image: item.image,
								date: item.date,
								country: item.place,
							}))}
						/>
					) : (
						<div className={cl.banners__loading + ' skeleton'} />
					)}
				</div>

				<div className="container">
					<div className={cl.schedule}>
						<Schedule />
					</div>
				</div>
			</>
		</PageLayout>
	);
};

export default SchedulePage;
