import md1 from '../../../assets/img/md-1.jpg';
import md1w from '../../../assets/img/md-1.webp';
import md2 from '../../../assets/img/md-2.jpg';
import md2w from '../../../assets/img/md-2.webp';
import md3 from '../../../assets/img/md-3.jpg';
import md3w from '../../../assets/img/md-3.webp';
import md4 from '../../../assets/img/md-4.jpg';
import md4w from '../../../assets/img/md-4.webp';
import md5 from '../../../assets/img/md-5.jpg';
import md5w from '../../../assets/img/md-5.webp';

export const MEDITATION_OUR_MOCK: {
	title: { [key: string]: string };
	link: string;
	subtitle: { [key: string]: string };
	preview: { type: 'jpg' | 'png' | 'webp'; uri: string }[];
	slug: string;
}[] = [
	{
		slug: 'sound-healing',
		title: {
			ru: 'Sound Healing',
			en: 'Sound Healing',
		},
		preview: [
			{
				type: 'webp',
				uri: md1w,
			},
			{
				type: 'jpg',
				uri: md1,
			},
		],
		link: '',
		subtitle: {
			ru: 'Вибрации для релаксации, баланса и внутренней гармонии',
			en: 'Therapeutic sounds for relaxation, balance, and inner harmony.',
		},
	},
	{
		slug: 'breathwork',
		title: {
			ru: 'Дыхательные практики',
			en: 'Breathwork',
		},
		preview: [
			{
				type: 'webp',
				uri: md2w,
			},
			{
				type: 'jpg',
				uri: md2,
			},
		],
		link: '',
		subtitle: {
			ru: 'Осознанное дыхание для восстановления жизненных сил, снятия стресса и хорошего самочувствия.',
			en: 'Conscious breathing for vitality, stress reduction, and well-being.',
		},
	},
	{
		slug: 'guided-meditations',
		title: {
			ru: 'Медитации',
			en: 'Guided Meditations',
		},
		preview: [
			{
				type: 'webp',
				uri: md3w,
			},
			{
				type: 'jpg',
				uri: md3,
			},
		],
		link: '',
		subtitle: {
			ru: 'Путешествие по своему сознанию для сосредоточенности и внутреннего покоя.',
			en: 'Mindfulness journeys for mental clarity, focus, and inner peace.',
		},
	},
	{
		slug: 'movement',
		title: {
			ru: 'Движение',
			en: 'Movement',
		},
		preview: [
			{
				type: 'webp',
				uri: md4w,
			},
			{
				type: 'jpg',
				uri: md4,
			},
		],
		link: '',
		subtitle: {
			ru: 'Динамические практики для пробуждения тела, гибкости и силы.',
			en: 'Dynamic practices for body awakening, flexibility, and strength.',
		},
	},
	{
		slug: 'tea-ceremonies',
		title: {
			ru: 'Чайные церемонии',
			en: 'Tea ceremonies',
		},
		preview: [
			{
				type: 'webp',
				uri: md5w,
			},
			{
				type: 'jpg',
				uri: md5,
			},
		],
		link: '',
		subtitle: {
			ru: 'Ритуалы для размышлений, спокойствия и связи с настоящим моментом.',
			en: 'Mindful rituals for reflection, tranquility, and present-moment connection.',
		},
	},
];
