import { useState } from 'react';

interface ILoader {
	loader: {
		loading: boolean;
		toggle: () => void;
		hide: () => void;
		show: () => void;
	};
}
type CustomWindow = Window & typeof globalThis & ILoader;

export const useWindowLoader = () => {
	const w = window as CustomWindow;
	const [loading, setLoading] = useState(w.loader.loading);

	const toggle = () => {
		if (w && w.loader !== undefined) {
			setLoading((prev) => !prev);
			w.loader.toggle();
		}
	};

	const hideLoader = () => {
		if (w && w.loader !== undefined) {
			setLoading(false);
			w.loader.hide();
		}
	};

	const showLoader = () => {
		if (w && w.loader !== undefined) {
			setLoading(true);
			w.loader.show();
		}
	};

	return {
		isAwaitLoader: loading,
		toggleLoader: toggle,
		hideLoader,
		showLoader,
	};
};
