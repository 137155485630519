import { Preview } from 'src/@types';
import cl from './banner.module.scss';

export const Banner = ({
	title,
	preview,
	actions,
	fill = false,
	position = 'center',
}: {
	title: string;
	preview: Preview;
	actions?: JSX.Element;
	fill?: boolean;
	position?: 'top' | 'center' | 'bottom';
}) => {
	return (
		<div className={cl.banner}>
			<picture className={cl.preview}>
				{preview.map((img) => (
					<source type={'image/' + img.type} srcSet={img.uri} key={img.type} />
				))}
				<img
					src={preview[preview.length - 1].uri}
					className={
						cl.preview +
						' ' +
						(fill ? cl.fill : '') +
						' ' +
						cl['position__' + position]
					}
					alt=""
					loading="lazy"
				/>
			</picture>
			<div className={cl.banner__content}>
				<h1 className={cl.title}>{title}</h1>
				{actions}
			</div>
		</div>
	);
};
