import { ISvgIconProps } from '.';

export const CloseModalIcon = ({
	className = '',
	viewBox = '0 0 20 20',
	refComponent = undefined,
}: ISvgIconProps) => {
	return (
		<svg
			className={className}
			ref={refComponent}
			viewBox={viewBox}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				width="20"
				height="20"
				rx="10"
				fill="rgba(184, 14, 146)"
				fillOpacity="0.2"
			/>
			<path
				d="M6 14L13.9997 6.00025"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="square"
			/>
			<path
				d="M6 6L13.9997 13.9997"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="square"
			/>
		</svg>
	);
};
