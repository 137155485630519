import { ISvgIconProps } from '.';

export const InSlugIcon = ({
	className = '',
	viewBox = '0 0 374 414',
	refComponent = undefined,
}: ISvgIconProps) => {
	return (
		<svg
			className={className}
			ref={refComponent}
			viewBox={viewBox}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M32.4514 61.7834C23.5587 61.7834 15.9464 58.7571 9.56781 52.7045C3.18927 46.6751 0 39.3887 0 30.8917C0 22.3947 3.18927 15.1316 9.56781 9.07895C15.9231 3.02632 23.5587 0 32.4514 0C41.3441 0 48.9565 3.02632 55.335 9.07895C61.7136 15.1316 64.9028 22.3947 64.9028 30.8917C64.9028 39.3887 61.7136 46.6518 55.335 52.7045C48.9565 58.7571 41.3441 61.7834 32.4514 61.7834ZM9.26518 407.808V111.229H54.8462V407.831H9.26518V407.808Z"
				fill="currentColor"
			/>
			<path
				d="M183.815 229.395V407.808H138.234V111.229H182.256V157.578H186.12C193.081 142.516 203.626 130.388 217.78 121.169C231.934 111.974 250.231 107.365 272.626 107.365C292.716 107.365 310.292 111.462 325.331 119.633C340.392 127.804 352.102 140.165 360.482 156.717C368.84 173.268 373.03 194.15 373.03 219.385V407.855H327.449V222.481C327.449 199.178 321.396 180.997 309.291 167.938C297.186 154.878 280.588 148.336 259.473 148.336C244.924 148.336 231.957 151.502 220.573 157.788C209.19 164.096 200.204 173.292 193.639 185.397C187.074 197.502 183.792 212.168 183.792 229.418L183.815 229.395Z"
				fill="currentColor"
			/>
		</svg>
	);
};
