import { gsap } from 'gsap';
//@ts-ignore
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger.js';
import {
	LegacyRef,
	forwardRef,
	useEffect,
	useLayoutEffect,
	useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { CardMission } from 'src/components/card/card-mission/card-mission.component';
import { useWindowSize } from 'src/hooks/useWindowSize';
import cl from './section-mission.module.scss';
import { MISSION_MOCK } from './section-mission.mock';

gsap.registerPlugin(ScrollTrigger);

export const SectionMissionGsap = forwardRef(
	(props: unknown, ref: LegacyRef<HTMLElement> | undefined) => {
		const { width = window.innerWidth } = useWindowSize();
		const { t, i18n } = useTranslation();
		const ctxGsapRef = useRef<gsap.Context | null>(null);
		const { ref: refObs, inView: inViewObs } = useInView({
			threshold: 0.5,
		});
		const refList = useRef<HTMLUListElement | null>(null);
		const refText = useRef<HTMLDivElement | null>(null);
		const refSlider = useRef<HTMLDivElement | null>(null);
		const refSection = useRef<HTMLElement | null>(null);

		useEffect(() => {
			if (refText.current && refSlider.current && inViewObs) {
				refText.current.classList.add(cl.viewText);
				refSlider.current.classList.add(cl.viewSlider);
			}
		}, [inViewObs]);

		useLayoutEffect(() => {
			if (ctxGsapRef.current) {
				ctxGsapRef.current.clear();
				ctxGsapRef.current.kill();
			}

			if (refList.current && refList && refSection.current) {
				ctxGsapRef.current = gsap.context((self) => {
					const scrollWidth = refList.current?.scrollWidth || 0;
					gsap.to(refList.current, {
						x: () => (scrollWidth + 36) * -1,
						xPercent: 100,
						ease: 'power1',
						scrollTrigger: {
							trigger: refList.current,
							start: 'center center',
							end: `+=${scrollWidth + 120}px`,
							pin: refSection.current,
							scrub: true,
							invalidateOnRefresh: true,
						},
					});
				});
			}

			return () => {
				ScrollTrigger.killAll();
			};
		}, [width]);

		return (
			<section className={cl.section} ref={refSection}>
				<div className={cl.section} ref={ref as any}>
					<div className={cl.container} ref={refObs}>
						<div className={cl.row}>
							<div className={`${cl.colLeft}`} ref={refText}>
								<p className="desc-section">{t('mission.desc')}</p>
							</div>
							<div className={`${cl.colRight}`} ref={refSlider}>
								<ul className={cl.slide__list} ref={refList}>
									{MISSION_MOCK.map((mission, index) => (
										<li className={`${cl.slide}`} key={index}>
											<CardMission
												title={mission.title[i18n.language]}
												subtitle={mission.subtitle[i18n.language]}
												preview={mission.preview}
											/>
										</li>
									))}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	},
);
