import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Langs } from '../../@types';
import { useWindowSize } from '../../hooks/useWindowSize';
import cl from './switcher-lang.module.scss';
import { LANG_LIST } from './switcher-lang.mock';

export const SwitcherLang = ({
	custom = false,
	classes,
}: {
	custom?: boolean;
	classes?: {
		root?: string;
		title?: string;
		list?: string;
	};
}) => {
	const { i18n } = useTranslation();
	const lang = window.localStorage.i18nextLng;
	const titleLang = LANG_LIST.find((item) => lang === item.lang)?.title || 'EN';
	const { width = window.innerWidth } = useWindowSize();
	const [open, setOpen] = useState(false);
	const handlerToggleOpen = () => {
		setOpen(!open);
	};
	const handlerLang = (value: Langs) => {
		if (lang !== value) {
			let newUrl = lang;
			if (lang === Langs.en) {
				newUrl = window.location.pathname.replace(`/`, `/${value}/`);
			} else if (value === Langs.en) {
				newUrl = window.location.pathname.replace(`/${lang}`, ``);
			} else {
				newUrl = window.location.pathname.replace(`/${lang}`, `/${value}`);
			}
			if (newUrl === '') {
				newUrl = '/';
			}
			window.location = newUrl;
			localStorage.setItem('i18nextLng', value);
			i18n.changeLanguage(value);
			window.location = newUrl;
		}

		width < 1280 && setOpen(false);
	};

	useEffect(() => {
		if (width >= 1280 && open) {
			setOpen(false);
		}
	}, [width, open]);

	return (
		<div className={`${cl.switcherLang + ' ' + (classes?.root || '')}`}>
			<div
				className={`${cl.switcherLangBtn} ${
					custom ? cl.switcherLangBtnCustom : ''
				}`}
				onClick={handlerToggleOpen}
			>
				<span className={`${cl.switcherLangTitle} ${classes?.title || ''}`}>
					{titleLang}
				</span>
			</div>
			<ul
				className={`${cl.switcherLangList} ${
					open ? cl.switcherLangListOpen : ''
				} ${classes?.list || ''}`}
			>
				{LANG_LIST.map((item) => (
					<li
						className={`${cl.switcherLangListItem}`}
						key={item.title}
						onClick={() => handlerLang(item.lang)}
					>
						{lang === item.lang ? (
							<svg
								width="12"
								height="10"
								viewBox="0 0 12 10"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M1 4.68421L4.21429 8L11 1"
									stroke="white"
									strokeWidth="2"
								/>
							</svg>
						) : (
							<div></div>
						)}
						<span
							className={`${cl.switcherLangListItemTitle} ${
								lang === item.lang ? cl.switcherLangListItemSelected : ''
							}`}
						>
							{item.title}
						</span>
					</li>
				))}
			</ul>
		</div>
	);
};
