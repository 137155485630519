import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { Loader } from './loader.component';

interface IPagesLoad {
	home: boolean;
	[key: string]: boolean;
}
export const LoaderPagesContext = createContext<{
	pagesLoad: IPagesLoad;
	onSetPageFirstRender: (key: string, value: boolean) => void;
}>({
	pagesLoad: { home: false },
	onSetPageFirstRender: (key: string, value: boolean) => void 0,
});

export const usePagesLoad = () => {
	const { pagesLoad, onSetPageFirstRender } = useContext(LoaderPagesContext);
	return {
		pagesLoad,
		onSetPageFirstRender,
	};
};

export const PagesLoaderContextProvider = ({
	children,
}: {
	children?: JSX.Element[] | JSX.Element;
}) => {
	const [pagesLoad, setPagesLoad] = useState<IPagesLoad>({ home: false });
	const onSetPageFirstRender = (key: string, value: boolean) =>
		setPagesLoad((prev) => ({ ...prev, [key]: value }));

	const contextValue = { pagesLoad, onSetPageFirstRender };

	return (
		<LoaderPagesContext.Provider value={contextValue}>
			{children}
		</LoaderPagesContext.Provider>
	);
};

export const LoaderContext = createContext<{
	loading: boolean;
	onToggleLoader: (value: boolean) => void;
}>({
	loading: false,
	onToggleLoader: (value: boolean) => void 0,
});

export const useLoader = () => {
	const { loading, onToggleLoader } = useContext(LoaderContext);
	const bodyRef = useRef<HTMLElement | null>();
	const onToggle = (value: boolean) => {
		onToggleLoader(value);
		if (bodyRef.current) bodyRef.current.style.overflow = value ? 'hidden' : '';
	};
	useEffect(() => {
		bodyRef.current = document.querySelector('body');
		return () => {
			if (bodyRef.current) bodyRef.current.style.overflow = '';
		};
	}, []);

	return {
		loading,
		onToggleLoader: onToggle,
	};
};

export const LoaderContextProvider = ({
	children,
}: {
	children?: JSX.Element[] | JSX.Element;
}) => {
	const [loading, setLoading] = useState<boolean>(false);
	const onToggleLoader = (value: boolean) => setLoading(value);

	const contextValue = { loading, onToggleLoader };

	return (
		<LoaderContext.Provider value={contextValue}>
			{children}
		</LoaderContext.Provider>
	);
};
