import { Preview } from 'src/@types';
import { SliderEffectCoverflow } from '../../slider-effect-coverflow/slider-effect-coverflow.component';
import cl from './section-studio.module.scss';

export const SectionStudio = ({
	title,
	items,
	className,
}: {
	className?: string;
	title: string;
	items: { img: Preview; title?: string }[];
	transparent?: boolean;
}) => {
	return (
		<section
			className={
				cl.section + (className ? ' ' + className : cl.section__padding)
			}
		>
			<h2 className={cl.title}>{title}</h2>
			<SliderEffectCoverflow slides={items} negativeMargin />
		</section>
	);
};
