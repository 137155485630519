import cl from './event-price-info.module.scss';

interface EventPriceInfoProps {
	isPrice: boolean;
	price: string;
	usersCount: number;
	maxUsers: number;
	currency: string;
}

const EventPriceInfo = ({ isPrice, price, usersCount, maxUsers, currency }: EventPriceInfoProps) => (
	<div className={cl.event__info__price}>
		{isPrice && (
			<div className={cl.price}>
				{currency} <span>{price}</span>
			</div>
		)}
		<div className={cl.count}>
			<img src="/image/count.svg" alt="count-people" />
			{usersCount}
			<span>/</span>
			{maxUsers}
		</div>
	</div>
);

export default EventPriceInfo;