import { Langs } from 'src/@types';

const TABS_PRIVACY_POLICY_MOCK = [
	`
		<h2>1. General provisions</h2>
		<p>The present policy of personal data processing is drawn up in accordance with the requirements of the Federal Law dated 27.07.2006. No. 152-FZ "On Personal Data" (hereinafter - the Personal Data Law) and defines the procedure of personal data processing and measures to ensure personal data security undertaken by Uprise Agency FZE (hereinafter - the Operator).</p>
		<p>1.1 The Operator sets as its most important goal and condition of its activity the observance of human and citizen's rights and freedoms during the processing of personal data, including the protection of the rights to privacy, personal and family secrecy.</p>
		<p>1.2 This Operator's policy on personal data processing (hereinafter referred to as the Policy) applies to all information that the Operator may obtain about the visitors of the website <a href="https://in-to.ae">https://in-to.ae</a>.</p>
		
		<h2>2. Basic concepts used in the Policy</h2>
		<p>2.1 Automated processing of personal data - processing of personal data by means of computer equipment.</p>
		<p>2.2 Blocking of personal data - temporary cessation of personal data processing (except for cases when processing is necessary to clarify personal data).</p>
		<p>2.3 Website - a set of graphic and informational materials, as well as computer programs and databases, ensuring their availability on the Internet at the network address <a href="https://in-to.ae">https://in-to.ae</a>.</p>
		<p>2.4 Personal data information system - a set of personal data contained in databases and information technologies and technical means ensuring their processing.</p>
		<p>2.5 Personal data depersonalization - actions as a result of which it is impossible to determine, without using additional information, whether personal data belong to a particular User or other subject of personal data.</p>
		<p>2.6 Processing of personal data - any action (operation) or set of actions (operations) performed with the use of automation means or without the use of such means with personal data, including collection, recording, systematization, accumulation, storage, clarification (update, change), extraction, use, transfer (distribution, provision, access), depersonalization, blocking, deletion, destruction of personal data.</p>
		<p>2.7 Operator - a state authority, municipal authority, legal entity or individual, independently or jointly with other persons organizing and/or carrying out processing of personal data, as well as determining the purposes of personal data processing, composition of personal data subject to processing, actions (operations) performed with personal data.</p>
		<p>2.8 Personal Data - any information relating directly or indirectly to a certain or defined User of <a href="https://in-to.ae">https://in-to.ae</a> website.</p>
		<p>2.9 Personal data authorized by the subject of personal data for dissemination - personal data to which access to an unlimited number of persons is granted by the subject of personal data by giving consent to the processing of personal data authorized by the subject of personal data for dissemination in the manner prescribed by the Personal Data Law (hereinafter - personal data authorized for dissemination).</p>
		<p>2.10 User - any visitor of the website <a href="https://in-to.ae">https://in-to.ae</a>.</p>
		<p>2.11 Provision of personal data - actions aimed at disclosure of personal data to a certain person or a certain circle of persons.</p>
		<p>2.12 Dissemination of personal data - any actions aimed at disclosure of personal data to an indefinite number of persons (transfer of personal data) or at familiarization with personal data of an unlimited number of persons, including disclosure of personal data in mass media, placement in information and telecommunication networks or providing access to personal data in any other way.</p>
		<p>2.13 Cross-border transfer of personal data - transfer of personal data to the territory of a foreign country to a foreign government authority, a foreign individual or a foreign legal entity.</p>
		<p>2.14 Destruction of personal data - any actions, as a result of which personal data are irretrievably destroyed with the impossibility of further recovery of the content of personal data in the personal data information system and/or material carriers of personal data are destroyed.</p>
		
		<h2>3. Basic rights and obligations of the Operator</h2>
		<p>3.1 The Operator has the right to:</p>
		<ul>
				<li>to receive from the subject of personal data reliable information and/or documents containing personal data;</li>
				<li>In case the personal data subject revokes his/her consent to the processing of personal data, as well as in case he/she submits a request to stop the processing of personal data, the Operator has the right to continue the processing of personal data without the consent of the personal data subject on the grounds specified in the Personal Data Law;</li>
				<li>independently determine the composition and list of measures necessary and sufficient to ensure the fulfillment of obligations stipulated by the Personal Data Law and regulatory legal acts adopted in accordance with it, unless otherwise provided by the Personal Data Law or other federal laws.</li>
		</ul>
		<p>3.2 The Operator shall:</p>
		<ul>
				<li>provide the personal data subject, upon his/her request, with information regarding the processing of his/her personal data;</li>
				<li>organize the processing of personal data in accordance with the procedure established by the current legislation of the Russian Federation;</li>
				<li>respond to appeals and requests of personal data subjects and their legal representatives in accordance with the requirements of the Law on personal data;</li>
				<li>report to the authorized body for the protection of the rights of personal data subjects at the request of this body the necessary information within 10 days from the date of receipt of such a request;</li>
				<li>publish or otherwise provide unrestricted access to this Policy on personal data processing;</li>
				<li>take legal, organizational and technical measures to protect personal data from unlawful or accidental access to them, destruction, modification, blocking, copying, provision, dissemination of personal data, as well as from other unlawful actions with regard to personal data;</li>
				<li>cease transfer (dissemination, provision, access) of personal data, stop processing and destroy personal data in the manner and cases stipulated by the Personal Data Law;</li>
				<li>fulfill other obligations stipulated by the Personal Data Law.</li>
		</ul>
		
		<h2>4. Basic rights and obligations of personal data subjects</h2>
		<p>4.1 Personal data subjects have the right to:</p>
		<ul>
				<li>To receive information regarding the processing of his/her personal data, except in cases provided for by federal laws. Information shall be provided to the subject of personal data by the Operator in an accessible form and shall not contain personal data relating to other subjects of personal data, except in cases where there are legal grounds for disclosure of such personal data. The list of information and the procedure for obtaining it is established by the Law on Personal Data;</li>
				<li>demand from the operator to clarify his personal data, block or destroy it in case the personal data is incomplete, outdated, inaccurate, illegally obtained or not necessary for the stated purpose of processing, as well as to take measures provided by law to protect his rights;</li>
				<li>to impose a condition of prior consent when processing personal data in order to market goods, works and services;</li>
				<li>to withdraw consent to the processing of personal data, as well as to submit a request to stop the processing of personal data;</li>
				<li>to appeal to the authorized body for the protection of the rights of personal data subjects or in court against unlawful acts or omissions of the Operator in the processing of his/her personal data;</li>
				<li>to exercise other rights provided for by the legislation of the Russian Federation.</li>
		</ul>
		<p>4.2 The subjects of personal data shall be obliged to:</p>
		<ul>
				<li>provide the Operator with reliable data about themselves;</li>
				<li>notify the Operator about clarification (update, change) of their personal data.</li>
		</ul>
		<p>4.3 Persons who provided the Operator with false information about themselves shall bear the responsibility established by the legislation of the Russian Federation.</p>
		
		<h2>5. Basic principles of personal data processing</h2>
		<p>5.1 Personal data shall be processed on a legal and fair basis.</p>
		<p>5.2 The processing of personal data shall be limited to the achievement of specific, predetermined and legitimate purposes. The processing of personal data that is incompatible with the purposes of personal data collection shall not be allowed.</p>
		<p>5.3 The content and volume of processed personal data shall correspond to the stated purposes of processing. The processed personal data shall not be excessive in relation to the stated purposes of processing.</p>
		<p>5.4 The processing of personal data shall be carried out in such a way as to ensure the accuracy and adequacy of personal data in relation to the purposes of processing. The Operator shall take measures to eliminate or clarify incomplete or inaccurate data.</p>
		<p>5.5 The personal data shall be stored in a form which permits the identification of subjects of personal data no longer than is necessary for the purposes of processing personal data.</p>
		<p>5.6 The Operator shall ensure the security of personal data from unauthorized access, destruction, modification, blocking, copying, provision, dissemination of personal data and other unlawful actions of third parties.</p>
		
		<h2>6. Purposes of personal data processing</h2>
		<p>The purposes of personal data processing are:</p>
		<ul>
				<li>implementation of the services requested by the User;</li>
				<li>improving the quality of services provided;</li>
				<li>research and analysis of user activity;</li>
				<li>sending information about products, services and promotions;</li>
				<li>notification of the User on important events, changes in services and other important information.</li>
		</ul>
		
		<h2>7. Legal basis for personal data processing</h2>
		<p>The legal basis for the processing of personal data by the Operator is:</p>
		<ul>
				<li>the consent of the subject of personal data to process his personal data;</li>
				<li>the necessity to perform a contract, to which the subject of personal data is a party, as well as to perform obligations of the Operator in relation to the subject of personal data;</li>
				<li>the necessity to fulfill the obligations established by the legislation of the Russian Federation.</li>
		</ul>
		
		<h2>8. Conditions for the processing of personal data</h2>
		<p>8.1 The Operator processes personal data if:</p>
		<ul>
				<li>the personal data subject has expressed his/her consent to the processing of his/her personal data;</li>
				<li>the processing is necessary for the fulfillment of a contract to which the personal data subject is a party;</li>
				<li>the processing is necessary for the fulfillment of the obligations imposed by the legislation of the Russian Federation;</li>
				<li>the processing is necessary to achieve the purposes set by this Policy.</li>
		</ul>
		<p>8.2 The Operator processes personal data without the consent of the personal data subject in the cases stipulated by the legislation of the Russian Federation.</p>
		
		<h2>9. Terms of personal data storage</h2>
		<p>The Operator shall store personal data in a form which permits the identification of subjects of personal data no longer than is necessary for the purposes of processing personal data.</p>
		<p>When processing personal data, the Operator shall ensure compliance with the principles of their storage: personal data shall be stored in the information system for a period not exceeding that required for the purposes of their processing. Upon the achievement of the goals of personal data processing or in case of withdrawal of consent to processing personal data by the subject of personal data, personal data shall be destroyed in the manner and within the time limits established by the legislation of the Russian Federation.</p>
		
		<h2>10. Cross-border transfer of personal data</h2>
		<p>10.1 The Operator shall take the necessary measures to prevent any unauthorized access to personal data during its cross-border transfer.</p>
		<p>10.2 The Operator shall ensure that personal data is transferred only to the countries that provide adequate protection of the rights of the subjects of personal data in accordance with the legislation of the Russian Federation.</p>

		<h2>11. Confidentiality of personal data</h2>
		<p>The Operator and other persons who have access to personal data are obliged not to disclose to third parties and not to disseminate personal data without the consent of the subject of personal data, unless otherwise provided by federal law.</p>
		
		<h2>12. Final provisions</h2>
		<p>12.1 This Policy is subject to change. The Operator shall notify the Users about any changes to this Policy by publishing the new version of the Policy on the website <a href="https://in-to.ae">https://in-to.ae</a>.</p>
		<p>12.2 The Operator encourages the Users to periodically check this Policy for updates.</p>
		<p>12.3 The current version of the Policy is available on the website <a href="https://in-to.ae">https://in-to.ae</a>.</p>
	`,
	`
		<p>
			The user, having left his personal data in the form and clicking on the button "SEND" on the website 
			<a href="https://in-to.ae/" target="_blank">https://in-to.ae/</a> and its subdomains, accepts the present Consent to the processing 
			of personal data (hereinafter - Consent).
		</p>
		
		<p>
			Acting freely, by one's own will and in one's own interest, and also confirming one's own legal capacity, the User 
			gives his consent to the Limited Company the responsibility of "Chatme AI" (hereinafter referred to as the Operator), 
			which owns the website: <a href="https://in-to.ae/" target="_blank">https://in-to.ae/</a> and which is located at: 416654, UAE, 
			Dubai, Downtown, Emaar Square, Building 4, office 104-105 for the processing of their personal data subject to the following conditions:
		</p>
		
		<p>
			1. Personal data is processed in accordance with the Operator's Policy regarding the processing of personal data 
			(hereinafter referred to as the Policy), the relevant freely available version is located on the website: 
			<a href="https://in-to.ae/" target="_blank">https://in-to.ae/</a>.
		</>
		
		<p>
			2. Consent is given to the processing of the following personal data:
			<ul>
				<li>Full Name</li>
				<li>Official name of the organization</li>
				<li>Phone number</li>
				<li>Email address</li>
			</ul>
			The above data further in the text of the Consent are united by a common concept "Personal Data".
		</p>
		
		<p>
			3. Consent is granted to the processing of personal data performed using automation tools.
		</p>
		
		<p>
			4. The personal data provided is not publicly available.
		</p>
		
		<p>
			5. The processing of personal data means any action (operation) or a set of actions (operations) performed using 
			automation or without such means with personal data, including collection, recording, systematization, 
			accumulation, storage, clarification (updating, changing), extraction, use, transfer (provision, access), 
			blocking, deletion, destruction of personal data.
		</p>
		
		<p>
			6. The purpose of the processing of personal data is the possibility of sending feedback with the User by sending 
			a response to their message left through a special form on the website: 
			<a href="https://in-to.ae/" target="_blank">https://in-to.ae/</a>.
		</p>
		
		<p>7. The basis for the processing of personal data is this Agreement.</p>
		
		<p>
			8. The operator ensures the safety of personal data and accepts all possible measures to prevent access to 
			personal data by unauthorized persons.
		</p>
		
		<p>
			9. The User's personal data will not be transferred to third parties without prior consent of the User, except as 
			otherwise provided by federal law.
		</p>
		
		<p>
			10. Processing and storage of personal data is carried out from the moment of consent provided by the User and 
			until the expiration of 30 days from the date of achievement of the goal processing of personal data. After 30 
			days from the date of achievement of the purpose of processing personal data, the operator is obliged to stop 
			processing and destroy personal data.
		</p>
		
		<p>
			11. Consent may be withdrawn by the User by sending to the Operator notifications to the email address: 
			<a href="mailto:hello@in-to.ae">hello@in-to.ae</a> with a subject "Withdrawal of consent to processing of personal data". 
			Upon receipt of said notification, the Operator stops processing and destroys personal data relating to the User.
		</p
		
		<p>
			12. This consent is valid at all times until its withdrawal in accordance with clause 11 of the Consent.
		</p>
	`,
];

const TABS_PRIVACY_POLICY_RU_MOCK = [
	`
		<h2>1. Общие положения</h2>
		<p>Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального закона от 27.07.2006. № 152-ФЗ «О персональных данных» (далее — Закон о персональных данных) и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных, предпринимаемые in to (далее — Оператор).</p>
		<p>1.1. Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.</p>
		<p>1.2. Настоящая политика Оператора в отношении обработки персональных данных (далее — Политика) применяется ко всей информации, которую Оператор может получить о посетителях веб-сайта <a href="https://in-to.ae/">https://in-to.ae/</a>.</p>
		
		<h2>2. Основные понятия, используемые в Политике</h2>
		<p>2.1. Автоматизированная обработка персональных данных — обработка персональных данных с помощью средств вычислительной техники.</p>
		<p>2.2. Блокирование персональных данных — временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных).</p>
		<p>2.3. Веб-сайт — совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу <a href="https://in-to.ae/">https://in-to.ae/</a>.</p>
		<p>2.4. Информационная система персональных данных — совокупность содержащихся в базах данных персональных данных и обеспечивающих их обработку информационных технологий и технических средств.</p>
		<p>2.5. Обезличивание персональных данных — действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных.</p>
		<p>2.6. Обработка персональных данных — любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.</p>
		<p>2.7. Оператор — государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и/или осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.</p>
		<p>2.8. Персональные данные — любая информация, относящаяся прямо или косвенно к определенному или определяемому Пользователю веб-сайта <a href="https://in-to.ae/">https://in-to.ae/</a>.</p>
		<p>2.9. Персональные данные, разрешенные субъектом персональных данных для распространения, — персональные данные, доступ неограниченного круга лиц к которым предоставлен субъектом персональных данных путем дачи согласия на обработку персональных данных, разрешенных субъектом персональных данных для распространения в порядке, предусмотренном Законом о персональных данных (далее — персональные данные, разрешенные для распространения).</p>
		<p>2.10. Пользователь — любой посетитель веб-сайта <a href="https://in-to.ae/">https://in-to.ae/</a>.</p>
		<p>2.11. Предоставление персональных данных — действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц.</p>
		<p>2.12. Распространение персональных данных — любые действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом.</p>
		<p>2.13. Трансграничная передача персональных данных — передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу.</p>
		<p>2.14. Уничтожение персональных данных — любые действия, в результате которых персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе персональных данных и/или уничтожаются материальные носители персональных данных.</p>
		
		<h2>3. Основные права и обязанности Оператора</h2>
		<p>3.1. Оператор имеет право:</p>
		<ul>
				<li>получать от субъекта персональных данных достоверные информацию и/или документы, содержащие персональные данные;</li>
				<li>в случае отзыва субъектом персональных данных согласия на обработку персональных данных, а также, направления обращения с требованием о прекращении обработки персональных данных, Оператор вправе продолжить обработку персональных данных без согласия субъекта персональных данных при наличии оснований, указанных в Законе о персональных данных;</li>
				<li>самостоятельно определять состав и перечень мер, необходимых и достаточных для обеспечения выполнения обязанностей, предусмотренных Законом о персональных данных и принятыми в соответствии с ним нормативными правовыми актами, если иное не предусмотрено Законом о персональных данных или другими федеральными законами.</li>
		</ul>
		
		<p>3.2. Оператор обязан:</p>
		<ul>
				<li>предоставлять субъекту персональных данных по его просьбе информацию, касающуюся обработки его персональных данных;</li>
				<li>организовывать обработку персональных данных в порядке, установленном действующим законодательством РФ;</li>
				<li>отвечать на обращения и запросы субъектов персональных данных и их законных представителей в соответствии с требованиями Закона о персональных данных;</li>
				<li>сообщать в уполномоченный орган по защите прав субъектов персональных данных по запросу этого органа необходимую информацию в течение 10 дней с даты получения такого запроса;</li>
				<li>публиковать или иным образом обеспечивать неограниченный доступ к настоящей Политике в отношении обработки персональных данных;</li>
				<li>принимать правовые, организационные и технические меры для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных;</li>
				<li>прекратить передачу (распространение, предоставление, доступ) персональных данных, прекратить обработку и уничтожить персональные данные в порядке и случаях, предусмотренных Законом о персональных данных;</li>
				<li>исполнять иные обязанности, предусмотренные Законом о персональных данных.</li>
		</ul>
		
	 	<h2>4. Основные права и обязанности субъектов персональных данных</h2>
		<h3>4.1. Субъекты персональных данных имеют право:</h3>
		<ul>
			<li>
				получать информацию, касающуюся обработки его персональных данных, за исключением случаев, предусмотренных федеральными законами. Сведения предоставляются субъекту персональных данных Оператором в доступной форме, и в них не должны содержаться персональные данные, относящиеся к другим субъектам персональных данных, за исключением случаев, когда имеются законные основания для раскрытия таких персональных данных. Перечень информации и порядок ее получения установлен Законом о персональных данных;
			</li>
			<li>
				требовать от оператора уточнения его персональных данных, их блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав;
			</li>
			<li>
				выдвигать условие предварительного согласия при обработке персональных данных в целях продвижения на рынке товаров, работ и услуг;
			</li>
			<li>
				на отзыв согласия на обработку персональных данных, а также, на направление требования о прекращении обработки персональных данных;
			</li>
			<li>
				обжаловать в уполномоченный орган по защите прав субъектов персональных данных или в судебном порядке неправомерные действия или бездействие Оператора при обработке его персональных данных;
			</li>
			<li>на осуществление иных прав, предусмотренных законодательством РФ.</li>
		</ul>

		<h3>4.2. Субъекты персональных данных обязаны:</h3>
		<ul>
			<li>предоставлять Оператору достоверные данные о себе;</li>
			<li>сообщать Оператору об уточнении (обновлении, изменении) своих персональных данных.</li>
		</ul>

		<h3>4.3. Лица, передавшие Оператору недостоверные сведения о себе, либо сведения о другом субъекте персональных данных без согласия последнего, несут ответственность в соответствии с законодательством РФ.</h3>

		<h2>5. Принципы обработки персональных данных</h2>
		<ul>
			<li>Обработка персональных данных осуществляется на законной и справедливой основе.</li>
			<li>Обработка персональных данных ограничивается достижением конкретных, заранее определенных и законных целей.</li>
			<li>Не допускается объединение баз данных, содержащих персональные данные, обработка которых осуществляется в целях, несовместимых между собой.</li>
			<li>Обработке подлежат только персональные данные, которые отвечают целям их обработки.</li>
			<li>Содержание и объем обрабатываемых персональных данных соответствуют заявленным целям обработки.</li>
			<li>
				При обработке персональных данных обеспечивается точность персональных данных, их достаточность, а в необходимых случаях и актуальность.
			</li>
			<li>
				Хранение персональных данных осуществляется в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных.
			</li>
		</ul>

		<h2>6. Условия обработки персональных данных</h2>
		<ul>
			<li>Обработка персональных данных осуществляется с согласия субъекта персональных данных.</li>
			<li>Обработка персональных данных необходима для достижения целей, предусмотренных международным договором Российской Федерации или законом.</li>
			<li>Обработка персональных данных необходима для осуществления правосудия, исполнения судебного акта.</li>
			<li>Обработка персональных данных необходима для исполнения договора, стороной которого является субъект персональных данных.</li>
			<li>Обработка персональных данных необходима для осуществления прав и законных интересов оператора или третьих лиц.</li>
			<li>Осуществляется обработка общедоступных персональных данных.</li>
			<li>Осуществляется обработка персональных данных, подлежащих опубликованию.</li>
		</ul>

		<h2>7. Порядок сбора, хранения, передачи и других видов обработки персональных данных</h2>
		<ul>
			<li>Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации правовых, организационных и технических мер.</li>
			<li>Персональные данные Пользователя никогда не будут переданы третьим лицам, за исключением случаев, предусмотренных законодательством.</li>
			<li>Пользователь может актуализировать свои данные, направив уведомление на адрес электронной почты Оператора.</li>
			<li>Срок обработки персональных данных определяется достижением целей, для которых они были собраны.</li>
		</ul>

		<h2>8. Перечень действий, производимых Оператором с полученными персональными данными</h2>
		<ul>
			<li>Оператор осуществляет сбор, запись, систематизацию, накопление, хранение, уточнение, использование, передачу, обезличивание, блокирование, удаление и уничтожение персональных данных.</li>
			<li>Оператор осуществляет автоматизированную обработку персональных данных.</li>
		</ul>

		<h2>9. Трансграничная передача персональных данных</h2>
		<ul>
			<li>Оператор обязан уведомить уполномоченный орган о своем намерении осуществлять трансграничную передачу персональных данных.</li>
			<li>Оператор обязан получить от иностранных лиц соответствующие сведения.</li>
		</ul>

		<h2>10. Конфиденциальность персональных данных</h2>
		<p>Оператор и иные лица обязаны не раскрывать третьим лицам и не распространять персональные данные без согласия субъекта персональных данных, если иное не предусмотрено федеральным законом.</p>

		<h2>11. Заключительные положения</h2>
		<ul>
			<li>Пользователь может получить разъяснения, обратившись к Оператору по электронной почте <a href="mailto:HELLO@IN-TO.AE">HELLO@IN-TO.AE</a>.</li>
			<li>Изменения политики обработки персональных данных Оператором будут отражены в данном документе.</li>
			<li>Актуальная версия Политики доступна по адресу <a href="https://in-to.ae/" target="_blank">https://in-to.ae/</a>.</li>
		</ul>
	`,
	`
		<p>
			Настоящим в соответствии с Федеральным законом № 152-ФЗ «О персональных данных» от 27.07.2006 года, 
			я, субъект персональных данных, отправляя информацию через формы обратной связи, при прохождении процедуры регистрации 
			в целях использования сайта и в дальнейшем при использовании сайта <a href="https://in-to.ae/" target="_blank">https://in-to.ae/</a> 
			и/или его сервисов, свободно, своей волей и в своем интересе выражаю свое безусловное согласие на 
			обработку моих персональных данных «in to», зарегистрированным в соответствии с 
			законодательством РФ по адресу: 416654, ОАЭ, Дубай, Центр города, площадь Эмаар, корпус 4, 
			офис 104-105 (далее по тексту - Оператор).
		</p>
	
		<p>Настоящее Согласие выдано мною на обработку следующих персональных данных:</p>
		
		<ul>
			<li>Фамилия, Имя и Отчество;</li>
			<li>Адрес электронной почты;</li>
			<li>Данные, которые автоматически передаются в процессе просмотра и при посещении страниц Сайта: IP адрес, информация из cookies, информация о браузере, время доступа, адрес посещаемой страницы, реферер (адрес предыдущей страницы);</li>
			<li>Статистики о моих IP-адресах.</li>
		</ul>
	
		<p>
			Субъект персональных данных, подтверждает, осведомлен и согласен, что для вышеуказанных целей, 
			Оператор вправе собирать и использовать дополнительную информацию, связанную с субъектом персональных данных, 
			получаемую в процессе доступа к сайту и/или сервисам, или от третьих лиц, и включающую в себя данные о технических 
			средствах (в том числе, мобильных устройствах) и способах технологического взаимодействия с Сайтом и/или его сервисами 
			(в т. ч. IP-адрес хоста, вид операционной системы, тип браузера, географическое положение, данные о провайдере и иное), 
			об активности субъекта персональных данных при использовании Сайта и/или его сервисов, cookies, об информации об ошибках, 
			выдаваемых субъекту персональных данных, о скачанных файлах, видео, инструментах; распоряжаться статистической информацией, 
			связанной с функционированием Сайта и/или его сервисами, а также информацией субъекта персональных данных для целей 
			организации функционирования и технической поддержки Сайта и/или его сервисов и исполнения условий законодательства Российской Федерации.
		</p>
	
		<p>
			Согласие дано Оператору для совершения следующих действий с моими персональными данными с использованием средств автоматизации и/или без использования таких средств:
			сбор, систематизация, накопление, хранение, уточнение (обновление, изменение), использование, обезличивание, а также осуществление любых иных действий, предусмотренных действующим законодательством РФ как неавтоматизированными, так и автоматизированными способами.</li>
		</p>
	
		<p>Данное согласие дается Оператору для обработки моих персональных данных в следующих целях:</p>
	
		<ul>
			<li>предоставление мне услуг/работ;</li>
			<li>направление в мой адрес уведомлений, оказания поддержки и консультирования, касающихся предоставляемых услуг/работ;</li>
			<li>подготовка и направление ответов на мои запросы;</li>
			<li>направление в мой адрес информации, в том числе рекламной, о мероприятиях/товарах/услугах/работах Оператора;</li>
			<li>аналитики действий субъекта персональных данных на сайте <a href="https://in-to.ae/" target="_blank">https://in-to.ae/</a> и функционирования указанного сайта.</li>
		</ul>
	
		<p>
			Настоящее согласие действует до момента его отзыва путем направления соответствующего уведомления на электронный адрес 
			<a href="mailto:hello@in-to.ae">hello@in-to.ae</a>. В случае отзыва мною согласия на обработку персональных данных Оператор вправе 
			продолжить обработку персональных данных без моего согласия при наличии оснований, указанных в пунктах 2 – 11 части 1 статьи 6, 
			части 2 статьи 10 и части 2 статьи 11 Федерального закона №152-ФЗ «О персональных данных» от 27.07.2006 г.
		</p>
	`,
];

export const MOCK_PRIVACY_POLICY = {
	[Langs.en]: TABS_PRIVACY_POLICY_MOCK,
	[Langs.ru]: TABS_PRIVACY_POLICY_RU_MOCK,
};
