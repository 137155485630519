import { Suspense, useMemo, useState } from 'react';
import { IMasterData } from 'src/@types';
import { CardTeam } from 'src/components/card/card-team/card-team.component';
import ModalUser from 'src/components/modals/modal-user/modal-user.component';
import { SliderUsers } from '../../slider-users/slider-users.component';
import { useWindowSize } from 'src/hooks/useWindowSize';
import cl from './section-masters.module.scss';

export const SectionMasters = ({
	loading,
	masters,
	header,
	transparent = false,
}: {
	loading: boolean;
	header?: JSX.Element;
	masters: IMasterData[];
	transparent?: boolean;
}) => {
	const { width = window.innerWidth } = useWindowSize();
	const [mastersState, setMastersData] = useState<IMasterData | null>(null);
	const isMobile = useMemo(() => {
		return width < 1280;
	}, [width, masters]);
	const onClickCard = (id?: number) => {
		if (id) {
			const master = masters.find((item) => item.id === id);
			setMastersData(master || null);
		}
	};
	return (
		<section className={cl.section + ' ' + (transparent ? '' : cl.color)}>
			<div className="container">
				{header}
				{masters.length >= 3 ? (
					<SliderUsers
						slides={masters.map((item) => ({
							key: item.id,
							position: item.position,
							preview: item.image,
							title: item.name,
							id: item.id,
							description: item.about,
						}))}
						isLoading={loading}
						translate
						onClickCard={onClickCard}
					/>
				) : isMobile && masters.length === 2 ? (
					<SliderUsers
						slides={masters.map((item) => ({
							key: item.id,
							position: item.position,
							preview: item.image,
							title: item.name,
							id: item.id,
							description: item.about,
						}))}
						isLoading={loading}
						translate
						onClickCard={onClickCard}
					/>
				) : (
					<div className={cl.row}>
						{masters.map((item) => (
							<CardTeam
								key={item.id}
								preview={item.image}
								title={item.name}
								position={item.position}
								description={item.about}
								translate={true}
								onClick={() => onClickCard(item.id)}
							/>
						))}
					</div>
				)}
			</div>
			<Suspense fallback={<div />}>
				<ModalUser data={mastersState} setData={setMastersData} />
			</Suspense>
		</section>
	);
};
