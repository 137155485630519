import { ISvgIconProps } from '.';

export const CloseEyeIcon = ({
	className = '',
	viewBox = '0 0 20 20',
	refComponent = undefined,
}: ISvgIconProps) => {
	return (
		<svg
			className={className}
			ref={refComponent}
			viewBox={viewBox}
			width="20"
			height="20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8.06298 12.0655C7.56566 11.5691 7.26294 10.8945 7.26294 10.1377C7.26294 8.62147 8.48419 7.39935 9.99952 7.39935C10.7494 7.39935 11.4396 7.70294 11.9283 8.1994"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.6852 10.623C12.4846 11.7387 11.6058 12.6192 10.491 12.8216"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.3765 14.7515C4.00389 13.6738 2.84145 12.0997 1.99902 10.1372C2.8501 8.16608 4.02032 6.5833 5.40158 5.49697C6.7742 4.41064 8.35785 3.82077 9.99945 3.82077C11.6506 3.82077 13.2334 4.41929 14.6146 5.5134"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16.4414 7.4158C17.0364 8.20633 17.5597 9.11881 18 10.1368C16.2987 14.0782 13.2922 16.4524 9.99953 16.4524C9.25311 16.4524 8.51707 16.3313 7.80957 16.0952"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16.8213 3.31506L3.17822 16.9582"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
