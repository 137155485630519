import cl from './terabit.module.scss';

export const Terabit = () => {
	return (
		<div>
			<p className={cl.title}>Website development</p>
			<a
				href="https://terabit.ai/"
				target="_blank"
				rel="noflow, noindex, noopener, noreferrer"
				className={cl.link}
			>
				<svg
					width="121"
					height="26"
					viewBox="0 0 121 26"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					className={cl.logo}
				>
					<path
						d="M0 5.84082V6.26364C0 8.71208 1.96493 10.6885 4.39909 10.6885H7.22428V20.5806C7.22428 23.029 9.1892 25.0055 11.6234 25.0055H12.2392V10.6885H15.1133C17.5475 10.6885 19.5124 8.71208 19.5124 6.26364V5.84082H0Z"
						fill="#0CC1F8"
					/>
					<path
						d="M101.238 5.84082V6.26364C101.238 8.71208 103.203 10.6885 105.637 10.6885H108.463V20.5806C108.463 23.029 110.427 25.0055 112.862 25.0055H113.478V10.6885H116.352C118.786 10.6885 120.751 8.71208 120.751 6.26364V5.84082H101.238Z"
						fill="#0CC1F8"
					/>
					<path
						d="M99.724 25.0059H99.0006C96.5665 25.0059 94.6016 23.0294 94.6016 20.581V5.49707H95.325C97.7591 5.49707 99.724 7.47352 99.724 9.92195V25.0059Z"
						fill="#0CC1F8"
					/>
					<path
						d="M84.0049 5.51685V5.49718H78.6184V5.34968C78.6184 2.90125 76.6535 0.924805 74.2194 0.924805H73.6328V14.7304H73.6524C73.6426 14.9173 73.6328 15.0943 73.6328 15.2811C73.6328 20.6893 77.9928 25.065 83.3597 25.065C88.7266 25.065 93.0865 20.6794 93.0865 15.2811C93.0865 10.0991 89.0785 5.85117 84.0049 5.51685ZM83.3401 20.1583C80.642 20.1583 78.4522 17.9557 78.4522 15.2417V10.3252H83.3401C86.0382 10.3252 88.228 12.5278 88.228 15.2417C88.228 17.9557 86.048 20.1583 83.3401 20.1583Z"
						fill="#0CC1F8"
					/>
					<path
						d="M63.0542 25.0746H66.681H71.6666V15.8414H71.6569C71.6666 15.6546 71.6764 15.4776 71.6764 15.2907C71.6764 9.88255 67.3164 5.50684 61.9495 5.50684C56.5826 5.50684 52.2227 9.89238 52.2227 15.2907C52.2227 20.4826 56.2405 24.7206 61.3141 25.055V25.0746H63.0542ZM57.0617 15.3301C57.0617 12.6161 59.2514 10.4135 61.9495 10.4135C64.6476 10.4135 66.8374 12.6161 66.8374 15.3301V20.2466H61.9495C59.2514 20.2564 57.0617 18.0538 57.0617 15.3301Z"
						fill="#0CC1F8"
					/>
					<path
						d="M52.5435 5.49707H46.6389C39.9132 5.49707 36.9609 9.95145 36.9609 14.2485V25.0059H37.6061C40.0403 25.0059 42.0052 23.0294 42.0052 20.581V14.7401C42.0052 12.2917 43.9702 10.3153 46.4043 10.3153H48.1542C50.5883 10.3153 52.5533 8.33883 52.5533 5.89039V5.49707H52.5435Z"
						fill="#0CC1F8"
					/>
					<path
						d="M30.5484 17.6609H27.6254C26.3448 17.6609 25.3086 16.6186 25.3086 15.3304C25.3086 14.0423 26.3448 13 27.6254 13H34.9475V13.236C34.9475 15.6844 32.9728 17.6609 30.5484 17.6609Z"
						fill="#0CC1F8"
					/>
					<path
						d="M28.6916 10.3745C28.6916 10.3745 28.9457 10.3745 28.9946 10.3745H30.5489C32.9733 10.3745 34.948 8.38826 34.948 5.94966V5.45801L28.3983 5.46784C23.2367 5.70383 19.1211 9.99105 19.1211 15.2419C19.1211 20.4338 23.1389 24.6915 28.2125 25.0258L34.9578 25.0061V24.6325C34.9578 22.1841 32.9929 20.2076 30.5587 20.2076H28.9555C26.2476 20.2076 23.9601 17.9755 23.9796 15.2517C23.9894 12.6066 26.0814 10.463 28.6916 10.3745Z"
						fill="#0CC1F8"
					/>
				</svg>
			</a>
		</div>
	);
};
