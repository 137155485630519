import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ISwiper, RoutsPath } from '../../../@types';
import { CardPost } from 'src/components/card/card-post/card-post.component';
import { Button } from '../../ui/buttons/button/button.component';
import { useWindowSize } from 'src/hooks/useWindowSize';
import { ArrowRightIcon } from '../../ui/icons';
import { createLangHref } from 'src/utils/href.utils';
import imgBg from '../../../assets/img/or-bg.png';
import cl from './section-our-meditation.module.scss';
import { MEDITATION_OUR_MOCK } from './section-our-meditation.mock';

const BREAKPOINTS = {
	0: {
		slidesPerView: 1,
	},
	575: {
		slidesPerView: 1.8,
	},
	768: {
		slidesPerView: 2.8,
	},
	996: {
		slidesPerView: 3.1,
	},
	1279: {
		slidesPerView: 5,
	},
};
export const SectionOurMeditation = () => {
	const navigation = useNavigate();
	const { t, i18n } = useTranslation();
	const refSwiper = useRef<ISwiper | null>(null);
	const prevRef = useRef<HTMLDivElement | null>(null);
	const nextRef = useRef<HTMLDivElement | null>(null);
	const [activeSlide, setActiveSlide] = useState(0);
	const onSlideChange = (sw: ISwiper) => {
		setActiveSlide(sw.activeIndex);
	};
	const goto = (slug: string) => {
		navigation(createLangHref(i18n.language, RoutsPath.practice + '/' + slug));
	};
	const gotoPractice = () => {
		navigation(createLangHref(i18n.language, RoutsPath.practice));
	};

	useEffect(() => {
		if (refSwiper.current) {
			const swiper = refSwiper.current as any;
			swiper.params.navigation.prevEl = prevRef.current;
			swiper.params.navigation.nextEl = nextRef.current;
			swiper.navigation.init();
			swiper.navigation.update();
		}
	}, [refSwiper]);

	return (
		<section className={cl.section} style={{ background: `url(${imgBg})` }}>
			<h3 className={cl.section__title}>{t('section.meditation.title')}</h3>
			<div
				className={
					cl.navigation__btn +
					' ' +
					cl.navigation__btn__prev +
					' ' +
					(activeSlide === 0 ? cl.hide : '')
				}
				ref={prevRef}
				role="button"
			>
				<ArrowRightIcon className={cl.navigation__btn__icon} />
			</div>
			<div
				className={
					cl.navigation__btn +
					' ' +
					cl.navigation__btn__next +
					' ' +
					(activeSlide === 4 ? cl.hide : '')
				}
				ref={nextRef}
				role="button"
			>
				<ArrowRightIcon className={cl.navigation__btn__icon} />
			</div>

			<div className={cl.content}>
				<Swiper
					breakpoints={BREAKPOINTS}
					slidesPerView={2.5}
					spaceBetween={0}
					pagination={{
						clickable: true,
					}}
					modules={[Navigation]}
					className={'swiper-our-meditation ' + cl.slider}
					onSwiper={(sw: unknown) => (refSwiper.current = sw as ISwiper)}
					onSlideChange={onSlideChange}
				>
					{MEDITATION_OUR_MOCK.map((item, index) => (
						<SwiperSlide key={index}>
							<div className={cl.slide}>
								<h4
									className={cl.title + ' ' + cl.title__visible}
									dangerouslySetInnerHTML={{
										__html: item.title[i18n.language],
									}}
								/>
								<div className={cl.hover}>
									<picture className={cl.slide__img}>
										{item.preview.map((img) => (
											<source
												type={'image/' + img.type}
												srcSet={img.uri}
												key={img.type}
											/>
										))}
										<img
											src={item.preview[1].uri}
											className={cl.slide__img}
											alt=""
											loading="lazy"
										/>
									</picture>
									<div className={cl.content__hover}>
										<p
											className={cl.title}
											dangerouslySetInnerHTML={{
												__html: item.title[i18n.language],
											}}
										/>
										<p className={cl.desc}>{item.subtitle[i18n.language]}</p>
										<div className={cl.footer}>
											<Button
												className={cl.btn}
												onClick={() => {
													goto(item.slug);
												}}
											>
												{t('more')}
											</Button>
											<Button
												theme="white-outline"
												className={cl.btn}
												onClick={gotoPractice}
											>
												{t('btn.our.practic')}
											</Button>
										</div>
									</div>
								</div>
							</div>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		</section>
	);
};

export const SectionOurMeditationGrid = () => {
	const navigation = useNavigate();
	const { t, i18n } = useTranslation();
	const goto = (slug: string) => {
		navigation(createLangHref(i18n.language, RoutsPath.practice + '/' + slug));
	};
	return (
		<section className={cl['section-mobile']}>
			<div className="container">
				<h3 className={cl.section__title__mobile}>
					{t('section.meditation.title')}
				</h3>

				<div className={cl.grid}>
					<div className={cl.grid__row}>
						<CardPost
							title={MEDITATION_OUR_MOCK[0].title[i18n.language]}
							preview={MEDITATION_OUR_MOCK[0].preview}
							onClick={() => goto(MEDITATION_OUR_MOCK[0].slug)}
						/>
						<CardPost
							title={MEDITATION_OUR_MOCK[1].title[i18n.language]}
							preview={MEDITATION_OUR_MOCK[1].preview}
							onClick={() => goto(MEDITATION_OUR_MOCK[1].slug)}
						/>
					</div>
					<div className={cl.grid__row}>
						<CardPost
							title={MEDITATION_OUR_MOCK[2].title[i18n.language]}
							preview={MEDITATION_OUR_MOCK[2].preview}
							onClick={() => goto(MEDITATION_OUR_MOCK[2].slug)}
						/>
					</div>
					<div className={cl.grid__row}>
						<CardPost
							title={MEDITATION_OUR_MOCK[3].title[i18n.language]}
							preview={MEDITATION_OUR_MOCK[3].preview}
							onClick={() => goto(MEDITATION_OUR_MOCK[3].slug)}
						/>
						<CardPost
							title={MEDITATION_OUR_MOCK[4].title[i18n.language]}
							preview={MEDITATION_OUR_MOCK[4].preview}
							onClick={() => goto(MEDITATION_OUR_MOCK[4].slug)}
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

export const SectionOurMeditationContainer = () => {
	const { width = window.innerWidth } = useWindowSize();
	const isMobile = useMemo(() => {
		return width < 768;
	}, [width]);
	return isMobile ? <SectionOurMeditationGrid /> : <SectionOurMeditation />;
};
