import * as Yup from 'yup';

const ERRORS = {
	req: 'error.req',
	phone: 'error.phone',
	min: 'error.min',
	max: 'error.max',
	email: 'error.email',
};

export const orderFormSchema = Yup.object().shape({
	full_name: Yup.string()
		.required(ERRORS.req)
		.min(2, ERRORS.min)
		.max(150, ERRORS.max),
	phone: Yup.string()
		.required(ERRORS.req)
		.test('include', ERRORS.phone, (val) => {
			return val?.indexOf('_') === -1 ? true : false;
		}),
	email: Yup.string()
		.required(ERRORS.req)
		.email(ERRORS.email)
		.max(150, ERRORS.max),
	message: Yup.string().min(2, ERRORS.min).max(400, 'Максимальная длина 400'),
	address: Yup.string()
		.required(ERRORS.req)
		.min(2, ERRORS.min)
		.max(150, ERRORS.max),
});

export type OrderFormData = Yup.InferType<typeof orderFormSchema>;
