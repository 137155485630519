import React from 'react';
import { IMasterData, IScheduleItemData } from '../../../@types';
import EventInfoModalContent from '../../modals-content/event-info-modal-content/event-info-modal-content.component';
import Modal from '../../ui/modal/modal.component';
import { CloseModalIcon } from '../../ui/icons';
import cl from './modal-event.module.scss';

interface IModalEventProps {
	event: IScheduleItemData | null;
	setEvent: (value: IScheduleItemData | null) => void;
	handleClose: () => void;
	onUpdateEvent: () => void;
	onClickSpeaker: (value: IMasterData) => void;
}

const ModalEvent = ({
	event,
	setEvent,
	handleClose,
	onUpdateEvent,
	onClickSpeaker,
}: IModalEventProps) => {
	if (!event) return null;

	const hasImage = !!event.image;
	const contentClasses = `${cl.eventContent} ${!hasImage ? cl.eventContentSmall : ''}`;

	const handleCloseModal = () => {
		setEvent(null);
		handleClose();
	}

	return (
		<Modal
			open={!!event}
			onClose={handleCloseModal}
			animate="fade"
			classes={{
				content: cl.content,
			}}
			closeIcon={<CloseModalIcon />}
			portal
		>
			<div className={contentClasses}>
				{hasImage && (
					<div className={cl.eventContent__image}>
						<img
							src={event.image ?? undefined}
							alt={event.title}
							loading="lazy"
						/>
					</div>
				)}

				<div className={`${cl.eventContent__info} modal-scroll`}>
					<EventInfoModalContent
						event={event}
						setEvent={setEvent}
						onUpdateEvent={onUpdateEvent}
						onClickMaster={onClickSpeaker}
					/>
				</div>
			</div>
		</Modal>
	);
};

export default ModalEvent;
