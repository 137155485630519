import { useTranslation } from 'react-i18next';
import { ICardCartProps } from './card-cart.model';
import { Counter } from '../../ui/counter/counter.component';
import { useCart } from '../../cart/cart.context';
import { CURRENCY } from '../../../const/settings.const';
import cl from './card-cart.module.scss';

export const CardCart = ({ data, classes }: ICardCartProps) => {
	const { getCountProduct, onAdd, onSub, onRemove } = useCart();
	const { t } = useTranslation();
	const { root = '' } = classes || {};
	const count = getCountProduct(data.product.id, data.size, data.color);
	const add = () => onAdd(data.product, data?.size, data?.color);
	const sub = () => onSub(data.product, data?.size, data?.color);
	const remove = () => onRemove(data.product.id, data?.size, data.color);

	return (
		<div className={cl.card + ' ' + root}>
			<img
				className={cl.preview}
				src={
					data.product?.main_image?.image
						? process.env.REACT_APP_API_URL + data.product.main_image.image
						: '/image/products/empty.jpg'
				}
				alt=""
				loading="lazy"
			/>
			<div className={cl.body}>
				<div className={cl.body__info}>
					<h4 className={cl.name}>{data.product.name}</h4>
					<div className={cl.row__info}>
						<div className={cl.color__container}>
							<div className={cl.color__title}>{t('label-color')}:</div>
							<div
								className={cl.color__color}
								style={{
									backgroundColor:
										data.product?.main_image?.color?.html_code || '#000',
								}}
							>
								<span
									style={{
										backgroundColor:
											data.product?.main_image?.color?.html_code || '#000',
									}}
								></span>
							</div>
						</div>
						{data.product?.weignt && (
							<div className={cl.color__container}>
								<div className={cl.color__title}>{t('weignt')}:</div>
								<div className={cl.row__info__value}>
									{data.product?.weignt}
									{t('units')}
								</div>
							</div>
						)}
					</div>
				</div>
				<div className={cl.row}>
					<div className={cl.price + ' ' + cl.gray}>
						<span>{CURRENCY}</span> {data.product.price}
					</div>
					<Counter amount={count} onAdd={add} onSub={sub} />
					<div className={cl.price}>
						<span>{CURRENCY}</span> {Number(data.product.price) * data.amount}
					</div>
					<div className={cl.icon} role="button" onClick={remove}>
						<svg
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<rect
								width="20"
								height="20"
								rx="10"
								fill="rgba(184, 14, 146)"
								fillOpacity="0.2"
							/>
							<path
								d="M6 14L13.9997 6.00025"
								stroke="white"
								strokeWidth="2"
								strokeLinecap="square"
							/>
							<path
								d="M6 6L13.9997 13.9997"
								stroke="white"
								strokeWidth="2"
								strokeLinecap="square"
							/>
						</svg>
					</div>
				</div>
			</div>
		</div>
	);
};
