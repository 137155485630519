import { useEffect, useRef } from 'react';
import {
	EffectCoverflow,
	Navigation,
	Pagination,
	Parallax,
} from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ISwiper } from '../../@types';
import { ArrowRightIcon } from '../ui/icons';
import cl from './slider-effect-coverflow.module.scss';
import 'swiper/css/effect-coverflow';

const BREAKPOINTS = {
	0: {
		coverflowEffect: {
			rotate: 10,
			stretch: 20,
			depth: 0,
			modifier: 1,
			slideShadows: false,
			scale: 0.8,
		},
		centeredSlides: true,
		slidesPerView: 1.2,
		spaceBetween: 10,
	},
	768: {
		slidesPerView: 1.6,
		spaceBetween: 30,
		coverflowEffect: {
			rotate: 10,
			stretch: 0,
			depth: 0,
			modifier: 1,
			slideShadows: false,
		},
	},
	1280: {
		coverflowEffect: {
			rotate: 50,
			stretch: -50,
			depth: 100,
			modifier: 1,
			slideShadows: false,
		},
		centeredSlides: true,
		slidesPerView: 2,
		spaceBetween: 20,
	},
	1440: {
		coverflowEffect: {
			rotate: 50,
			stretch: -100,
			depth: 100,
			modifier: 1,
			slideShadows: false,
		},
		spaceBetween: 20,
	},
	1800: {
		spaceBetween: 40,
	},
};

export const SliderEffectCoverflow = ({
	slides,
	negativeMargin = false,
}: {
	slides: { img: { type: string; uri: string }[]; title?: string }[];
	negativeMargin?: boolean;
}) => {
	const refSwiper = useRef<ISwiper | null>(null);
	const prevRef = useRef<HTMLDivElement | null>(null);
	const nextRef = useRef<HTMLDivElement | null>(null);
	useEffect(() => {
		if (refSwiper.current) {
			const swiper = refSwiper.current as any;
			swiper.params.navigation.prevEl = prevRef.current;
			swiper.params.navigation.nextEl = nextRef.current;
			swiper.navigation.init();
			swiper.navigation.update();
		}
	}, [refSwiper]);

	return (
		<div className={cl.container}>
			<div
				className={cl.navigation__btn + ' ' + cl.navigation__btn__prev}
				ref={prevRef}
				role="button"
			>
				<ArrowRightIcon className={cl.navigation__btn__icon} />
			</div>
			<div
				className={cl.navigation__btn + ' ' + cl.navigation__btn__next}
				ref={nextRef}
				role="button"
			>
				<ArrowRightIcon className={cl.navigation__btn__icon} />
			</div>
			<div
				className={cl.wrap + ' ' + (negativeMargin ? cl.negativeMargin : '')}
			>
				<Swiper
					breakpoints={BREAKPOINTS}
					effect={'coverflow'}
					grabCursor={true}
					centeredSlides={true}
					init={false}
					loop={true}
					speed={800}
					slidesPerView={2}
					spaceBetween={0}
					onSwiper={(sw: unknown) => (refSwiper.current = sw as ISwiper)}
					coverflowEffect={{
						rotate: 50,
						stretch: -100,
						depth: 100,
						modifier: 1,
						slideShadows: false,
					}}
					parallax={true}
					modules={[EffectCoverflow, Parallax, Navigation, Pagination]}
					pagination={true}
					className={cl.swiper + ' swiper-studio'}
				>
					{slides.map((slide, index) => (
						<SwiperSlide className={cl.swiper__slide} key={index}>
							<picture className={cl.swiper__slide__img}>
								{slide.img.map((itemImg) => (
									<source
										type={'image/' + itemImg.type}
										srcSet={itemImg.uri}
										key={itemImg.type}
									/>
								))}
								<img
									src={slide.img[1].uri}
									className={cl.swiper__slide__img}
									alt=""
									loading="lazy"
								/>
							</picture>
							{slide.title && (
								<h4 className={cl.slide__title}>{slide.title}</h4>
							)}
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		</div>
	);
};
