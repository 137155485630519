import { ISvgIconProps } from '.';

export const CloseInputIcon = ({
	className = '',
	viewBox = '0 0 17 16',
	refComponent = undefined,
}: ISvgIconProps) => {
	return (
		<svg
			width="17"
			className={className}
			height="16"
			viewBox={viewBox}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			ref={refComponent}
		>
			<rect
				x="0.5"
				width="16"
				height="16"
				rx="8"
				fill="#C2D0FB"
				fillOpacity="0.1"
			/>
			<path
				d="M6.5 9.99988L10.5 6M6.5 6.00012L10.5 10"
				stroke="#EBE9F0"
				strokeOpacity="0.8"
				strokeWidth="1.5"
				strokeLinecap="square"
			/>
		</svg>
	);
};
