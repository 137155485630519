import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RoutsPath } from 'src/@types';
import { AsideCart } from '../../components/aside/aside-cart/aside-cart.component';
import { CardCart } from '../../components/card/card-cart/card-cart.component';
import { useCart } from '../../components/cart/cart.context';
import ContentLayout from '../../components/layout/content-layout/content-layout.component';
import { PageLayout } from '../../components/layout/page.layout';
import { Breadcrumbs } from '../../components/ui/breadcrumbs/breadcrumbs.component';
import { Button } from 'src/components/ui/buttons/button/button.component';
import cl from './index.module.scss';

const CartPage = () => {
	const { t } = useTranslation();
	const navigation = useNavigate();

	const { products } = useCart();
	const gotoShop = () => {
		navigation(RoutsPath.catalog);
	};

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, []);

	return (
		<PageLayout positionHeader="fixed">
			<div className={cl.page}>
				<div className={'header-offset'} />
				<div className="container">
					<div className={'box20'}></div>
					<Breadcrumbs items={[{ name: 'page.cart', translate: true }]} />
					<h1 className={'title-section-small ' + cl.title}>
						{t('page.cart')}
					</h1>
					<div className={'box20'}></div>
					{products.length > 0 ? (
						<ContentLayout
							aside={<AsideCart />}
							marginTop={false}
							small
							classes={{ cl_aside: cl.aside, cl_root: cl.root }}
						>
							<ul className={cl.cart__list}>
								{products.map((item) => (
									<li
										key={item.size + '-' + item.color + '-' + item.product.id}
									>
										<CardCart data={item} />
									</li>
								))}
							</ul>
						</ContentLayout>
					) : (
						<div className={cl.cart__empty}>
							<h3 className={cl.cart__empty__title}>{t('cart.empty')}</h3>
							<p className={cl.cart__empty__desc}>{t('cart.empty.text')}</p>
							<Button
								theme="rainbow"
								className={cl.cart__empty__btn}
								onClick={gotoShop}
							>
								{t('label.tobuyers')}
							</Button>
						</div>
					)}
				</div>
			</div>
		</PageLayout>
	);
};

export default CartPage;
