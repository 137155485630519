import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { RoutsPath } from '../../../@types';
import { createLangHref } from '../../../utils/href.utils';
import cl from './aside-profile.module.scss';

const MENU = {
	[RoutsPath.profile]: {
		label: 'page.profile',
		icon: (
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M18.9989 21.8743C20.7247 21.4306 22 19.8642 22 18V6C22 3.79086 20.2091 2 18 2H6C3.79086 2 2 3.79086 2 6V18C2 19.8642 3.27532 21.4306 5.00111 21.8743M18.9989 21.8743C18.6796 21.9563 18.3449 22 18 22H6C5.6551 22 5.32039 21.9563 5.00111 21.8743M18.9989 21.8743C18.9318 18.0663 15.824 15 12 15C8.17601 15 5.06818 18.0663 5.00111 21.8743M15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12C13.6569 12 15 10.6569 15 9Z"
					stroke="currentColor"
					strokeWidth="2"
				/>
			</svg>
		),
	},
	[RoutsPath.events_profile.replace(':status', 'active')]: {
		label: 'page.profile.events',
		icon: (
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M8 10H16M8 14H16M8 18H12M8 4C8 5.10457 8.89543 6 10 6H14C15.1046 6 16 5.10457 16 4M8 4C8 2.89543 8.89543 2 10 2H14C15.1046 2 16 2.89543 16 4M8 4H7C4.79086 4 3 5.79086 3 8V18C3 20.2091 4.79086 22 7 22H17C19.2091 22 21 20.2091 21 18V8C21 5.79086 19.2091 4 17 4H16"
					stroke="#EBE9F0"
					strokeWidth="2"
					strokeLinecap="round"
				/>
			</svg>
		),
	},
	// [RoutsPath.orders_profile]: {
	// 	label: 'page.profile.orders',
	// 	icon: (
	// 		<svg
	// 			width="24"
	// 			height="24"
	// 			viewBox="0 0 24 24"
	// 			fill="none"
	// 			xmlns="http://www.w3.org/2000/svg"
	// 		>
	// 			<path
	// 				d="M9 6L9 7C9 8.65685 10.3431 10 12 10C13.6569 10 15 8.65685 15 7V6"
	// 				stroke="#EBE9F0"
	// 				strokeWidth="2"
	// 				strokeLinecap="round"
	// 				strokeLinejoin="round"
	// 			/>
	// 			<path
	// 				d="M15.6113 3H8.38836C6.433 3 4.76424 4.41365 4.44278 6.3424L2.77612 16.3424C2.36976 18.7805 4.24994 21 6.72169 21H17.278C19.7498 21 21.6299 18.7805 21.2236 16.3424L19.5569 6.3424C19.2355 4.41365 17.5667 3 15.6113 3Z"
	// 				stroke="#EBE9F0"
	// 				strokeWidth="2"
	// 				strokeLinejoin="round"
	// 			/>
	// 		</svg>
	// 	),
	// },
};

export const AsideNavigation = () => {
	const { t, i18n } = useTranslation();
	const location = useLocation();
	const split_path = location.pathname.split('/');
	const active = split_path[split_path.length - 1];

	return (
		<ul className={cl.list}>
			{Object.entries(MENU).map((item, index) => {
				const path = item[0].split('/');
				// Проверка на совпадение с двумя путями
				const isEventsActive =
					item[0].includes('/profile/events') &&
					location.pathname.includes('/profile/events');

				return (
					<li
						key={index}
						className={
							(path[path.length - 1] === active && item[0].includes(active)) ||
							isEventsActive
								? cl.active
								: ''
						}
					>
						<Link to={createLangHref(i18n.language, item[0])}>
							{item[1].icon}
							{t(item[1].label)}
						</Link>
					</li>
				);
			})}
		</ul>
	);
};
