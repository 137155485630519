import cl from './specification.module.scss';

export const Specification = ({
	items,
	classes,
}: {
	items: {
		label: string;
		value: string;
		dataStyle?: { label?: string; value?: string };
	}[];
	classes?: { root?: string; list_item?: string; list?: string };
}) => {
	const { list_item = '', list = '' } = classes || {};
	return (
		<ul className={cl.specifications + ' ' + list}>
			{items.map((item, index) => (
				<li className={cl.specifications__item + ' ' + list_item} key={index}>
					<div
						className={cl.specifications__item__type}
						data-style={item?.dataStyle?.label || 'label'}
					>
						{item.label}
					</div>
					<div className={cl.specifications__item__sp} />
					<div
						className={cl.specifications__item__name}
						data-style={item?.dataStyle?.value || 'value'}
					>
						{item.value}
					</div>
				</li>
			))}
		</ul>
	);
};
