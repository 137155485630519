import { useMutation } from '@tanstack/react-query';
import { format, parse } from 'date-fns';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	IMasterData,
	IScheduleItemData,
	Participate,
	Schedule as ScheduleEnum,
} from '../../../@types';
import { queryClient } from '../../../application/application.component';
import Masters from '../../masters/masters.component';
import ActionButton from './common/action-button.component';
import Divider from './common/divider.component';
import EventDateInfo from './common/event-data-info.component';
import EventDescription from './common/event-description.component';
import EventPriceInfo from './common/event-price-info.component';
import LocationInfo from './common/location-info.component';
import { useAuth } from '../../../application/application.auth.context';
import { ModalAuthContext } from '../../modals/modal-auth/modal-auth.context';
import { useScheduleFilters } from '../../schedule/schedule-filters/schedule-filters.context';
import { CURRENCY } from 'src/const/settings.const';
import { apiServices } from '../../../services/api';
import { convertDotDate, dateFnsParse } from '../../../utils/date.utils';
import cl from './event-info-modal-content.module.scss';

interface EventContentInfoProps {
	event: IScheduleItemData;
	setEvent: (value: IScheduleItemData | null) => void;
	onUpdateEvent: () => void;
	onClickMaster: (value: IMasterData) => void;
}

const EventInfoModalContent = ({
	event,
	setEvent,
	onUpdateEvent,
	onClickMaster,
}: EventContentInfoProps) => {
	const { toggleOpen } = useContext(ModalAuthContext);
	const { dateRange, eventType, filterMaster, filterGenre } =
		useScheduleFilters();
	const navigate = useNavigate();
	const location = useLocation();
	const { isAuth, user } = useAuth();
	const { i18n, t } = useTranslation();
	const isPastEvent = useMemo(() => {
		if (event && event.date && event.time_finish) {
			const fullDateString = `${event.date.split(' ')[0]} ${event.time_finish}`;
			const eventDateTime = parse(
				fullDateString,
				'dd.MM.yyyy HH:mm:ss',
				new Date(),
			);
			if (isNaN(eventDateTime.getTime())) {
				console.error('Invalid event date format:', fullDateString);
				return false;
			}
			return eventDateTime < new Date();
		}
		return false;
	}, [event]);

	const soldOut = useMemo(() => {
		return (
			event?.max_users === event?.users_cnt &&
			event?.user_status?.status !== 'BOOK'
		);
	}, [event]);

	const isCancel = useMemo(() => {
		return !!(
			event &&
			event.user_status &&
			event.user_status.status === 'BOOK'
		);
	}, [event]);

	const generateButtonText = useMemo(() => {
		if (isPastEvent) return t('btn.label.past.event');

		if (soldOut) return t('btn.event.modal.soldOut');

		if (isAuth) {
			if (isCancel) return t('you.cancelled');

			return t('btn.event.modal');
		}
		return t('btn.signup.modal');
	}, [soldOut, isAuth, isCancel, t]);

	const isPrice = useMemo(() => {
		return (
			event &&
			event?.price !== '0.00' &&
			event?.price !== '0.0' &&
			event.price !== '' &&
			event.price !== '0' &&
			event.price !== undefined
		);
	}, [event]);

	const mutation = useMutation({
		mutationKey: ['cancel_participate', 'participate'],
		mutationFn: (data: { id: string; participate: Participate }) => {
			return apiServices
				.updateScheduleParticipate(data.id, data.participate)
				.then((response) => {
					if (response?.payment_link) {
						window.location.href = response.payment_link;
					} else {
						onUpdateEvent();
					}
					return response;
				});
		},
		onSuccess(_, value) {
			const filters = {
				start_date: dateRange[0]
					? format(dateRange[0], 'yyyy-MM-dd')
					: undefined,
				end_date: dateRange[1] ? format(dateRange[1], 'yyyy-MM-dd') : undefined,
				type: eventType === ScheduleEnum.ALL ? undefined : eventType,
				masters:
					filterMaster.master.values === '-1'
						? undefined
						: filterMaster.master.values,
				genre:
					filterGenre.genre.values === '-1'
						? undefined
						: filterGenre.genre.values,
			};
			if (value.participate === 'cancel_participate') {
				queryClient.invalidateQueries({
					queryKey: [
						filters?.start_date,
						filters?.end_date,
						filters?.type,
						filters?.masters,
						filters?.genre,
						'schedule',
					],
				});
				event &&
					setEvent({
						...event,
						user_status: { status: 'NOT BOOK', status_text: 'Отписан' },
					});
			}
		},
	});

	const onSingUp = () => {
		if (!mutation.isPending) {
			if (isAuth && user && event) {
				mutation.mutate({
					id: event.id.toString(),
					participate:
						event.user_status && event.user_status.status !== 'BOOK'
							? 'participate'
							: 'cancel_participate',
				});
			} else {
				const searchParams = new URLSearchParams(location.search);
				searchParams.set('eventId', String(event?.id));
				const date = event?.date ? event.date.split(' ')[0] : '';
				searchParams.set('date', date);
				navigate(`${location.pathname}?${searchParams.toString()}`, {
					replace: true,
				});
				setEvent(null);
				toggleOpen(true);
			}
		}
	};

	const date_string = event?.date.split(' ');
	const time_finish = event?.time_finish ? event.time_finish.split(':') : null;
	const time_start = date_string ? date_string[1].split(':') : null;

	const { week, day, month } = dateFnsParse(
		event?.date && date_string ? convertDotDate(date_string[0]) : null,
		i18n.language,
		{
			replace_week_ru: true,
		},
	);

	return (
		<div
			className={`${cl.eventContentInfo} ${!isPastEvent && isCancel ? cl.eventContentInfo__active : ''}`}
		>
			{!isPastEvent && isCancel && (
				<div className={cl.tag}>
					<span>{t('you.recorded')}</span>
				</div>
			)}

			<h4 className={cl.title}>{event.title}</h4>

			{event.event_location && (
				<LocationInfo eventLocation={event.event_location} />
			)}

			<div className={cl.event__info}>
				<EventDateInfo
					week={week}
					day={day}
					month={month}
					timeStart={time_start}
					timeFinish={time_finish}
				/>
				<EventPriceInfo
					isPrice={isPrice}
					price={event.price}
					usersCount={event.users_cnt}
					maxUsers={event.max_users}
					currency={CURRENCY}
				/>
			</div>

			<div className={cl.buttonWrapper}>
				<ActionButton
					isPastEvent={isPastEvent}
					isCancel={isCancel}
					disabled={soldOut || isPastEvent}
					onClick={onSingUp}
					text={generateButtonText}
				/>
			</div>

			<Divider />

			<Masters masters={event.masters} onClickMaster={onClickMaster} />

			<Divider />

			<EventDescription description={event.description} />
		</div>
	);
};

export default EventInfoModalContent;
