import cl from './card-elevate.module.scss';

export const CardElevate = ({
	title,
	description,
	image,
	folder = 'company-retreats',
}: {
	image: string;
	title: string;
	description?: string;
	folder?: string;
}) => {
	return (
		<div className={cl.card}>
			<picture className={cl.card__img}>
				<source
					media="(min-width: 768px)"
					type="image/png"
					srcSet={`/image/${folder}/${image}.png`}
				/>
				<source
					media="(min-width: 0px)"
					type="image/png"
					srcSet={`/image/${folder}/${image}-m.png`}
				/>
				<img
					src={`/image/company-retreats/${image}.png`}
					className={cl.card__img}
					alt=""
					loading="lazy"
					decoding="async"
				/>
			</picture>
			{description && (
				<>
					<p className={cl.card__description}>{description}</p>
					<img
						className={cl.card__hover}
						src="/image/hover-bg.svg"
						alt=""
						decoding="async"
					/>
				</>
			)}

			<h4 className={cl.card__title}>{title}</h4>
		</div>
	);
};
