import { useTranslation } from 'react-i18next';
import { Preview } from 'src/@types';
import img1 from './assets/1.jpg';
import imgWebp1 from './assets/1.webp';
import img3 from './assets/4.jpg';
import imgWebp3 from './assets/4.webp';
import img2 from './assets/6.jpg';
import imgWebp2 from './assets/6.webp';

export const IMAGES_COMPANY_RETREATS: { img: Preview; title?: string }[] = [
	{
		img: [
			{
				type: 'webp',
				uri: imgWebp1,
			},
			{
				type: 'jpg',
				uri: img1,
			},
		],
		title: '',
	},
	{
		img: [
			{
				type: 'webp',
				uri: imgWebp2,
			},
			{
				type: 'jpg',
				uri: img2,
			},
		],
		title: '',
	},
	{
		img: [
			{
				type: 'webp',
				uri: imgWebp3,
			},
			{
				type: 'jpg',
				uri: img3,
			},
		],
		title: '',
	},
	{
		img: [
			{
				type: 'webp',
				uri: imgWebp1,
			},
			{
				type: 'jpg',
				uri: img1,
			},
		],
		title: '',
	},
];

export const useCompanyRetreatsReviews = (): {
	name: string;
	message: string;
	preview: Preview;
}[] => {
	const { t } = useTranslation();
	return [
		{
			name: t('retreats.reviews.items.one.name'),
			message: t('retreats.reviews.items.one.message'),
			preview: [
				{
					type: 'webp',
					uri: '/image/company-retreats/r.webp',
				},
				{
					type: 'jpg',
					uri: '/image/company-retreats/r.jpg',
				},
			],
		},
		{
			name: t('retreats.reviews.items.two.name'),
			message:
				t('retreats.reviews.items.two.message') +
				t('retreats.reviews.items.two.message'),
			preview: [
				{
					type: 'webp',
					uri: '/image/company-retreats/r.webp',
				},
				{
					type: 'jpg',
					uri: '/image/company-retreats/r.jpg',
				},
			],
		},
	];
};

export const useCompanyRetreatsPost = (): {
	preview: {
		type: 'webp' | 'jpg' | 'png';
		uri: string;
	}[];
	title: string;
	subtitle: string;
}[] => {
	const { t } = useTranslation();
	return [
		{
			subtitle: t('retreats.variants.post.one.description'),
			title: t('retreats.variants.post.one.title'),
			preview: [
				{
					type: 'webp',
					uri: '/image/company-retreats/s1.webp',
				},
				{
					type: 'jpg',
					uri: '/image/company-retreats/s1.jpg',
				},
			],
		},
		{
			subtitle: t('retreats.variants.post.two.description'),
			title: t('retreats.variants.post.two.title'),
			preview: [
				{
					type: 'webp',
					uri: '/image/company-retreats/s2.webp',
				},
				{
					type: 'jpg',
					uri: '/image/company-retreats/s2.jpg',
				},
			],
		},
		{
			subtitle: t('retreats.variants.post.tree.description'),
			title: t('retreats.variants.post.tree.title'),
			preview: [
				{
					type: 'webp',
					uri: '/image/company-retreats/s3.webp',
				},
				{
					type: 'jpg',
					uri: '/image/company-retreats/s3.jpg',
				},
			],
		},
	];
};
