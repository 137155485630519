import {
	differenceInDays,
	endOfISOWeek,
	endOfWeek,
	format,
	isSameISOWeek,
	startOfISOWeek,
	startOfWeek,
} from 'date-fns';
import { enUS, ru } from 'date-fns/esm/locale';
import { useEffect, useId, useState } from 'react';
import DatePicker, { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { IInputDateRangeProps } from './input.model';
import { dateParse } from '../../../utils/date.utils';
import './input.scss';

const renderCustomHeader = (
	{
		date,
		decreaseMonth,
		increaseMonth,
		prevMonthButtonDisabled,
		nextMonthButtonDisabled,
	}: ReactDatePickerCustomHeaderProps,
	lang: string = 'ru',
) => {
	const date_parse = dateParse(date, { incline: false, lang });
	return (
		<div
			style={{
				marginLeft: '10px',
				marginRight: '10px',
				marginTop: '20px',
				paddingBottom: '10px',
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				borderBottom: '1px solid rgba(194, 208, 251, 0.10)',
			}}
		>
			<button
				onClick={decreaseMonth}
				style={{ background: 'transparent', border: 'none', cursor: 'pointer' }}
				disabled={prevMonthButtonDisabled}
			>
				<svg
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M12 6L8 10L12 14"
						stroke="#EBE9F0"
						strokeOpacity="0.5"
						strokeWidth="1.5"
					/>
					<rect
						x="-0.75"
						y="0.75"
						width="18.5"
						height="18.5"
						rx="9.25"
						transform="matrix(-1 0 0 1 18.5 0)"
						stroke="#494187"
						strokeOpacity="0.5"
						strokeWidth="1.5"
					/>
				</svg>
			</button>
			<div className="input-calendar-header-date">
				{date_parse.mount_label} {date_parse.yyyy}
			</div>

			<button
				onClick={increaseMonth}
				style={{ background: 'transparent', border: 'none', cursor: 'pointer' }}
				disabled={nextMonthButtonDisabled}
			>
				<svg
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M8 6L12 10L8 14"
						stroke="#EBE9F0"
						strokeOpacity="0.5"
						strokeWidth="1.5"
					/>
					<rect
						x="0.75"
						y="0.75"
						width="18.5"
						height="18.5"
						rx="9.25"
						stroke="#494187"
						strokeOpacity="0.5"
						strokeWidth="1.5"
					/>
				</svg>
			</button>
		</div>
	);
};

export const InputDateRange = ({
	classes,
	value,
	onChangeDate,
	endAdornment,
	startAdornment,
	label,
	id,
	error: errorProps,
	placeholder,
	lang,
	isSelectWeek = false,
	isClearable = false,
	theme = 'default',
}: IInputDateRangeProps) => {
	const isError = errorProps !== undefined;
	const id_field = useId();
	const [dateRange, setDateRange] = useState<[Date | null, Date | null]>(
		value ? value : [null, null],
	);

	const [startDate, setStartDate] = useState<Date | null>(null);
	const [endDate, setEndDate] = useState<Date | null>(null);
	const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

	useEffect(() => {
		if (value === undefined) {
			setStartDate(null);
			setEndDate(null);
			setSelectedDate(null);
			setDateRange([null, null]);
		}
	}, [value]);

	/**
	 * Обрабатывает обновление диапазона дат.
	 * Если разница между датами составляет 7 или более дней, диапазон устанавливается напрямую.
	 * Если разница меньше 7 дней, вычисляется начало и конец недели.
	 */
	const handleDateRangeUpdate = (
		start: Date | null,
		end: Date | null,
		onChangeDate: (dates: [Date | null, Date | null]) => void,
	) => {
		if (start && end) {
			if (differenceInDays(end, start) >= 7) {
				// Устанавливаем выбранный диапазон, если разница >= 7 дней
				setDateRangeAndNotify([start, end], onChangeDate);
			} else {
				// Вычисляем начало и конец недели, если разница < 7 дней
				const weekStart = startOfWeek(start, { weekStartsOn: 1 });
				const weekEnd = endOfWeek(start, { weekStartsOn: 1 });
				setDateRangeAndNotify([weekStart, weekEnd], onChangeDate);
			}
		} else {
			// Устанавливаем значения, если один из параметров (start или end) равен null
			setDateRangeAndNotify([start, end], onChangeDate);
		}
	};

	/**
	 * Устанавливает начальную и конечную даты диапазона,
	 * обновляет диапазон и уведомляет об изменениях.
	 */
	const setDateRangeAndNotify = (
		dates: [Date | null, Date | null],
		onChangeDate: (dates: [Date | null, Date | null]) => void,
	) => {
		const [start, end] = dates;
		setStartDate(start);
		setEndDate(end);
		setDateRange(dates);
		onChangeDate(dates);
	};

	/**
	 * Обрабатывает обновление одной выбранной даты.
	 * Если дата не равна null, вычисляются начало и конец недели,
	 * иначе диапазон сбрасывается.
	 */
	const handleSingleDateUpdate = (
		selectedDate: Date | null,
		onChangeDate: (dates: [Date | null, Date | null]) => void,
	) => {
		if (selectedDate !== null) {
			// Если выбрана дата, устанавливаем начало и конец недели
			const start = startOfISOWeek(selectedDate);
			const end = endOfISOWeek(selectedDate);
			setDateRangeAndNotify([start, end], onChangeDate);
		} else {
			// Сбрасываем диапазон, если дата не выбрана
			setDateRangeAndNotify([null, null], onChangeDate);
		}
	};

	/**
	 * Основная функция onChange для обработки изменения даты или диапазона дат.
	 * Определяет, является ли обновление массивом дат (диапазон) или одной датой,
	 * и вызывает соответствующую обработку.
	 */
	const onChange = (update: [Date | null, Date | null] | Date | null) => {
		if (Array.isArray(update)) {
			// Обрабатываем диапазон дат
			const [start, end] = update;
			handleDateRangeUpdate(start, end, onChangeDate);
		} else {
			// Обрабатываем одну дату
			handleSingleDateUpdate(update, onChangeDate);
		}
	};

	const header = (values: ReactDatePickerCustomHeaderProps) =>
		renderCustomHeader(values, lang);

	const locale = lang === 'en' ? enUS : ru;

	return (
		<div className={`input-form-control input-calendar ${classes?.root || ''}`}>
			{label && (
				<label className="input-form-control__label" htmlFor={id || id_field}>
					{label}
				</label>
			)}
			<div
				className={`input-form-control__wrapper-input ${
					isError ? 'error' : ''
				} ${classes?.control || ''}`}
			>
				{startAdornment && (
					<div
						className={`input-form-control-adornment input-form-control-adornment__start ${
							classes?.startAdornment || ''
						} ${startDate && endDate ? ' active' : ''}`}
					>
						{startAdornment}
					</div>
				)}

				{isSelectWeek ? (
					<DatePicker
						value={
							dateRange[0] && dateRange[1]
								? `${format(dateRange[0], 'dd.MM.yyyy')} - ${format(
										dateRange[1],
										'dd.MM.yyyy',
									)}`
								: undefined
						}
						placeholderText={placeholder}
						className={
							`input-form-control__input offset_right ${startAdornment ? 'offset_left' : ''} ${theme} ` +
								classes?.input || ''
						}
						calendarClassName={'input-form-calendar'}
						popperClassName="input-form-popper"
						onChange={onChange}
						isClearable={isClearable}
						dateFormat="dd.MM.yyyy"
						renderCustomHeader={header}
						selected={value ? selectedDate : undefined}
						dayClassName={(date: Date) => {
							if (selectedDate) {
								return isSameISOWeek(date, selectedDate)
									? 'react-datepicker__day--selected custom'
									: '';
							}

							return '';
						}}
						calendarStartDay={1}
						locale={locale}
					/>
				) : (
					<DatePicker
						value={
							dateRange[0] && dateRange[1]
								? `${format(dateRange[0], 'dd.MM.yyyy')} - ${format(
										dateRange[1],
										'dd.MM.yyyy',
									)}`
								: undefined
						}
						placeholderText={placeholder}
						className={
							`input-form-control__input offset_right ${startAdornment ? 'offset_left' : ''} ${theme} ` +
								classes?.input || ''
						}
						calendarClassName={'input-form-calendar'}
						popperClassName="input-form-popper"
						selected={value ? selectedDate : null}
						selectsRange={true}
						startDate={startDate}
						endDate={endDate}
						onChange={onChange}
						isClearable={isClearable}
						disabledKeyboardNavigation
						dateFormat="dd.MM.yyyy"
						renderCustomHeader={header}
						calendarStartDay={1}
						locale={locale}
						dayClassName={(date: Date) => {
							if (startDate || endDate) {
								return '';
							}

							if (selectedDate) {
								return isSameISOWeek(date, selectedDate)
									? 'react-datepicker__day--selected custom'
									: '';
							}

							return '';
						}}
					/>
				)}

				{endAdornment && startDate === null && endDate === null && (
					<div
						className={`input-form-control-adornment input-form-control-adornment__end ${
							classes?.endAdornment || ''
						}`}
					>
						{endAdornment}
					</div>
				)}
			</div>
			{
				<div className={`input-form-control__error ${isError ? 'error' : ''}`}>
					{errorProps || ''}
				</div>
			}
		</div>
	);
};
