import React, { useEffect, useId, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import { InputPhoneProps } from './input.model';
import './input-phone.scss';
import 'react-phone-input-2/lib/style.css';
import { localization } from './localization-rus';
import { masks } from './masks';

export const InputPhoneField: React.FC<InputPhoneProps> = ({
	field,
	form: { setFieldValue },
	id,
	value,
	country,
	label,
	onBlur,
	onFocus,
	onUpdateCountry,
	autofocus = false,
	error,
	disabled = false,
}) => {
	const id_field = useId();
	const { i18n } = useTranslation();
	const [phone, setPhone] = useState(value || country?.dialCode);
	const [placeholder, setPlaceholder] = useState('');
	const [countryCode] = useState('ae');

	const handleOnMount = (phone: string, countryDate: CountryData) => {
		const customMask = masks[countryDate.countryCode as keyof typeof masks];

		if (customMask) {
			setFieldValue('country', {
				...countryDate,
				format: customMask,
			});
			onUpdateCountry({
				...countryDate,
				format: customMask,
			});
		} else {
			setFieldValue('country', countryDate);
			onUpdateCountry(countryDate);
		}
	};

	useEffect(() => {
		const formatPlaceholder = (format: string, phoneNumber: string) => {
			let formattedPlaceholder = '';
			let phoneIndex = 0;
			phoneNumber = phoneNumber.replace(/\+/g, '');
			for (let i = 0; i < format.length; i++) {
				if (i < format.length) {
					if (format[i] === '.' && phoneIndex < phoneNumber.length) {
						// Заменяем оставшиеся точки на цифры из введенного номера
						formattedPlaceholder += phoneNumber[phoneIndex];
						phoneIndex++;
					} else if (format[i] === '.') {
						// Заменяем оставшиеся точки на нули
						formattedPlaceholder += '0';
					} else {
						// Сохраняем любые другие символы (например, пробелы)
						formattedPlaceholder += format[i];
					}
				}
			}

			return formattedPlaceholder;
		};

		const newPlaceholder = formatPlaceholder(
			country?.format || '+... ... ... ...',
			phone,
		);
		setPlaceholder(newPlaceholder);
	}, [country, phone, setPhone, value]);

	const handleChange = (phone: string, countryDate: CountryData) => {
		const customMask = masks[countryDate.countryCode as keyof typeof masks];

		if (customMask) {
			setFieldValue('country', {
				...countryDate,
				format: customMask,
			});
			onUpdateCountry({
				...countryDate,
				format: customMask,
			});
		} else {
			setFieldValue('country', countryDate);
			onUpdateCountry(countryDate);
		}

		setFieldValue(field.name, phone);
		setPhone(phone);
	};

	// Функция для обрезки номера по формату страны
	const trimPhoneToFitFormat = (phone: string, format: string) => {
		const numericPhone = phone.replace(/\D/g, ''); // Оставляем только цифры
		const maxLength = format.replace(/[^.]/g, '').length; // Ожидаемая длина номера
		return numericPhone.substring(0, maxLength); // Обрезаем номер по длине формата
	};

	// Очищаем лишние символы из номера телефона при смене страны или обновлении формы
	useEffect(() => {
		if (country?.format && phone) {
			const trimmedPhone = trimPhoneToFitFormat(phone, country.format);
			setPhone(trimmedPhone); // Обновляем состояние телефона
			setFieldValue(field.name, trimmedPhone); // Устанавливаем значение поля
		}
	}, [country, phone, setFieldValue, field.name]);

	const generateInputLength = useMemo(() => {
		return country?.format?.length;
	}, [country]);

	return (
		<div className="phone-input">
			{label && (
				<label className="phone-input__label" htmlFor={id || id_field}>
					{label}
				</label>
			)}
			<div className="phone-input__wrapper-placeholder">
				<PhoneInput
					specialLabel={label}
					country={countryCode}
					value={value}
					onChange={handleChange}
					onBlur={onBlur}
					onFocus={onFocus}
					placeholder={placeholder}
					disabled={disabled}
					enableLongNumbers={true}
					localization={i18n.language === 'ru' ? localization : undefined}
					containerClass="phone-input__input-container"
					inputClass={`phone-input__input ${error ? 'error' : ''}`}
					buttonClass="phone-input__input-flag"
					onMount={handleOnMount}
					inputProps={{
						id: id || id_field,
						name: 'phone',
						required: true,
						maxLength: generateInputLength,
						autoFocus: autofocus,
					}}
				/>
				{placeholder && (
					<span className="phone-input__placeholder">{placeholder}</span>
				)}
			</div>
			{error && (
				<div className={`phone-input__error ${error ? 'error' : ''}`}>
					{error}
				</div>
			)}
		</div>
	);
};
