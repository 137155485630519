import { useId } from 'react';
import { IInputDefaultProps } from './input.model';
import './input.scss';

export const InputField = ({
	value: valueProps,
	label,
	type = 'text',
	id,
	placeholder = '',
	onChange,
	onSubmit,
	classes = {},
	error: errorProps,
	validationOnChange,
	min,
	max,
	endAdornment,
	startAdornment,
	numberOff = false,
	theme = 'default',
	...props
}: IInputDefaultProps) => {
	const isError = errorProps !== undefined ? true : false;
	const id_field = useId();
	return (
		<div className={`input-form-control ${classes?.root || ''}`}>
			{label && (
				<label className="input-form-control__label" htmlFor={id || id_field}>
					{label}
				</label>
			)}
			<div
				className={`input-form-control__wrapper-input ${
					isError ? 'error' : ''
				} ${classes?.control || ''}`}
			>
				{startAdornment && (
					<div
						className={`input-form-control-adornment input-form-control-adornment__start ${
							classes?.startAdornment || ''
						} ${valueProps !== '' ? ' active' : ''}`}
					>
						{startAdornment}
					</div>
				)}

				{type === 'textarea' ? (
					<textarea
						className={'input-form-control__input ' + classes?.input || ''}
						onChange={onChange}
						value={valueProps}
						placeholder={placeholder}
						id={id || id_field}
						{...props}
					></textarea>
				) : (
					<input
						className={`input-form-control__input ${theme} ${
							classes?.input || ''
						}`}
						onChange={onChange}
						value={valueProps}
						placeholder={placeholder}
						type={type}
						max={max}
						min={min}
						id={id || id_field}
						{...props}
					/>
				)}

				{endAdornment && (
					<div
						className={`input-form-control-adornment input-form-control-adornment__end ${
							classes?.endAdornment || ''
						}`}
					>
						{endAdornment}
					</div>
				)}
			</div>
			{
				<div className={`input-form-control__error ${isError ? 'error' : ''}`}>
					{errorProps || ''}
				</div>
			}
		</div>
	);
};
