import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	IMasterData,
	IScheduleItemData,
	ScheduleListData,
} from '../../../@types';
import { Button } from 'src/components/ui/buttons/button/button.component';
import {
	ScheduleGridList,
	ScheduleGridListLoader,
} from './schedule-grid-list.component';
import { useScheduleFilters } from '../schedule-filters/schedule-filters.context';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { createWeek } from '../../../utils/date.utils';
import cl from './schedule-grid.module.scss';

export const ScheduleGrid = ({
	items = [],
	filters,
	loading,
	date,
	onClickEvent,
	onClickSpeaker,
}: {
	items?: ScheduleListData;
	filters?: JSX.Element;
	loading: boolean;
	date?: [Date | null, Date | null];
	onClickEvent: (event: IScheduleItemData | null) => void;
	onClickSpeaker: (user: IMasterData | null) => void;
}) => {
	const { t } = useTranslation();
	const { i18n } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const { isMobile } = useWindowSize(1280);
	const { resetFilter } = useScheduleFilters();

	const date_items = useMemo(() => {
		if (items.length > 0) {
			const _items = createWeek<IScheduleItemData>(
				i18n.language,
				date,
				isMobile,
			);
			items.forEach((item) => {
				const date = item.date.split(' ');
				const key = date[0];
				if (!_items) return Object.values([]);
				if (key in _items) {
					_items[key].items.push(item);
				}
			});
			return Object.values(_items || []);
		}

		return [];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items, filters, i18n.language, date]);

	const handleClickEvent = (e: React.MouseEvent<HTMLDivElement>) => {
		const id = e.currentTarget.dataset.id;
		const date = e.currentTarget.dataset.date;
		if (id && date) {
			const events = date_items.find((item) => item.date === date);
			const event =
				events?.items.find((item) => item.id.toString() === id) || null;
			onClickEvent(event);
		}
	};

	function useQuery() {
		return new URLSearchParams(useLocation().search);
	}

	const query = useQuery();

	// Убираем локальное состояние для eventData и masters
	useEffect(() => {
		const eventId = query.get('eventId');
		const date = query.get('date');

		if (eventId && date) {
			const events = date_items.find((item) => item.date === date);
			const event =
				events?.items.find((item) => item.id.toString() === eventId) || null;

			if (event) {
				onClickEvent(event);
			}
		}
	}, [location.search, date_items, onClickEvent, query]);

	const handleClose = () => {
		navigate(`${location.pathname}`, { replace: true });
		onClickEvent(null); // Сбрасываем событие через пропс
	};

	const handleClickSpeaker = (e: React.MouseEvent, data?: string | number) => {
		e.preventDefault();
		e.stopPropagation();
		const el = e.currentTarget as HTMLElement;
		const id = el.dataset.id;
		const eventId = el.dataset.event;
		const date = el.dataset.date;
		const isModal = el.dataset.modal;
		if (id && date && eventId) {
			const events = date_items.find((item) => item.date === date);
			const event =
				events?.items.find((item) => item.id.toString() === eventId) || null;
			const user =
				event?.masters.find((item) => item.id.toString() === id) || null;
			if (isModal) {
				onClickEvent(null);
			}
			// Изменяем через onClickSpeaker вместо setCurrentMastersData
			onClickSpeaker(user || null);
		}
	};

	const itemsList = useMemo(() => {
		return date_items;
	}, [date_items]);

	return (
		<div className={cl.section}>
			<div className={cl.header}>
				{filters && <div className={cl.filters}>{filters}</div>}
			</div>

			{loading && <ScheduleGridListLoader />}

			{!loading && itemsList.length === 0 && (
				<div className={cl.empty}>
					<h3 className={cl.empty__title}>{t('schedule.empty.title')}</h3>
					<p className={cl.empty__desc}>{t('schedule.empty.desc')}</p>
					<Button theme="outline" className={cl.reset} onClick={resetFilter}>
						{t('schedule.button.reset')}
					</Button>
				</div>
			)}

			{!loading && itemsList.length > 0 && (
				// {itemsList.length === 7 ? (
				<>
					<ScheduleGridList
						date={date}
						list={itemsList}
						onClickEvent={handleClickEvent}
						onClickSpeaker={handleClickSpeaker}
					/>
					{/*): (*/}
					{/*	<ScheduleMonthList*/}
					{/*		list={itemsList}*/}
					{/*		onClickEvent={onClickEvent}*/}
					{/*		onClickSpeaker={onClickSpeaker}*/}
					{/*	/>*/}
					{/*)}*/}
				</>
			)}
		</div>
	);
};
