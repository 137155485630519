import { LegacyRef, forwardRef, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ArrowRightIcon } from '../../ui/icons';
import bg from './assets/bg.jpg';
import cl from './section-mission.module.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import { MISSION_MOCK } from './section-mission.mock';

const BREAKPOINTS = {
	0: {
		slidesPerView: 1.2,
	},
	540: {
		slidesPerView: 1.5,
	},
	680: {
		slidesPerView: 1.8,
	},
	768: {
		slidesPerView: 1.5,
	},
	996: {
		slidesPerView: 2.1,
	},

	1530: {
		slidesPerView: 2.5,
	},
	2000: {
		slidesPerView: 3.5,
	},
};

export const SectionMission = forwardRef(
	(
		{ dots = false }: { dots?: boolean },
		ref: LegacyRef<HTMLElement> | undefined,
	) => {
		const { t, i18n } = useTranslation();

		const { ref: refObs, inView: inViewObs } = useInView({
			threshold: 0.5,
		});

		const refText = useRef<HTMLDivElement | null>(null);
		const refSlider = useRef<HTMLDivElement | null>(null);

		useEffect(() => {
			if (refText.current && refSlider.current && inViewObs) {
				refText.current.classList.add(cl.viewText);
				refSlider.current.classList.add(cl.viewSlider);
			}
		}, [inViewObs]);

		return (
			<section className={cl.section} ref={ref}>
				<div className={cl.container} ref={refObs}>
					<div className={cl.row}>
						<div className={`${cl.colLeft}`} ref={refText}>
							<p className="desc-section">{t('mission.desc')}</p>
						</div>
						<div className={`${cl.colRight}`} ref={refSlider}>
							<div
								className={
									cl.navigation__btn +
									' ' +
									cl.navigation__btn__prev +
									' swiper-misson-button-prev'
								}
								role="button"
							>
								<ArrowRightIcon className={cl.navigation__btn__icon} />
							</div>
							<div
								className={
									cl.navigation__btn +
									' ' +
									cl.navigation__btn__next +
									' swiper-misson-button-next'
								}
								role="button"
							>
								<ArrowRightIcon className={cl.navigation__btn__icon} />
							</div>
							<Swiper
								breakpoints={BREAKPOINTS}
								slidesPerView={3.5}
								spaceBetween={15}
								pagination={
									!dots
										? false
										: {
												clickable: true,
											}
								}
								modules={[Pagination, Navigation]}
								className="swiper-misson"
								navigation={{
									nextEl: '.swiper-misson-button-next',
									prevEl: '.swiper-misson-button-prev',
									disabledClass: 'swiper-button-disabled',
								}}
							>
								{MISSION_MOCK.map((item, index) => (
									<SwiperSlide key={index}>
										<div
											className={cl.swiper__misson__slide}
											style={{ backgroundImage: `url(${bg})` }}
										>
											<picture className={cl.swiper__misson__slide__img}>
												{item.preview.map((img) => (
													<source
														type={'image/' + img.type}
														srcSet={img.uri}
														key={img.type}
													/>
												))}
												<img
													src={item.preview[1].uri}
													className={cl.swiper__misson__slide__img}
													alt=""
													loading="lazy"
												/>
											</picture>

											<div className={cl.swiper__misson__slide__footer}>
												<h3 className={cl.swiper__misson__slide__title}>
													{item.title[i18n.language]}
												</h3>
												<p className={cl.swiper__misson__slide__subtitle}>
													{item.subtitle[i18n.language]}
												</p>
											</div>
										</div>
									</SwiperSlide>
								))}
							</Swiper>
						</div>
					</div>
				</div>
			</section>
		);
	},
);
