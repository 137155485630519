import { Preview } from 'src/@types';
import cl from './card-post.module.scss';

export const CardPost = ({
	title,
	preview,
	onClick,
}: {
	preview: Preview;
	title: string;
	onClick?: () => void;
}) => {
	return (
		<article className={cl.card__post} onClick={onClick}>
			<picture className={cl.card__post__image}>
				{preview.map((img) => (
					<source type={'image/' + img.type} srcSet={img.uri} key={img.type} />
				))}
				<img
					src={preview[preview.length - 1].uri}
					className={cl.card__post__image}
					alt=""
					loading="lazy"
					decoding="async"
				/>
			</picture>
			<p className={cl.card__post__info}>{title}</p>
		</article>
	);
};
