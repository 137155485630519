import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RoutsPath } from 'src/@types';
import { BreakpointsSwiper } from 'src/@types/global';
import { PageLayout } from '../../components/layout/page.layout';
import { Banner } from 'src/components/banner/banner.component';
import { CardElevate } from 'src/components/card/card-elevate/card-elevate.component';
import { SectionStudio } from 'src/components/sections/section-studio/section-studio.component';
import { SliderCardInfo } from 'src/components/slider-card-info/slider-card-info.component';
import { SliderReview } from 'src/components/slider-review/slider-review.component';
import { Button } from 'src/components/ui/buttons/button/button.component';
import { TextBlock } from 'src/components/ui/text-block/text-block.components';
import { UserContacts } from 'src/components/ui/user-contacts/user-contacts.component';
import { createLangHref } from 'src/utils/href.utils';
import cl from './index.module.scss';
import {
	IMAGES_COMPANY_RETREATS,
	useCompanyRetreatsPost,
	useCompanyRetreatsReviews,
} from './mock';

const BREAKPOINTS_POSTS: BreakpointsSwiper = {
	0: {
		slidesPerView: 1.2,
		spaceBetween: 15,
	},
	540: {
		slidesPerView: 1.5,
	},
	680: {
		slidesPerView: 1.8,
	},
	768: {
		slidesPerView: 2.5,
	},
	996: {
		slidesPerView: 3,
		spaceBetween: 32,
	},
};

const CompanyRetreatsPage = () => {
	const { t, i18n } = useTranslation();
	const posts = useCompanyRetreatsPost();
	const reviews = useCompanyRetreatsReviews();
	const navigation = useNavigate();
	const scrollToContact = () =>
		navigation(
			createLangHref(
				i18n.language,
				RoutsPath.company_retreats + RoutsPath.contacts,
			),
		);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, []);

	return (
		<PageLayout positionHeader="fixed">
			<div className={cl.page}>
				<Banner
					title={t('retreats.title')}
					preview={[
						{
							type: 'webp',
							uri: '/image/company-retreats/banner.webp',
						},
						{
							type: 'jpg',
							uri: '/image/company-retreats/banner.jpg',
						},
					]}
					actions={
						<Button className={cl.banner__btn} onClick={scrollToContact}>
							{t('btn.contact')}
						</Button>
					}
				/>
				<section className={cl.section__variants}>
					<div className="container position_relative">
						<TextBlock
							title={t('retreats.text.title')}
							description={t('retreats.text.description')}
							classes={{
								title: cl['text-info-title'],
								description: cl['text-info-desc'],
								root: cl['text-info-root'],
							}}
						/>
						<div className={cl.actions}>
							<Button className={cl.banner__btn}>{t('btn.contact')}</Button>
						</div>

						<TextBlock
							title={t('retreats.variants.title')}
							description={t('retreats.variants.description')}
							classes={{
								root: cl['variant-text-info-root'],
								title: cl['text-info-title'],
								description: cl['variant-text-info-desc'],
							}}
						/>
					</div>
					<div className={cl.variants}>
						<SliderCardInfo
							items={posts}
							breakpoints={BREAKPOINTS_POSTS}
							classes={{
								slide: 'slide-variants',
							}}
							arrowOff
						/>
					</div>
				</section>

				<section className={cl.section__grid}>
					<picture className={cl.section__grid__bg}>
						<source type="image/png" srcSet="/image/bg-right-2x.png" />
						<img
							src={'/image/bg-right-2x.png'}
							className={cl.section__grid__bg}
							alt=""
							loading="lazy"
							decoding="async"
						/>
					</picture>

					<div className={'container position_relative ' + cl.zIndex}>
						<h3 className={cl.section_title + ' ' + cl.section_title_position}>
							{t('retreats.section.title')}
						</h3>
						<div className={cl.row__group__start}>
							<div className={cl.row + ' ' + cl.end}>
								<CardElevate
									title={t('retreats.elevate.one.title')}
									image="g1"
								/>
							</div>
							<div className={cl.row + ' ' + cl.center + ' ' + cl.topY}>
								<CardElevate
									title={t('retreats.elevate.two.title')}
									image="g2"
								/>
							</div>
						</div>

						<div className={cl.row + ' ' + cl.row__group__center}>
							<CardElevate
								title={t('retreats.elevate.three.title')}
								image="g3"
							/>
						</div>
						<div className={cl.row__group__end}>
							<div className={cl.row + ' ' + cl.center}>
								<CardElevate
									title={t('retreats.elevate.four.title')}
									image="g4"
								/>
							</div>
							<div className={cl.row + ' ' + cl.end + ' ' + cl.topY}>
								<CardElevate
									title={t('retreats.elevate.five.title')}
									image="g5"
								/>
							</div>
						</div>
					</div>
				</section>

				<SectionStudio
					items={IMAGES_COMPANY_RETREATS}
					title={t('retreats.slider')}
					className={cl.images}
				/>
				<TextBlock
					title={t('retreats.reviews.title')}
					description={t('retreats.reviews.description')}
					classes={{
						root: cl['text-reviews-root'],
						title: cl['text-info-title'],
						description: cl['text-info-desc'],
					}}
				/>

				<div
					className={cl.contacts}
					style={{ background: `url(/image/star-big.png)` }}
				>
					<SliderReview items={reviews} classes={{ root: cl.reviews }} />
					<div className="container">
						<UserContacts
							title={t('retreats.contact')}
							user={{
								preview: [
									{
										type: 'webp',
										uri: '/image/company-retreats/user.webp',
									},
									{
										type: 'jpg',
										uri: '/image/company-retreats/user.jpg',
									},
								],
								name: t('call.user.name'),
								description: t('call.user'),
							}}
							contact={{
								phone: '+971 4 657 565 646',
								email: 'hello@alaya.com',
								emailUser: 'kris.ebauer@alayasound.com',
							}}
						/>
					</div>
				</div>
			</div>
		</PageLayout>
	);
};

export default CompanyRetreatsPage;
