import axios, { AxiosError, GenericAbortSignal } from 'axios';
import i18next from 'i18next';
import {
	AudioData,
	BannersData,
	GenreListData,
	ICartItem,
	ICartResponse,
	IConvertTokenParams,
	IConvertTokenResponse,
	IGenreData,
	IOptionsData,
	IOrderProfileData,
	IOrderRequest,
	IOrderResponse,
	IProduct,
	IProductDetail,
	IRecordsData,
	IRequestsChangeAuthPassword,
	IRequestsLogin,
	IRequestsReset,
	IRequestsSingUp,
	IResponseLogin,
	IResponseObject,
	IResponseReset,
	IResponseSingUp,
	IResponseSingUpCode,
	IScheduleFilters,
	IScheduleItemData,
	IScheduleParticipate,
	IUserData,
	IUserEmailUpdateData,
	IUserUpdateData,
	MasterListData,
	Participate,
	PlaceListData,
	ScheduleFiltersKeys,
	ScheduleListData,
} from '../@types';
import { CART_SESSION_TOKEN } from 'src/components/cart/cart.context';
import { TOKEN_ACCESS, TOKEN_REFRESH } from '../const/auth.const';
import { DtoCartData } from './dto';

export interface IRequestsError {
	msg: string;
	status: number;
	data: unknown;
}

export const API_URL = process.env.REACT_APP_API_URL;

const api = axios.create({
	withCredentials: false,
	baseURL: API_URL,
});

const apiPrivate = axios.create({
	withCredentials: false,
	baseURL: API_URL,
});

api.interceptors.request.use(
	(config) => {
		config.baseURL = `${API_URL}/${i18next.language}/api/`;
		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);

apiPrivate.interceptors.request.use(
	(config) => {
		const accessToken = localStorage.getItem('accessToken');
		if (accessToken) {
			config.headers.Authorization = `Bearer ${accessToken}`;
		}
		config.baseURL = `${API_URL}/${i18next.language}/api/`;

		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);

apiPrivate.interceptors.response.use(
	(response) => {
		return response;
	},
	async (error) => {
		const originalRequest = error.config;

		if (error.response.status === 401 && !originalRequest._retry) {
			originalRequest._retry = true;
			try {
				const newAccessToken = await apiServices.refreshAccessToken();
				originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
				return api(originalRequest);
			} catch (refreshError) {
				return Promise.reject(refreshError);
			}
		}
		return Promise.reject(error);
	},
);

class Api {
	constructor() {
		this.sendMessage = this.sendMessage.bind(this);
	}

	private getError(error: unknown): IRequestsError {
		const e = error as AxiosError;
		const status = e.response?.status || 404;
		let msg = 'Error 404. Not found page';
		if (status === 500) {
			msg = 'Server error. Status 500.';
		} else {
			if (e.response?.data && typeof e.response?.data === 'object') {
				msg = '';
				Object.values(e.response.data).forEach((item) => {
					if (Array.isArray(item)) {
						msg += item.join(' ');
						msg.replaceAll('[', '').replaceAll(']', '');
					} else if (typeof item === 'string') {
						msg += ' ' + item;
					} else {
						msg = 'Something went wrong.';
					}
				});
			} else {
				msg = JSON.stringify(e.response?.data) || e?.message || 'Error unset';
			}
		}
		return { msg, status, data: e.response?.data };
	}

	checkCaptcha = async (token: string) => {
		try {
			const result = await await axios.post<boolean>('recaptcha/', {
				token,
			});

			if (
				result.status === 403 ||
				result.status === 405 ||
				result.status === 500
			) {
				throw new Error('Incorrect captcha input');
			}

			return result.data;
		} catch (error) {
			const e = error as AxiosError;
			console.log(e.response?.data || e?.message || 'Incorrect captcha input');
			return false;
		}
	};

	async refreshAccessToken(): Promise<string> {
		// eslint-disable-next-line
		try {
			const refreshToken = localStorage.getItem('refreshToken');
			const response = await api.post('account/token/refresh/', {
				refresh: refreshToken,
			});
			const { access, refresh } = response.data;
			localStorage.setItem(TOKEN_ACCESS, access);
			localStorage.setItem(TOKEN_REFRESH, refresh);
			return access;
		} catch (error) {
			throw error;
		}
	}

	async sendMessage<T>(url: string, data: T): Promise<string> {
		try {
			await api.post<T>(url, data);
			return 'success';
		} catch (error) {
			return Promise.reject(this.getError(error));
		}
	}

	async getAudioCatalog(): Promise<AudioData> {
		try {
			return (await api.get<IResponseObject<AudioData>>('content/audio/list/'))
				.data.results;
		} catch (error) {
			return Promise.reject(this.getError(error));
		}
	}

	async getOptions(): Promise<IOptionsData> {
		try {
			return (await api.get<IOptionsData>('options/')).data;
		} catch (error) {
			return Promise.reject(this.getError(error));
		}
	}

	async signUp(data: IRequestsSingUp): Promise<IResponseSingUp> {
		try {
			const response = (
				await api.post<IResponseSingUp>('account/sign_up/', data)
			).data;
			return response;
		} catch (error) {
			return Promise.reject(this.getError(error));
		}
	}

	async signUpCode(data: IResponseSingUpCode): Promise<IResponseSingUpCode> {
		try {
			const response = (
				await api.post<IResponseSingUpCode>('account/sign_up/token/', data)
			).data;
			return response;
		} catch (error) {
			return Promise.reject(this.getError(error));
		}
	}

	async login(data: IRequestsLogin): Promise<IResponseLogin> {
		try {
			return (await api.post<IResponseLogin>('account/token/', data)).data;
		} catch (error) {
			return Promise.reject(this.getError(error));
		}
	}

	async sendTokenResetPassword(email: string): Promise<{ user: string }> {
		try {
			return (
				await api.post<{ user: string }>('account/password_reset/token/', {
					user: email,
				})
			).data;
		} catch (error) {
			return Promise.reject(this.getError(error));
		}
	}

	async resendVerificationCode(email: string): Promise<{ user: string }> {
		try {
			return (
				await api.post<{ user: string }>('account/sign_up/resend_token/', {
					user: email,
				})
			).data;
		} catch (error) {
			return Promise.reject(this.getError(error));
		}
	}

	async verifyTokenResetPassword({
		email,
		token,
	}: {
		email: string;
		token: string;
	}): Promise<{ user: string; token: string }> {
		try {
			return (
				await api.post<{ user: string; token: string }>(
					'account/password_reset/token/verify/',
					{ user: email, token },
				)
			).data;
		} catch (error) {
			return Promise.reject(this.getError(error));
		}
	}

	async resetPassword(data: IRequestsReset): Promise<IResponseReset> {
		try {
			return (
				await api.post<IResponseReset>('account/password_reset/done/', data)
			).data;
		} catch (error) {
			return Promise.reject(this.getError(error));
		}
	}

	async changeAuthPassword(
		data: IRequestsChangeAuthPassword,
		signal: GenericAbortSignal | undefined,
	): Promise<any> {
		try {
			return (
				await apiPrivate.post<any>('account/password_change/', data, { signal })
			).data;
		} catch (error) {
			return Promise.reject(this.getError(error));
		}
	}

	async getUserInfo(): Promise<IUserData> {
		try {
			const a = (await apiPrivate.get<IUserData>('account/profile/')).data;
			return a;
		} catch (error) {
			return Promise.reject(this.getError(error));
		}
	}

	async updateUserInfo(data: IUserUpdateData): Promise<IUserUpdateData> {
		try {
			return (await apiPrivate.patch<IUserUpdateData>('account/update/', data))
				.data;
		} catch (error) {
			return Promise.reject(this.getError(error));
		}
	}

	async updateUserEmailInfo(
		data: IUserEmailUpdateData,
	): Promise<IUserEmailUpdateData> {
		try {
			return (
				await apiPrivate.patch<IUserEmailUpdateData>(
					'account/update/token/',
					data,
				)
			).data;
		} catch (error) {
			return Promise.reject(this.getError(error));
		}
	}

	async getBanners(): Promise<BannersData> {
		try {
			return (
				await api.get<IResponseObject<BannersData>>('content/banners/list/')
			).data.results;
		} catch (error) {
			return Promise.reject(this.getError(error));
		}
	}

	async getSchedule(filters?: IScheduleFilters): Promise<ScheduleListData> {
		let _filters = '';
		if (filters) {
			const q_array: string[] = [];
			Object.keys(filters).forEach((key) => {
				if (filters[key as ScheduleFiltersKeys]) {
					q_array.push(`${key}=${filters[key as ScheduleFiltersKeys]}`);
				}
			});

			if (q_array.length > 0) {
				_filters = '?' + q_array.join('&');
			}
		}

		try {
			return (
				await apiPrivate.get<ScheduleListData>(
					`content/schedule/list/${_filters}`,
				)
			).data;
		} catch (error) {
			return Promise.reject(this.getError(error));
		}
	}

	async updateScheduleParticipate(
		id: string,
		type: Participate,
	): Promise<IScheduleParticipate> {
		try {
			return (
				await apiPrivate.post<IScheduleParticipate>(
					`/content/schedule/${id}/${type}/`,
				)
			).data;
		} catch (error) {
			return Promise.reject(this.getError(error));
		}
	}

	async getMasters({
		limit = 20,
		offset = 0,
	}: {
		limit: number;
		offset: number;
	}): Promise<MasterListData> {
		try {
			return (
				await api.get<IResponseObject<MasterListData>>(
					`content/masters/list/?limit=${limit}&offset=${offset}`,
				)
			).data.results;
		} catch (error) {
			return Promise.reject(this.getError(error));
		}
	}

	async getGenres({
		limit = 20,
		offset = 0,
	}: {
		limit: number;
		offset: number;
	}): Promise<GenreListData> {
		try {
			return (
				await api.get<IResponseObject<GenreListData>>(
					`content/genres/list/?limit=${limit}&offset=${offset}`,
				)
			).data.results;
		} catch (error) {
			return Promise.reject(this.getError(error));
		}
	}

	async getPlaces({
		limit = 20,
		offset = 0,
	}: {
		limit: number;
		offset: number;
	}): Promise<PlaceListData> {
		try {
			return (
				await api.get<IResponseObject<PlaceListData>>(
					`content/schedule/location/list/?limit=${limit}&offset=${offset}`,
				)
			).data.results;
		} catch (error) {
			return Promise.reject(this.getError(error));
		}
	}

	async getShopList({
		limit,
		offset,
	}: {
		limit: number;
		offset: number;
	}): Promise<IResponseObject<IProduct[]>> {
		try {
			return (
				await api.get<IResponseObject<IProduct[]>>(
					`shop/product/?limit=${limit}&offset=${offset}`,
				)
			).data;
		} catch (error) {
			return Promise.reject(this.getError(error));
		}
	}

	async getShopProduct(id: string): Promise<IProductDetail> {
		try {
			return (await api.get<IProductDetail>(`shop/product/${id}/`)).data;
		} catch (error) {
			return Promise.reject(this.getError(error));
		}
	}

	async getCart(isAuth: boolean): Promise<ICartResponse> {
		try {
			if (isAuth) {
				return (await apiPrivate.get<ICartResponse>(`shop/cart/user_cart/`))
					.data;
			}
			const token = window.localStorage.getItem(CART_SESSION_TOKEN);
			const response = (
				await api.get<ICartResponse>(`shop/cart/user_cart/`, {
					headers: token
						? {
								'x-cart': window.localStorage.getItem(CART_SESSION_TOKEN) || '',
							}
						: {},
				})
			).data;
			return response;
		} catch (error) {
			return Promise.reject(this.getError(error));
		}
	}

	async updateCart(
		items: ICartItem[],
		isAuth: boolean = false,
	): Promise<ICartResponse> {
		try {
			if (isAuth) {
				return (
					await apiPrivate.post<ICartResponse>(
						`shop/cart/update_cart/`,
						items.map((item) => new DtoCartData(item).toJSON()),
					)
				).data;
			}
			const token = window.localStorage.getItem(CART_SESSION_TOKEN);
			return (
				await api.post<ICartResponse>(
					`shop/cart/update_cart/`,
					items.map((item) => new DtoCartData(item).toJSON()),
					{
						headers: token
							? {
									'x-cart': token,
								}
							: {},
					},
				)
			).data;
		} catch (error) {
			return Promise.reject(this.getError(error));
		}
	}

	async createOrder(
		data: IOrderRequest,
		isAuth: boolean,
	): Promise<IOrderResponse> {
		try {
			if (isAuth) {
				return (await apiPrivate.post<IOrderResponse>(`shop/cart/order/`, data))
					.data;
			}
			const token = window.localStorage.getItem(CART_SESSION_TOKEN);
			return (
				await api.post<IOrderResponse>(`shop/cart/order/`, data, {
					headers: token
						? {
								'x-cart': token,
							}
						: {},
				})
			).data;
		} catch (error) {
			return Promise.reject(this.getError(error));
		}
	}

	async cancelOrders(slug: string | number): Promise<IOrderProfileData> {
		try {
			return (
				await apiPrivate.post<IOrderProfileData>(`shop/orders/${slug}/cancel/`)
			).data;
		} catch (error) {
			return Promise.reject(this.getError(error));
		}
	}

	async getOrders({
		limit = 10,
		offset = 0,
	}: {
		limit?: number;
		offset?: number;
	}): Promise<IResponseObject<IOrderProfileData[]>> {
		try {
			return (
				await apiPrivate.get<IResponseObject<IOrderProfileData[]>>(
					`shop/orders/?limit=${limit}&offset=${offset}`,
				)
			).data;
		} catch (error) {
			return Promise.reject(this.getError(error));
		}
	}

	async getRecords({
		limit = 10,
		offset = 0,
		isActive = true,
	}: {
		limit?: number;
		offset?: number;
		isActive?: boolean;
	}): Promise<IResponseObject<IRecordsData[]>> {
		try {
			const isActiveParam = isActive ? 'True' : 'False';
			return (
				await apiPrivate.get<IResponseObject<IRecordsData[]>>(
					`account/user/events/?limit=${limit}&offset=${offset}&is_active=${isActiveParam}`,
				)
			).data;
		} catch (error) {
			return Promise.reject(this.getError(error));
		}
	}

	async getRecord(id: string): Promise<IScheduleItemData> {
		try {
			return (await apiPrivate.get<IScheduleItemData>(`account/user/events/${id}`)).data;
		} catch (error) {
			return Promise.reject(this.getError(error));
		}
	}

	async getGenreMasters(slug: string): Promise<IGenreData> {
		try {
			return (await apiPrivate.get<IGenreData>(`content/genres/genre/${slug}/`)).data;
		} catch (error) {
			return Promise.reject(this.getError(error));
		}
	}

	async convertToken(
		params: IConvertTokenParams,
	): Promise<IConvertTokenResponse> {
		try {
			const extendsParams = {
				...params,
				client_id: 'EVearp1HAwDNw2kr6Km3FZX9wFzlLkrYBq3cO1gV',
				grant_type: 'convert_token',
				client_secret:
					'D6xZqsN2HLbT1ZAWDNWgi6xwsMuAkjMWBvH2RzWaaeTKdyoHhxabN1aXPgvJWen4rokJ83xjpjbVBruWjO6QhuYO9Vu0zYrVnYuE0tNgTORdIZr6f6o8uFrNEJjiGK7N',
				backend: 'google-oauth2',
			};
			const response = await api.post(`auth/convert-token/`, extendsParams);
			const { access_token, refresh_token } = response.data;
			localStorage.setItem(TOKEN_ACCESS, access_token);
			localStorage.setItem(TOKEN_REFRESH, refresh_token);
			return response.data;
		} catch (error) {
			return Promise.reject(this.getError(error));
		}
	}
}

export const apiServices = new Api();
