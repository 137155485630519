import { ISvgIconProps } from '.';

export const CloseIcon = ({
	className = '',
	viewBox = '0 0 30 30',
	refComponent = undefined,
}: ISvgIconProps) => {
	return (
		<svg
			className={className}
			ref={refComponent}
			viewBox={viewBox}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7.92969 22L22.0718 7.85786"
				stroke="currentColor"
				strokeWidth="4"
				strokeLinecap="square"
			/>
			<path
				d="M7.92969 8L22.0718 22.1421"
				stroke="currentColor"
				strokeWidth="4"
				strokeLinecap="square"
			/>
		</svg>
	);
};
