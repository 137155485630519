import { RoutsPath } from '../../../@types';
import { IBreadcrumbsItem } from './breadcrumbs.model';

export const BREADCRUMS_DEFAULT: IBreadcrumbsItem[] = [
	{
		name: 'home.page',
		href: RoutsPath.home,
		translate: true,
	},
];
