import { ISvgIconProps } from '.';

export const LogoInToIcon = ({
	className = '',
	viewBox = '0 0 140 57',
	refComponent = undefined,
}: ISvgIconProps) => {
	return (
		<svg
			className={className}
			ref={refComponent}
			viewBox={viewBox}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4.08905 7.76023C2.96852 7.76023 2.00933 7.38012 1.2056 6.61988C0.401865 5.86257 0 4.94737 0 3.88012C0 2.81287 0.401865 1.90058 1.2056 1.14035C2.00639 0.380117 2.96852 0 4.08905 0C5.20958 0 6.16878 0.380117 6.97251 1.14035C7.77624 1.90058 8.1781 2.81287 8.1781 3.88012C8.1781 4.94737 7.77624 5.85965 6.97251 6.61988C6.16878 7.38012 5.20958 7.76023 4.08905 7.76023ZM1.16746 51.2222V13.9708H6.91091V51.2251H1.16746V51.2222Z"
				fill="currentColor"
			/>
			<path
				d="M23.9212 28.8128V51.2222H18.1777V13.9707H23.7246V19.7924H24.2116C25.0886 17.9006 26.4174 16.3772 28.2009 15.2193C29.9844 14.0643 32.29 13.4854 35.1118 13.4854C37.6433 13.4854 39.8579 14 41.7529 15.0263C43.6507 16.0526 45.1262 17.6052 46.1822 19.6842C47.2352 21.7631 47.7632 24.3859 47.7632 27.5555V51.228H42.0198V27.9444C42.0198 25.0175 41.2571 22.7339 39.7318 21.0935C38.2065 19.4532 36.115 18.6316 33.4545 18.6316C31.6212 18.6316 29.9873 19.0292 28.5529 19.8187C27.1185 20.6111 25.9862 21.7661 25.159 23.2865C24.3318 24.807 23.9182 26.6491 23.9182 28.8158L23.9212 28.8128Z"
				fill="currentColor"
			/>
			<path
				d="M99.4633 13.9708V18.8217H80.0469V13.9708H99.4633ZM85.7074 5.04395H91.465V40.5498C91.465 42.1668 91.7062 43.3744 92.1855 44.1755C92.6648 44.9767 93.2823 45.5089 94.038 45.7749C94.7937 46.041 95.5936 46.1755 96.4404 46.1755C97.0756 46.1755 97.5931 46.1404 98.0019 46.0673C98.4077 45.9942 98.7341 45.9328 98.9781 45.8861L100.148 51.0264C99.7574 51.1726 99.2134 51.3217 98.5135 51.4738C97.8137 51.6258 96.9286 51.7048 95.8553 51.7048C94.2291 51.7048 92.6383 51.3568 91.0857 50.6609C89.5331 49.965 88.2481 48.9065 87.2306 47.4825C86.2132 46.0586 85.7074 44.2662 85.7074 42.0995V5.04395Z"
				fill="currentColor"
			/>
			<path
				d="M122.975 52C119.593 52 116.629 51.1988 114.083 49.5994C111.539 48 109.554 45.7602 108.131 42.8801C106.708 40.0029 105.996 36.6403 105.996 32.7894C105.996 28.9386 106.708 25.5204 108.131 22.6286C109.554 19.7339 111.536 17.4883 114.083 15.8859C116.626 14.2836 119.59 13.4854 122.975 13.4854C126.359 13.4854 129.321 14.2865 131.864 15.8859C134.408 17.4854 136.393 19.7339 137.816 22.6286C139.239 25.5234 139.951 28.9093 139.951 32.7894C139.951 36.6696 139.239 40 137.816 42.8801C136.393 45.7573 134.408 47.9971 131.864 49.5994C129.321 51.2017 126.356 52 122.975 52ZM122.975 46.8567C125.545 46.8567 127.659 46.2017 129.318 44.8918C130.976 43.5818 132.205 41.8596 133.002 39.7251C133.799 37.5906 134.196 35.2778 134.196 32.7894C134.196 30.3011 133.799 27.9795 133.002 25.8275C132.205 23.6783 130.979 21.9386 129.318 20.614C127.659 19.2894 125.545 18.6257 122.975 18.6257C120.405 18.6257 118.291 19.2894 116.632 20.614C114.974 21.9386 113.744 23.6783 112.948 25.8275C112.151 27.9795 111.754 30.2982 111.754 32.7894C111.754 35.2807 112.151 37.5906 112.948 39.7251C113.744 41.8596 114.974 43.5818 116.632 44.8918C118.291 46.2017 120.405 46.8567 122.975 46.8567Z"
				fill="currentColor"
			/>
		</svg>
	);
};
