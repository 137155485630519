import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { IMasterData, RoutsPath } from '../../../@types';
import { useScheduleFilters } from 'src/components/schedule/schedule-filters/schedule-filters.context';
import { Button } from 'src/components/ui/buttons/button/button.component';
import { SocialsMaster } from '../../socials/socials.component';
import Modal from '../../ui/modal/modal.component';
import { CloseModalIcon } from '../../ui/icons';
import { createLangHref } from 'src/utils/href.utils';
import cl from './modal-user.module.scss';

const ModalUser = ({
	data,
	setData,
}: {
	data: IMasterData | null;
	setData: (value: IMasterData | null) => void;
}) => {
	const location = useLocation();
	const { t, i18n } = useTranslation();
	const { filterMaster, filterGenre } = useScheduleFilters();
	const navigate = useNavigate();
	const goScheduleMaster = (id: string, name: string) => {
		filterMaster.onChangeMaster({
			values: id,
			label: name,
		});
		if (RoutsPath.schedule === location.pathname) {
			setData(null);
		} else {
			navigate(createLangHref(i18n.language, RoutsPath.schedule));
		}
	};

	const goScheduleMasterGenre = (id: string, name: string) => {
		filterMaster.onChangeMaster({
			values: data?.id.toString() || '0',
			label: data?.name || '',
		});
		filterGenre.onChangeGenre({
			values: id,
			label: name,
		});
		if (RoutsPath.schedule === location.pathname) {
			setData(null);
		} else {
			navigate(createLangHref(i18n.language, RoutsPath.schedule));
		}
	};

	const genreList = useMemo(() => {
		return filterGenre.options.filter((genre) => {
			return genre.genremaster_set.some((master) => {
				return master.master.id === data?.id;
			});
		});
	}, [data?.id, filterGenre.options]);

	return (
		<Modal
			open={data !== null}
			onClose={() => {
				setData(null);
			}}
			animate="fade"
			classes={{
				content: cl.content,
			}}
			closeIcon={<CloseModalIcon />}
			portal
		>
			<div className={cl.container}>
				{data && (
					<div className={cl.scroll}>
						<div className={cl.header__top}>
							<img src={data.image} alt={data.name} className={cl.preview} />
							<div className={cl.header__top__content}>
								<div className={cl.name}>{data.name}</div>
								<div className={cl.about}>{data.position}</div>
							</div>
						</div>
						<div className={cl.section}>
							<h4 className={cl.section__title}>{t('label.position')}</h4>
							<div className={cl.chips}>
								{genreList.map((item, index) => (
									<div
										className={cl.chips__item + ''}
										key={index}
										onClick={() =>
											goScheduleMasterGenre(item.id.toString(), item.name)
										}
									>
										{item.name}
									</div>
								))}
							</div>
						</div>

						<div className={cl.section}>
							<h4 className={cl.section__title}>{t('label.about.master')}</h4>
							<p className={cl.section__desc}>{data.about}</p>
						</div>
						{data.about_me !== '' && (
							<div className={cl.section}>
								<h4 className={cl.section__title}>{t('label.master.about')}</h4>
								<p className={cl.section__desc}>{data.about_me}</p>
							</div>
						)}
						<div className={cl.section__footer}>
							<SocialsMaster
								links={{ fb: data.fb, insta: data.ig }}
								className={cl.socials}
							/>
							<Button
								className={cl.btn}
								onClick={() => goScheduleMaster(data.id.toString(), data.name)}
							>
								{t('label.schedule')}
							</Button>
						</div>
					</div>
				)}
			</div>
		</Modal>
	);
};

export default ModalUser;
