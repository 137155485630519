import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CountryData } from 'react-phone-input-2';
import { DeliveryType } from 'src/@types';
import { IFormOrderProps } from './form-order.model';
import { ButtonGroup } from '../../ui/button-group/button-group.component';
import { PHONE_MASK } from '../../../const/regexp.const';
import cl from './form-order.module.scss';
import { InputField, InputMaskField } from '../../ui/input';
import { InputPhoneField } from '../../ui/input-phone';

export const FormOrder = ({
	formik,
	onChangeDeliveryType,
	deliveryType,
	refSubmit,
}: IFormOrderProps) => {
	const [country, setCountry] = useState<CountryData>({
		name: 'United Arab Emirates',
		dialCode: '971',
		countryCode: 'ae',
		format: '+... ... ... ...',
	});
	const { t } = useTranslation();

	const {
		values,
		errors,
		touched,
		handleChange,
		handleBlur,
		handleSubmit,
		setSubmitting,
		setFieldValue,
	} = formik;

	const onChangeDelivery = (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
	) => {
		const target = e.currentTarget;
		const data = target.dataset.value as DeliveryType;
		onChangeDeliveryType(data);
	};

	const buttons_delivery = useMemo(() => {
		return [
			{
				label: t(DeliveryType.PICKUP.toLowerCase()),
				data: DeliveryType.PICKUP,
				onClick: onChangeDelivery,
			},
			{
				label: t(DeliveryType.COURIER.toLowerCase()),
				data: DeliveryType.COURIER,
				onClick: onChangeDelivery,
			},
		];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={cl.form__wrapper}>
			<form onSubmit={handleSubmit} autoComplete="off" className={cl.form}>
				<div className={cl.row}>
					<h3 className={cl.row__title}>{t('title.form.delivery')}</h3>
					<ButtonGroup active={deliveryType} buttons={buttons_delivery} />
				</div>
				<div className={cl.hr} />
				<div className={cl.row}>
					<div className={cl.col}>
						<h3 className={cl.row__title}>{t('title.form.address')}</h3>
					</div>
					<div className={cl.col}>
						<InputField
							label={t('form.label.order.why')}
							name="address"
							error={
								touched.address && errors.address
									? t(errors.address)
									: undefined
							}
							value={values.address}
							onChange={handleChange}
							onBlur={handleBlur}
							onFocus={() => setSubmitting(false)}
							id={'order-id-address-delivery'}
							classes={{
								input: cl.input,
							}}
						/>
					</div>
				</div>
				<div className={cl.hr} />
				<div className={cl.row}>
					<h3 className={cl.row__title}>{t('title.form.user.data')}</h3>
				</div>

				<div className={cl.row + ' ' + cl.row_fieds}>
					<div className={cl.col}>
						<InputField
							label={t('form.label.name')}
							name="full_name"
							error={
								touched.address && errors.full_name
									? t(errors.full_name)
									: undefined
							}
							value={values.full_name}
							onChange={handleChange}
							onBlur={handleBlur}
							onFocus={() => setSubmitting(false)}
							id={'order-id-fullname-delivery'}
						/>

						<InputField
							label={t('form.label.email')}
							name="email"
							error={
								touched.email && errors.email ? t(errors.email) : undefined
							}
							value={values.email}
							onChange={handleChange}
							onBlur={handleBlur}
							onFocus={() => setSubmitting(false)}
							id={'order-id-email-delivery'}
						/>
					</div>
					<div className={cl.col}>
						<InputPhoneField
							field={{
								name: 'phone',
								value: values.phone,
								onChange: handleChange,
								onBlur: handleBlur,
							}}
							country={country}
							form={{
								touched,
								errors,
								setFieldValue,
							}}
							autofocus={false}
							onUpdateCountry={setCountry}
							id={'edit-profile-id-phone'}
							label={t('form.label.phone')}
							name="phone"
							error={
								touched.phone && errors.phone ? t(errors.phone) : undefined
							}
							value={values.phone}
							onFocus={() => {
								setSubmitting(false);
							}}
							onChange={handleChange}
							onBlur={handleBlur}
						/>

						<InputField
							label={t('form.label.messege.order')}
							name="message"
							error={
								touched.message && errors.message
									? t(errors.message)
									: undefined
							}
							value={values.message}
							onChange={handleChange}
							onBlur={handleBlur}
							onFocus={() => setSubmitting(false)}
							id={'order-id-message-delivery'}
						/>
					</div>
				</div>
				<button style={{ display: 'none' }} ref={refSubmit} type="submit">
					send
				</button>
			</form>
		</div>
	);
};
