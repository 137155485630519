import { IVolumeCSSProps, IVolumeProps } from '../index.model';

export const Volume = (props: IVolumeProps) => {
	const volumeStyles: IVolumeCSSProps = {
		'--volume-width': props?.value?.toString() + '%' || '0%',
	};

	return (
		<div className="audio-volume">
			{props.value === 0 ? (
				<svg
					width="18"
					height="21"
					viewBox="0 0 18 15"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					onClick={props.onMute}
				>
					<path
						fillRule="evenodd"
						clip-rule="evenodd"
						d="M5.36689 3.76836L8.83366 1.16946C9.93239 0.345787 11.5003 1.1294 11.5003 2.50219V12.4976C11.5003 13.8704 9.93239 14.654 8.83366 13.8304L5.36689 11.2315C5.02076 10.972 4.59983 10.8317 4.16723 10.8317H2.33366C1.41318 10.8317 0.666992 10.0859 0.666992 9.16583V5.83401C0.666992 4.91395 1.41318 4.1681 2.33366 4.1681H4.16723C4.59983 4.1681 5.02076 4.02784 5.36689 3.76836ZM13.6342 5.36608C13.3901 5.122 12.9944 5.122 12.7503 5.36608C12.5062 5.61016 12.5062 6.00589 12.7503 6.24997L13.975 7.47469L12.7503 8.69942C12.5062 8.94349 12.5062 9.33922 12.7503 9.5833C12.9944 9.82738 13.3901 9.82738 13.6342 9.5833L14.8589 8.35857L16.0836 9.5833C16.3277 9.82738 16.7234 9.82738 16.9675 9.5833C17.2116 9.33922 17.2116 8.94349 16.9675 8.69942L15.7428 7.47469L16.9675 6.24997C17.2116 6.00589 17.2116 5.61016 16.9675 5.36608C16.7234 5.122 16.3277 5.122 16.0836 5.36608L14.8589 6.59081L13.6342 5.36608Z"
						fill="#EBE9F0"
						fillOpacity="0.6"
					/>
				</svg>
			) : (
				<svg
					width="20"
					height="21"
					viewBox="0 0 20 21"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					onClick={props.onMute}
					role="button"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M6.36689 6.76811L9.83366 4.16921C10.9324 3.34554 12.5003 4.12916 12.5003 5.50194V15.4974C12.5003 16.8702 10.9324 17.6538 9.83366 16.8301L6.36689 14.2312C6.02076 13.9718 5.59983 13.8315 5.16723 13.8315H3.33366C2.41318 13.8315 1.66699 13.0856 1.66699 12.1656V8.83376C1.66699 7.91371 2.41318 7.16785 3.33366 7.16785H5.16723C5.59983 7.16785 6.02076 7.02759 6.36689 6.76811ZM14.4997 5.80383C14.1614 5.73517 13.8315 5.95373 13.7628 6.29201C13.6942 6.63029 13.9127 6.96018 14.251 7.02885C15.8673 7.35695 17.0837 8.78696 17.0837 10.4997C17.0837 12.2124 15.8673 13.6424 14.251 13.9705C13.9127 14.0391 13.6942 14.369 13.7628 14.7073C13.8315 15.0456 14.1614 15.2642 14.4997 15.1955C16.6871 14.7515 18.3337 12.8186 18.3337 10.4997C18.3337 8.18076 16.6871 6.24785 14.4997 5.80383ZM14.6883 8.51496C14.3895 8.34212 14.0072 8.44422 13.8343 8.74301C13.6615 9.0418 13.7636 9.42413 14.0624 9.59697C14.3754 9.77802 14.5837 10.1149 14.5837 10.4997C14.5837 10.8844 14.3754 11.2213 14.0624 11.4024C13.7636 11.5752 13.6615 11.9575 13.8343 12.2563C14.0072 12.5551 14.3895 12.6572 14.6883 12.4844C15.3716 12.0891 15.8337 11.3487 15.8337 10.4997C15.8337 9.65063 15.3716 8.91026 14.6883 8.51496Z"
						fill="#EBE9F0"
						fillOpacity="0.6"
					/>
				</svg>
			)}

			<input
				type="range"
				name="volume"
				className={'audio-volume-range'}
				min={0}
				max={100}
				style={volumeStyles}
				{...props}
			/>
		</div>
	);
};
