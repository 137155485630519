import cl from './content-layout.module.scss';

const ContentLayout = ({
	aside,
	children,
	small = false,
	marginTop = true,
	mobileTabs = false,
	classes,
}: {
	aside?: JSX.Element;
	children: JSX.Element;
	small?: boolean;
	marginTop?: boolean;
	mobileTabs?: boolean;
	classes?: {
		cl_root?: string;
		cl_main?: string;
		cl_aside?: string;
	};
}) => {
	const { cl_aside = '', cl_main = '', cl_root = '' } = classes || {};
	return (
		<div
			className={
				cl.row__container + (small ? ' container-small ' : ' ') + ' ' + cl_root
			}
		>
			{aside && (
				<aside
					className={
						cl.paper +
						(marginTop ? ' ' + cl.aside__margin__top : '') +
						' ' +
						(mobileTabs ? cl.aside__mobile__tabs : cl.aside) +
						' ' +
						cl_aside
					}
				>
					{aside}
				</aside>
			)}
			<main
				className={cl.main + ' ' + cl_main + (aside ? '' + cl.main__aside : '')}
			>
				{children}
			</main>
		</div>
	);
};

export default ContentLayout;
