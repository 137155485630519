import { Preview } from 'src/@types';
import cl from './user-contacts.module.scss';

export const UserContacts = ({
	title,
	contact,
	user,
}: {
	title: string;
	contact: { email: string; emailUser: string; phone: string };
	user: {
		preview: Preview;
		name: string;
		description: string;
	};
}) => {
	return (
		<div className={cl.container}>
			<div className={cl.grid}>
				<div className={cl.title}>{title}</div>
				<picture className={cl.preview}>
					{user.preview.map((img) => (
						<source
							type={`image/${img.type}`}
							srcSet={img.uri}
							key={img.type}
						/>
					))}

					<img
						src={user.preview[user.preview.length - 1].uri}
						className={cl.preview}
						alt=""
						loading="lazy"
						decoding="async"
					/>
				</picture>
				<div className={cl.info}>
					<p className={cl.info__name}>{user.name}</p>
					<p className={cl.info__desc}>{user.description}</p>
					<div className={cl.contacts}>
						<a href={`tel:${contact.phone.replace(/\D/g, '')}`}>
							{contact.phone}
						</a>
						<a href={`mailto:${contact.email}}`}>{contact.email}</a>
						<a href={`mailto:${contact.emailUser}`}>{contact.emailUser}</a>
					</div>
				</div>
			</div>
		</div>
	);
};
