import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CardProduct } from '../../components/card/card-product/card-product.component';
import { PageLayout } from '../../components/layout/page.layout';
import { Breadcrumbs } from '../../components/ui/breadcrumbs/breadcrumbs.component';
import { Button } from '../../components/ui/buttons/button/button.component';
import { useWindowSize } from 'src/hooks/useWindowSize';
import { apiServices } from '../../services/api';
import cl from './index.module.scss';

type FilterCatalog = { limit: number; offset: number };

const useShop = (filters: FilterCatalog) => {
	return useQuery({
		queryKey: [filters.limit, filters.offset, 'shop-list'],
		queryFn: async () => {
			return await apiServices.getShopList(filters);
		},
	});
};

const CatalogPage = () => {
	const { t } = useTranslation();
	const [filter, setFilter] = useState<FilterCatalog>({ limit: 8, offset: 0 });
	const { data, isLoading, isFetching } = useShop(filter);
	const onMore = () => {
		setFilter({ limit: filter.limit, offset: filter.offset + filter.limit });
	};
	const { width = window.innerWidth } = useWindowSize();
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, []);

	return (
		<PageLayout positionHeader="fixed">
			<div className={cl.page}>
				<div className={'header-offset'} />
				<div className={width >= 1280 ? 'container-small' : 'container'}>
					<div className={'box20'}></div>
					<Breadcrumbs items={[{ name: 'page.catalog', translate: true }]} />
					<h1 className="title-section-small">{t('page.catalog')}</h1>
					<ul className={'row ' + cl.products}>
						{data && !isLoading
							? data.results.map((item) => (
									<li className={cl.products__item} key={item.id}>
										<CardProduct data={item} />
									</li>
								))
							: [1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
									<li
										className={
											cl.products__item +
											' skeleton ' +
											cl.products__item__loading
										}
										key={item + '-loading'}
									></li>
								))}
					</ul>
					<div className={cl.catalog__nav}>
						<Button
							theme="outline"
							disabled={isLoading || isFetching || data?.next === null}
							onClick={onMore}
							className={cl.more}
						>
							{t('btn-load-more')}
						</Button>
					</div>
				</div>
			</div>
		</PageLayout>
	);
};

export default CatalogPage;
