import { FormikHelpers, useFormik } from 'formik';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeliveryType, Langs } from 'src/@types';
import { useAuth } from 'src/application/application.auth.context';
import { apiServices } from 'src/services/api';
import { ORDER_INITIAL_VALUES } from './form-order.mock';
import { OrderFormData, orderFormSchema } from './form-order.schema';

export const useFormOrderLogic = () => {
	const { isAuth, user } = useAuth();
	const { i18n } = useTranslation();
	const [deliveryType, setDeliveryType] = useState(DeliveryType.PICKUP);

	const onSubmit = async (
		values: OrderFormData,
		actions: FormikHelpers<OrderFormData>,
	) => {
		apiServices
			.createOrder(
				{
					delivery_type: deliveryType,
					delivery_address: values.address,
					lang: i18n.language as Langs,
				},
				isAuth,
			)
			.then((response) => {
				console.log(response);
				if (response.payment_link) {
					window.location.href = response.payment_link;
				}
			})
			.catch((error) => {
				console.log('Error: ', error);
			});
	};

	const formik = useFormik<OrderFormData>({
		initialValues:
			isAuth && user
				? {
						full_name: user.last_name + ' ' + user.first_name,
						phone: user.phone,
						email: user.email,
						message: '',
						address: '',
					}
				: ORDER_INITIAL_VALUES,
		validationSchema: orderFormSchema,
		onSubmit,
	});

	const isDisabled = useMemo(() => {
		return (
			formik.isSubmitting ||
			!!(formik.errors.full_name && formik.touched.full_name) ||
			!!(formik.errors.email && formik.touched.email) ||
			!!(formik.errors.message && formik.touched.message) ||
			!!(formik.errors.address && formik.touched.address)
		);
	}, [formik]);

	return {
		formik,
		deliveryType,
		setDeliveryType,
		isDisabled,
	};
};
