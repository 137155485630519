import cl from './event-data-info.module.scss';

interface EventDateInfoProps {
	week: string;
	day: string;
	month: string;
	timeStart: string[] | null;
	timeFinish: string[] | null;
}

const EventDateInfo = ({ week, day, month, timeStart, timeFinish }: EventDateInfoProps) => (
	<div className={cl.event__info__date}>
		<div className={cl.date}>
			{week}, {day} {month}
		</div>
		<div className={cl.duration}>
			{timeStart && `${timeStart[0]}:${timeStart[1]}`}
			{timeFinish && ` — ${timeFinish[0]}:${timeFinish[1]}`}
		</div>
	</div>
);

export default EventDateInfo;