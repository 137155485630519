import { useEffect, useRef } from 'react';
import { Preview } from 'src/@types';
import l1 from './assets/l1.jpg';
import l2 from './assets/l2.jpg';
import l3 from './assets/l3.jpg';
import l4 from './assets/l4.jpg';
import l5 from './assets/l5.jpg';
import cl from './slide-section-img.module.scss';

const COLORS = [
	'#22023C',
	'rgba(103, 11, 211, 1)',
	'rgba(64, 128, 255, 1)',
	'rgba(51, 179, 172, 1)',
	'rgba(255, 112, 49, 1)',
	'#22023C',
	'rgba(103, 11, 211, 1)',
	'rgba(64, 128, 255, 1)',
	'rgba(51, 179, 172, 1)',
	'rgba(255, 112, 49, 1)',
	'#22023C',
	'rgba(103, 11, 211, 1)',
	'rgba(64, 128, 255, 1)',
	'rgba(51, 179, 172, 1)',
	'rgba(255, 112, 49, 1)',
];

const LAYERS_BG = [l1, l2, l3, l4, l5, l1, l2, l3, l4, l5];

export const SliderSectionImg = ({
	posts,
	title = "Who It's For",
	autoplay = {
		play: false,
		duration: 1000,
	},
}: {
	posts: { preview: Preview; title: string }[];
	title?: string;
	autoplay?: {
		play: boolean;
		duration: number;
	};
}) => {
	const refList = useRef<HTMLUListElement | null>(null);
	const refActive = useRef(0);
	const refRevertCounter = useRef(0);
	const refIsBlock = useRef(false);
	const refTime = useRef<NodeJS.Timeout>();
	const refTimeRevert = useRef<NodeJS.Timeout>();
	const refTimeReverStepTwo = useRef<NodeJS.Timeout>();
	const refTimerAutoplay = useRef<NodeJS.Timeout>();

	const onAutoplay = () => {
		if (autoplay.play === true) {
			refTimerAutoplay.current = setInterval(onClick, autoplay.duration);
		}
	};

	useEffect(() => {
		const items = refList.current?.querySelectorAll('li');
		if (items) {
			for (let i = 0; i < 3; i++) {
				if (i === 0) {
					items[i].classList.add(cl.list__item__one);
				} else if (i === 1) {
					items[i].classList.add(cl.list__item__two);
				} else if (i === 2) {
					items[i].classList.add(cl.list__item__three);
				}
			}
		}
		onAutoplay();
		return () => {
			refTime.current && clearTimeout(refTime.current);
			refTimeRevert.current && clearTimeout(refTimeRevert.current);
			refTimeReverStepTwo.current && clearTimeout(refTimeReverStepTwo.current);
			refTimerAutoplay.current && clearInterval(refTimerAutoplay.current);
		};
	}, []);

	const onClick = () => {
		const items = refList.current?.querySelectorAll('li');
		if (items && !refIsBlock.current) {
			refIsBlock.current = true;
			const len = items.length - (refActive.current + 3);
			if (items.length !== refActive.current + 1) {
				if (len > 0) {
					items[refActive.current].classList.remove(cl.list__item__one);
					items[refActive.current].classList.add(cl.list__item__null);

					items[refActive.current + 1].classList.remove(cl.list__item__two);
					items[refActive.current + 1].classList.add(cl.list__item__one);

					items[refActive.current + 2].classList.remove(cl.list__item__three);
					items[refActive.current + 2].classList.add(cl.list__item__two);

					items[refActive.current + 3].classList.add(cl.list__item__three);
				} else if (len === 0) {
					items[refActive.current].classList.remove(cl.list__item__one);
					items[refActive.current].classList.add(cl.list__item__null);

					items[refActive.current + 1].classList.remove(cl.list__item__two);
					items[refActive.current + 1].classList.add(cl.list__item__one__big);

					items[refActive.current + 2].classList.remove(cl.list__item__three);
					items[refActive.current + 2].classList.add(cl.list__item__two__big);
				}
				if (len < 0) {
					items[refActive.current].classList.remove(cl.list__item__one__big);
					items[refActive.current].classList.add(cl.list__item__null);

					items[refActive.current + 1].classList.remove(
						cl.list__item__two__big,
					);
					items[refActive.current + 1].classList.add(cl.list__item__one__full);
				}

				refTime.current = setTimeout(() => {
					refIsBlock.current = false;
				}, 1000);
				refActive.current = refActive.current + 1;
			} else {
				if (autoplay.play === true && refTimerAutoplay.current) {
					clearInterval(refTimerAutoplay.current);
				}
				refRevertCounter.current = refActive.current;

				items[refRevertCounter.current].classList.remove(
					cl.list__item__one__full,
				);
				items[refRevertCounter.current].classList.add(cl.list__item__two__big);

				items[refRevertCounter.current - 1].classList.remove(
					cl.list__item__null,
				);
				items[refRevertCounter.current - 1].classList.add(
					cl.list__item__one__big,
				);
				refRevertCounter.current = refRevertCounter.current - 1;

				const revert = () => {
					refTimeRevert.current = setTimeout(() => {
						items[refRevertCounter.current - 1].classList.remove(
							cl.list__item__null,
						);
						items[refRevertCounter.current - 1].classList.add(
							cl.list__item__one,
						);

						items[refRevertCounter.current].classList.remove(
							cl.list__item__one,
						);
						items[refRevertCounter.current].classList.add(cl.list__item__two);

						items[refRevertCounter.current + 1].classList.remove(
							cl.list__item__two,
						);
						items[refRevertCounter.current + 1].classList.add(
							cl.list__item__three,
						);

						items[refRevertCounter.current + 2]?.classList.remove(
							cl.list__item__three,
						);

						refRevertCounter.current = refRevertCounter.current - 1;
						if (refRevertCounter.current !== 0) {
							revert();
						} else {
							refActive.current = 0;
							refIsBlock.current = false;
							onAutoplay();
						}
					}, 1000);
				};

				refTimeReverStepTwo.current = setTimeout(() => {
					items[refRevertCounter.current + 1].classList.remove(
						cl.list__item__two__big,
					);
					items[refRevertCounter.current + 1].classList.add(
						cl.list__item__three,
					);

					items[refRevertCounter.current].classList.remove(
						cl.list__item__one__big,
					);
					items[refRevertCounter.current].classList.add(cl.list__item__two);

					items[refRevertCounter.current - 1].classList.remove(
						cl.list__item__null,
					);
					items[refRevertCounter.current - 1].classList.add(cl.list__item__one);
					refRevertCounter.current = refRevertCounter.current - 1;
					revert();
				}, 1000);
			}
		}
	};

	return (
		<div className={cl.section}>
			<h2 className={cl.sectionTitle}>{title}</h2>
			<ul
				className={cl.list}
				ref={refList}
				onClick={!autoplay.play ? onClick : undefined}
			>
				{posts.map((item, index) => (
					<li
						key={index}
						className={cl.list__item}
						style={{
							backgroundColor: COLORS[index],
							backgroundImage: `url(${LAYERS_BG[index]})`,
						}}
					>
						<p className={cl.title}>{item.title}</p>
						<picture className={cl.image}>
							{item.preview.map((img) => (
								<source
									type={'image/' + img.type}
									srcSet={img.uri}
									key={img.type}
								/>
							))}
							<img
								src={item.preview[item.preview.length - 1].uri}
								className={cl.image}
								alt=""
								loading="lazy"
								decoding="async"
							/>
						</picture>
					</li>
				))}
			</ul>
		</div>
	);
};
