import { Preview } from 'src/@types';
import img3 from './assets/3.jpg';
import imgWebp3 from './assets/3.webp';
import img4 from './assets/4.jpg';
import imgWebp4 from './assets/4.webp';
import img5 from './assets/5.jpg';
import imgWebp5 from './assets/5.webp';
import img2 from './assets/6.jpg';
import imgWebp2 from './assets/6.webp';
import img1 from './assets/7.jpg';
import imgWebp1 from './assets/7.webp';

export const SECTION_STUDEIO_ITEMS: { img: Preview; title?: string }[] = [
	{
		img: [
			{
				type: 'webp',
				uri: imgWebp1,
			},
			{
				type: 'jpg',
				uri: img1,
			},
		],
		title: '',
	},
	{
		img: [
			{
				type: 'webp',
				uri: imgWebp2,
			},
			{
				type: 'jpg',
				uri: img2,
			},
		],
		title: '',
	},
	{
		img: [
			{
				type: 'webp',
				uri: imgWebp3,
			},
			{
				type: 'jpg',
				uri: img3,
			},
		],
		title: '',
	},
	{
		img: [
			{
				type: 'webp',
				uri: imgWebp4,
			},
			{
				type: 'jpg',
				uri: img4,
			},
		],
		title: '',
	},
	{
		img: [
			{
				type: 'webp',
				uri: imgWebp5,
			},
			{
				type: 'jpg',
				uri: img5,
			},
		],
		title: '',
	},
];
