import { ISvgIconProps } from '.';

export const OpenEyeIcon = ({
	className = '',
	viewBox = '0 0 20 20',
	refComponent = undefined,
}: ISvgIconProps) => {
	return (
		<svg
			className={className}
			ref={refComponent}
			viewBox={viewBox}
			width="20"
			height="20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.7342 10.0531C12.7342 11.5631 11.5095 12.7869 9.99946 12.7869C8.48941 12.7869 7.26562 11.5631 7.26562 10.0531C7.26562 8.54214 8.48941 7.31836 9.99946 7.31836C11.5095 7.31836 12.7342 8.54214 12.7342 10.0531Z"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.99827 16.3681C13.2917 16.3681 16.304 14.0001 18 10.0529C16.304 6.10564 13.2917 3.73764 9.99827 3.73764H10.0017C6.70832 3.73764 3.696 6.10564 2 10.0529C3.696 14.0001 6.70832 16.3681 10.0017 16.3681H9.99827Z"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
