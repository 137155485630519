import { MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { DeliveryType } from 'src/@types';
import { Button } from '../../ui/buttons/button/button.component';
import { Specification } from '../../ui/specification/specification.component';
import { useCart } from '../../cart/cart.context';
import { CURRENCY } from '../../../const/settings.const';
import cl from './aside-order.module.scss';

export const AsideOrder = ({
	address = '',
	refSubmit,
	deliveryType,
	isDisabled,
}: {
	refSubmit: MutableRefObject<HTMLButtonElement | null>;
	deliveryType: DeliveryType;
	isDisabled: boolean;
	address?: string;
}) => {
	const { totalPrice, productsCount } = useCart();
	const { t } = useTranslation();

	return (
		<div className={cl.card}>
			<div className={cl.header}>
				<div className={cl.title}>{t('total')}</div>
			</div>
			<div className={cl.body}>
				<Specification
					items={[
						{
							label: t('specification.count'),
							value: productsCount.toString(),
						},
						{
							label: t('specification.price'),
							value: totalPrice + CURRENCY,
						},
					]}
				/>
			</div>
			<div className={cl.header}>
				<div className={cl.hr_top + ' hr ' + cl.hr} />
				<div className={cl.title + ' ' + cl.pd_20}>
					{t('aside.title.delivery')}
				</div>
			</div>
			<div className={cl.body}>
				<Specification
					classes={{
						list_item: cl.list_item,
					}}
					items={[
						{
							label: t('specification.type'),
							value: t(deliveryType.toLowerCase()),
						},
						{
							label: t('specification.address'),
							value: address,
							dataStyle: { value: 'address' },
						},
						{
							label: t('specification.total'),
							value: '50' + CURRENCY,
						},
					]}
				/>
			</div>
			<div className={cl.actions}>
				<div className={cl.hr_top + ' hr ' + cl.hr} />
				<Button
					className={cl.btn}
					type="submit"
					disabled={isDisabled}
					onClick={() => {
						if (refSubmit.current) {
							refSubmit.current.click();
						}
					}}
				>
					<>
						{t('pay')} {totalPrice + 50} {CURRENCY}
					</>
				</Button>
			</div>
			<p className={cl.policy}>
				{t('policy.text')}{' '}
				<a href="/#" rel="noflow noindex noreferrer">
					{t('policy.link')}
				</a>
			</p>
		</div>
	);
};
