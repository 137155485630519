import cl from './radio-image.module.scss';

export const CheckedListSkeleton = ({
	len,
	classes,
	type,
}: {
	len: number;
	type?: 'preview' | 'color';
	classes?: { root?: string };
}) => {
	const items = Array(len)
		.fill(null)
		.map((_, i) => i);
	return (
		<div className={cl.radio + ` ${classes?.root || ''}`}>
			<div className={cl.radio__title__skeleton + ' skeleton'}></div>
			<ul className={cl.radio__list}>
				{items.map((item) => (
					<li
						className={`${cl.radio__item} skeleton ${
							type === 'preview'
								? cl.item__img__skeleton
								: type === 'color'
									? cl.item__color__skeleton
									: cl.item__text + ' ' + cl.item__text__skeleton
						}`}
						key={item}
					/>
				))}
			</ul>
		</div>
	);
};
export const CheckedList = <T,>({
	active = [],
	items,
	onClickItem,
	title,
	classes,
	headerAction,
}: {
	title: string;
	active?: string[] | number[];
	items: {
		label: string;
		color?: string;
		preview?: string;
		value: string | number;
		data: T;
	}[];
	onClickItem: (value: { value: string | number; data: T }) => void;
	classes?: {
		root?: string;
		header?: string;
	};
	headerAction?: JSX.Element;
}) => {
	return (
		<div className={cl.radio + ` ${classes?.root || ''}`}>
			<div className={cl.header + ` ${classes?.header || ''}`}>
				<h5 className={cl.radio__title}>{title}</h5>
				{headerAction}
			</div>
			<ul className={cl.radio__list}>
				{items.map((item, index) => (
					<li
						key={index}
						className={
							cl.radio__item +
							` ${
								item.preview
									? cl.item__img
									: item.color
										? cl.item__color
										: cl.item__text
							}` +
							(active &&
							active.findIndex((el: string | number) => el === item.value) !==
								-1
								? ` ${cl.radio__item__active}`
								: '')
						}
						onClick={() => onClickItem({ value: item.value, data: item.data })}
					>
						{item.preview ? (
							<img
								src={item.preview}
								width={60}
								height={60}
								alt=""
								className={cl.img}
								loading="lazy"
							/>
						) : (
							<span
								className={item.color ? cl.item__color : ''}
								style={{ backgroundColor: item.color }}
							>
								{item.color ? '' : item.label}
							</span>
						)}
					</li>
				))}
			</ul>
		</div>
	);
};
