import { t } from 'i18next';
import { useContext, useMemo } from 'react';
import { Button } from '../../ui/buttons/button/button.component';
import { FormAuthContext } from '../../forms/form-auth/form-auth.context';
import { ModalAuthContext } from '../../modals/modal-auth/modal-auth.context';
import './pyro.scss';
import cl from './success-modal-content.module.scss';

interface ISuccessModalContentProps {
	type: 'registration' | 'profileSave' | 'order';
	handleClose?: () => void;
}

const SuccessModalContentComponent = ({
	type = 'registration',
	handleClose,
}: ISuccessModalContentProps) => {
	const { open } = useContext(ModalAuthContext);
	const { form } = useContext(FormAuthContext);

	const showAnimation = useMemo(() => {
		return open && form === 'successfullyRegistration';
	}, [form, open]);

	const modalContent = useMemo(() => {
		return {
			registration: {
				title: t('successModal.registration.title'),
				description: t('successModal.registration.description'),
			},
			profileSave: {
				title: t('successModal.profileSave.title'),
				description: t('successModal.profileSave.description'),
			},
			order: {
				title: t('successModal.order.title'),
				description: t('successModal.order.description'),
			},
			button: t('successModal.button'),
		};
	}, []);

	const content = useMemo(() => modalContent[type], [modalContent, type]);

	return (
		<div className={cl.successModalContent}>
			{showAnimation && (
				<div className="pyro">
					<div className="before"></div>
					<div className="after"></div>
				</div>
			)}

			<div className={cl.modalContent}>
				<div className={cl.title}>{content.title}</div>
				<div className={cl.description}>{content.description}</div>
			</div>
			<Button
				onClick={handleClose}
				type="button"
				theme="rainbow"
				className={cl.button}
			>
				{modalContent.button}
			</Button>
		</div>
	);
};

export default SuccessModalContentComponent;
