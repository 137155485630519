import { useId, useRef, useState } from 'react';
import ReactSelect, { DropdownIndicatorProps, components } from 'react-select';
import Select from 'react-select/dist/declarations/src/Select';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { useUpdateEffect } from 'src/hooks/useUpdateEffect';
import './input-select.scss';
import {
	styles_input_select,
	styles_input_select_mobile,
} from './input-select.style';

export interface IInputSelectProps<T> {
	onChange: (value: T, onReset?: () => void) => void;
	placeholder?: string | undefined;
	options: any;
	defaultValue?: T;
	value?: T;
	id?: string;
	portalMount?: HTMLElement | null | undefined;
	name?: string | undefined;
	error?: string;
	onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined;
	onFocus?: React.FocusEventHandler<HTMLInputElement> | undefined;
	classes?: {
		root?: string;
		endAdornment?: string;
		startAdornment?: string;
	};
	paddingBottom?: boolean;
	endAdornment?: JSX.Element;
	startAdornment?: JSX.Element;
}

const DropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => {
	return (
		<components.DropdownIndicator {...props}>
			<svg
				width="17"
				height="16"
				viewBox="0 0 17 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M12.5 6L8.49999 10L4.49999 6"
					stroke="#EBE9F0"
					strokeOpacity="0.6"
					strokeWidth="1.5"
				/>
			</svg>
		</components.DropdownIndicator>
	);
};

const InputSelect = <T,>({
	onChange,
	options,
	placeholder,
	defaultValue,
	value,
	id,
	portalMount,
	onBlur,
	onFocus,
	name,
	classes,
	error,
	paddingBottom = false,
	endAdornment,
}: IInputSelectProps<T>) => {
	const [isOpen, setIsSelectOpen] = useState(false);
	const id_field = useId();
	const { isMobile } = useWindowSize();
	const { root = '' } = classes || {};
	const refSelect = useRef<Select | null>(null);
	const isError = error !== undefined ? true : false;

	useUpdateEffect(() => {
		if (value === undefined && refSelect.current) {
			refSelect?.current?.clearValue();
		}
	}, [value]);

	return (
		<div
			className={`input-select-form-control ${
				paddingBottom ? 'padding' : ''
			} ${root} ${isError ? 'error' : ''}`}
		>
			<ReactSelect
				ref={refSelect}
				name={name}
				inputId={id_field}
				id={id || id_field}
				styles={isMobile ? styles_input_select_mobile : styles_input_select}
				isSearchable={false}
				onChange={(val) => {
					onChange(val as T, refSelect?.current?.clearValue);
				}}
				options={options}
				placeholder={placeholder}
				defaultValue={defaultValue}
				value={value}
				onMenuOpen={() => {
					setIsSelectOpen(true);
				}}
				onMenuClose={() => {
					setIsSelectOpen(false);
				}}
				menuIsOpen={isOpen}
				components={{
					DropdownIndicator:
						value === undefined ? DropdownIndicator : undefined,
				}}
				menuPortalTarget={portalMount}
				onBlur={onBlur}
				onFocus={onFocus}
			/>
			{endAdornment && value && (
				<div
					className={`input-select-form-control-adornment input-select-form-control-adornment__end ${
						classes?.endAdornment || ''
					}`}
				>
					{endAdornment}
				</div>
			)}
			<div
				className={`input-select-form-control__error ${isError ? 'error' : ''}`}
			>
				{error || ''}
			</div>
		</div>
	);
};

export default InputSelect;
