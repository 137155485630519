import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ISliderReviewProps } from './slider-review.model';
import { ArrowRightTwoIcon } from '../ui/icons';
import cl from './slider-review.module.scss';

const BREAKPOINTS = {
	0: {
		slidesPerView: 1.2,
		spaceBetween: 16,
	},
	570: {
		slidesPerView: 1.5,
		spaceBetween: 16,
	},
	768: {
		slidesPerView: 2.2,
		spaceBetween: 16,
	},
	996: {
		slidesPerView: 1,
		spaceBetween: 0,
	},
};

const pagination = {
	el: '.swiper-review-pagination',
	clickable: true,
	renderBullet: function (index: number, className: string) {
		return `<span class="dot swiper-pagination-bullet"></span>`;
	},
};

export const SliderReview = ({ items, classes }: ISliderReviewProps) => {
	const { root = '' } = classes || {};
	return (
		<div className={cl.container + ' ' + root}>
			<div
				className={
					cl.navigation__btn +
					' ' +
					cl.navigation__btn__prev +
					' swiper-review-button-prev'
				}
				role="button"
			>
				<ArrowRightTwoIcon className={cl.navigation__btn__icon} />
			</div>
			<div
				className={
					cl.navigation__btn +
					' ' +
					cl.navigation__btn__next +
					' swiper-review-button-next'
				}
				role="button"
			>
				<ArrowRightTwoIcon className={cl.navigation__btn__icon} />
			</div>
			<div className={cl.wrapper}>
				<Swiper
					breakpoints={BREAKPOINTS}
					slidesPerView={1}
					spaceBetween={0}
					className={'swiper-review ' + cl.swiper}
					modules={[Navigation, Pagination]}
					pagination={pagination}
					autoHeight={true}
					navigation={{
						nextEl: '.swiper-review-button-next',
						prevEl: '.swiper-review-button-prev',
						disabledClass: 'swiper-button-disabled',
					}}
				>
					{items.map((item, index) => {
						return (
							<SwiperSlide key={index} className={cl.swiper__slide}>
								<div className={cl.slide}>
									<div className={cl.content}>
										<svg
											width="16"
											height="16"
											viewBox="0 0 16 16"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M3.71294 7.03901L3.71294 8.32695L6.93063 8.32695L6.93063 15.2574L-1.33385e-06 15.2574L-7.27965e-07 8.32694L0.000166898 7.03901C0.000167127 4.4237 0.752639 2.48172 2.23681 1.26666C3.2635 0.426155 4.56308 3.98917e-07 6.09951 5.33236e-07L6.09951 3.71261C5.26546 3.71261 3.71294 3.71261 3.71294 7.03901ZM15.169 3.71261L15.169 1.32611e-06C13.6326 1.1918e-06 12.333 0.426156 11.3063 1.26666C9.82217 2.48172 9.06962 4.42379 9.06962 7.03902L9.06962 8.32695L9.06962 15.2574L16 15.2574L16 8.32695L12.7823 8.32695L12.7823 7.03902C12.7824 3.71261 14.3349 3.71261 15.169 3.71261Z"
												fill="#824BD1"
											/>
										</svg>
										<blockquote>{item.message}</blockquote>
										<svg
											width="16"
											height="16"
											viewBox="0 0 16 16"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M12.2871 8.47563L12.2871 7.1877L9.06937 7.1877L9.06937 0.257237L16 0.257237L16 7.1877L15.9998 7.1877L15.9998 8.47563C15.9998 11.0909 15.2474 13.0329 13.7632 14.248C12.7365 15.0885 11.4369 15.5146 9.90049 15.5146L9.90049 11.802C10.7345 11.802 12.2871 11.802 12.2871 8.47563ZM0.831038 11.802L0.831038 15.5146C2.36739 15.5146 3.66696 15.0885 4.69365 14.248C6.17783 13.0329 6.93038 11.0909 6.93038 8.47563L6.93038 7.1877L6.93038 0.257237L2.28752e-06 0.257236L1.68164e-06 7.1877L3.21769 7.1877L3.21769 8.47563C3.21761 11.802 1.66509 11.802 0.831038 11.802Z"
												fill="#824BD1"
											/>
										</svg>
									</div>
									<div className={cl.user}>
										<picture className={cl.user__preview}>
											{item.preview.map((img) => (
												<source
													type={`image/${img.type}`}
													srcSet={img.uri}
													key={img.type}
												/>
											))}

											<img
												src={item.preview[item.preview.length - 1].uri}
												className={cl.user__preview}
												alt=""
												loading="lazy"
												decoding="async"
											/>
										</picture>
										<p className={cl.user__name}>{item.name}</p>
									</div>
								</div>
							</SwiperSlide>
						);
					})}
				</Swiper>
				<div className={'swiper-review-pagination ' + cl.pagination}></div>
			</div>
		</div>
	);
};
