import { useTranslation } from 'react-i18next';
import { Preview } from 'src/@types';

export const useLearningToPlayInstrumentsPost = (): {
	preview: {
		type: 'webp' | 'jpg' | 'png';
		uri: string;
	}[];
	title: string;
	subtitle: string;
}[] => {
	const { t } = useTranslation();
	return [
		{
			subtitle: t('retreats.variants.post.one.description'),
			title: t('retreats.variants.post.one.title'),
			preview: [
				{
					type: 'webp',
					uri: '/image/learning/s1.webp',
				},
				{
					type: 'png',
					uri: '/image/learning/s1.png',
				},
			],
		},
		{
			subtitle: t('retreats.variants.post.two.description'),
			title: t('retreats.variants.post.two.title'),
			preview: [
				{
					type: 'webp',
					uri: '/image/learning/s2.webp',
				},
				{
					type: 'png',
					uri: '/image/learning/s2.png',
				},
			],
		},
		{
			subtitle: t('retreats.variants.post.tree.description'),
			title: t('retreats.variants.post.tree.title'),
			preview: [
				{
					type: 'webp',
					uri: '/image/learning/s3.webp',
				},
				{
					type: 'png',
					uri: '/image/learning/s3.png',
				},
			],
		},
	];
};

export const useLearningToPlayInstrumentsReviews = (): {
	name: string;
	message: string;
	preview: Preview;
}[] => {
	const { t } = useTranslation();
	return [
		{
			name: t('retreats.reviews.items.one.name'),
			message: t('retreats.reviews.items.one.message'),
			preview: [
				{
					type: 'webp',
					uri: '/image/company-retreats/r.webp',
				},
				{
					type: 'jpg',
					uri: '/image/company-retreats/r.jpg',
				},
			],
		},
		{
			name: t('retreats.reviews.items.two.name'),
			message: t('retreats.reviews.items.two.message'),
			preview: [
				{
					type: 'webp',
					uri: '/image/company-retreats/r.webp',
				},
				{
					type: 'jpg',
					uri: '/image/company-retreats/r.jpg',
				},
			],
		},
	];
};
