import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { IBreadcrumbsProps } from './breadcrumbs.model';
import { BREADCRUMS_DEFAULT } from './breadcrumbs.const';
import { createLangHref } from '../../../utils/href.utils';
import cl from './breadcrumbs.module.scss';

export const BreadcrumbsSkeleton = ({
	theme = 'default',
}: {
	theme?: string;
}) => {
	return (
		<div className={cl.breadcrumbs}>
			<div className={cl.breadcrumbs__item__skeleton + ' skeleton'}></div>
			<div
				className={cl.breadcrumbs__sp + ' ' + cl[`breadcrumbs__sp_${theme}`]}
			></div>
			<div className={cl.breadcrumbs__item__skeleton + ' skeleton'}></div>
			<div
				className={cl.breadcrumbs__sp + ' ' + cl[`breadcrumbs__sp_${theme}`]}
			></div>
			<div className={cl.breadcrumbs__item__skeleton + ' skeleton'}></div>
		</div>
	);
};

export const Breadcrumbs = ({
	items,
	classes = { root: '' },
	theme = 'default',
}: IBreadcrumbsProps) => {
	const { t, i18n } = useTranslation();
	return (
		<div className={cl.breadcrumbs + ' ' + classes?.root}>
			{[...BREADCRUMS_DEFAULT, ...items].map((item, index) => {
				if (item?.href) {
					const isLastItem = index === items.length;
					return (
						<span className={cl.breadcrumbs__span} key={index}>
							<Link
								to={createLangHref(i18n.language, item.href)}
								className={
									cl.breadcrumbs__item +
									' ' +
									cl.breadcrumbs__item__link +
									' ' +
									cl[`breadcrumbs__item__link_${theme}`]
								}
							>
								{item.translate === true ? t(item.name) : item.name}
							</Link>
							{!isLastItem && (
								<div
									className={
										cl.breadcrumbs__sp + ' ' + cl[`breadcrumbs__sp_${theme}`]
									}
								></div>
							)}
						</span>
					);
				}

				return (
					<div
						className={
							cl.breadcrumbs__item + ' ' + cl[`breadcrumbs__item_${theme}`]
						}
						key={index}
					>
						{item.translate === true ? t(item.name) : item.name}
					</div>
				);
			})}
		</div>
	);
};
