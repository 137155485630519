import { Langs } from '../../@types';

export const LANG_LIST = [
	{
		title: 'EN',
		lang: Langs.en,
	},
	{
		title: 'RU',
		lang: Langs.ru,
	},
];
