import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AsideOrder } from '../../components/aside/aside-order/aside-order.component';
import { CardCart } from '../../components/card/card-cart/card-cart.component';
import { useCart } from '../../components/cart/cart.context';
import { FormOrder } from '../../components/forms/form-order/form-order.component';
import { useFormOrderLogic } from '../../components/forms/form-order/form-order.logic';
import ContentLayout from '../../components/layout/content-layout/content-layout.component';
import { PageLayout } from '../../components/layout/page.layout';
import { Breadcrumbs } from '../../components/ui/breadcrumbs/breadcrumbs.component';
import cl from './index.module.scss';

const OrderPage = () => {
	const { products } = useCart();
	const { t } = useTranslation();
	const { formik, deliveryType, setDeliveryType, isDisabled } =
		useFormOrderLogic();
	const refSubmit = useRef<HTMLButtonElement | null>(null);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, []);

	const isDisabledBtn = useMemo(() => {
		return products.length === 0 ? true : isDisabled;
	}, [isDisabled, products]);

	return (
		<PageLayout positionHeader="fixed">
			<div className={cl.page}>
				<div className={'header-offset'} />
				<div className="container">
					<div className={'box20'}></div>
					<Breadcrumbs items={[{ name: 'page.order', translate: true }]} />
					<h1 className={'title-section-small ' + cl.title}>
						{t('page.order')}
					</h1>
					<ContentLayout
						aside={
							<AsideOrder
								address={formik.values.address}
								refSubmit={refSubmit}
								deliveryType={deliveryType}
								isDisabled={isDisabledBtn}
							/>
						}
						marginTop={false}
						small
						classes={{ cl_aside: cl.aside, cl_root: cl.root }}
					>
						<>
							<FormOrder
								refSubmit={refSubmit}
								formik={formik}
								onChangeDeliveryType={setDeliveryType}
								deliveryType={deliveryType}
							/>
							<ul className={cl.cart__list}>
								{products.map((item, index) => (
									<li key={index}>
										<CardCart data={item} />
									</li>
								))}
							</ul>
						</>
					</ContentLayout>
				</div>
			</div>
		</PageLayout>
	);
};

export default OrderPage;
