import { t } from 'i18next';
import * as Yup from 'yup';

const ERRORS = {
	req: t('error.req'),
	phone: t('error.phone'),
	min: t('error.min'),
	max: t('error.max'),
	email: t('error.email'),
	confirm: t('error.confirm'),
};

const getExpectedPhoneLength = (phoneFormat: string) => {
	return phoneFormat.replace(/\+/g, '').replace(/[^.]/g, '').length;
};

const MAX_LEN_FIELD = 150;

export const profileEditFormSchema = Yup.object().shape({
	name: Yup.string()
		.required(ERRORS.req)
		.min(2, ERRORS.min)
		.max(MAX_LEN_FIELD, ERRORS.max),
	email: Yup.string()
		.required(ERRORS.req)
		.email(ERRORS.email)
		.max(MAX_LEN_FIELD, ERRORS.max),
	phone: Yup.string()
		.required(ERRORS.req)
		.test('valid-phone', ERRORS.phone, function (value) {
			const { country, phone } = this.parent; // Берем данные страны и телефона из контекста формы
			const expectedLength = getExpectedPhoneLength(country?.format || ''); // Ожидаемая длина по формату

			// Убираем все символы, кроме цифр, чтобы сравнивать длину номера
			const numericPhone = phone?.replace(/\D/g, '') || '';

			if (numericPhone.length !== expectedLength) {
				return this.createError({ message: ERRORS.phone });
			}

			return true;
		})
		.max(MAX_LEN_FIELD, ERRORS.max),
	country: Yup.object().shape({
		name: Yup.string().required(ERRORS.req),
		dialCode: Yup.string().required(ERRORS.req),
		countryCode: Yup.string().required(ERRORS.req),
		format: Yup.string().required(ERRORS.req),
	}),
});

export type ProfileEditFormData = Yup.InferType<typeof profileEditFormSchema>;
