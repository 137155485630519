import { useEffect, useId, useState } from 'react';
import MaskedInput from 'react-text-mask';
import { IInputMaskProps } from './input.model';
import './input.scss';

export const InputMaskField = ({
	value: valueProps,
	label,
	type = 'text',
	id,
	placeholder = '',
	onChange,
	onSubmit,
	classes = {},
	error: errorProps,
	validationOnChange,
	min,
	max,
	endAdornment,
	startAdornment,
	numberOff = false,
	placeholderMask = false,
	theme = 'default',
	...props
}: IInputMaskProps) => {
	const isError = errorProps !== undefined ? true : false;
	const id_field = useId();
	const [isActivePlaceholder, setActivePlaceholder] = useState(placeholderMask);

	useEffect(() => {
		if (valueProps !== '' && placeholderMask) {
			setActivePlaceholder(false);
		} else if (valueProps === '' && placeholderMask) {
			setActivePlaceholder(true);
		}
	}, [placeholderMask, valueProps]);

	return (
		<div className={`input-form-control ${classes?.root || ''}`}>
			{label && (
				<label className="input-form-control__label" htmlFor={id || id_field}>
					{label}
				</label>
			)}
			<div
				className={`input-form-control__wrapper-input ${
					isError ? 'error' : ''
				}`}
			>
				{startAdornment && !isActivePlaceholder && (
					<div
						className={`input-form-control-adornment input-form-control-adornment__start ${
							classes?.startAdornment || ''
						} ${valueProps !== '' ? ' active' : ''}`}
					>
						{startAdornment}
					</div>
				)}
				<MaskedInput
					className={
						`input-form-control__input ${theme} ` + classes.input || ''
					}
					onChange={onChange}
					value={valueProps}
					placeholder={placeholder}
					type={type}
					id={id || id_field}
					{...props}
				/>
				{endAdornment && (
					<div
						className={`input-form-control-adornment input-form-control-adornment__end ${
							classes?.endAdornment || ''
						}`}
					>
						{endAdornment}
					</div>
				)}
			</div>
			<div className={`input-form-control__error ${isError ? 'error' : ''}`}>
				{errorProps || ''}
			</div>
		</div>
	);
};
