import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RoutsPath } from '../../../@types';
import { Button } from '../../ui/buttons/button/button.component';
import { Specification } from '../../ui/specification/specification.component';
import { useAuth } from '../../../application/application.auth.context';
import { useCart } from '../../cart/cart.context';
import { ModalAuthContext } from '../../modals/modal-auth/modal-auth.context';
import { CURRENCY } from '../../../const/settings.const';
import cl from './aside-cart.module.scss';

export const AsideCart = () => {
	const { toggleOpen } = useContext(ModalAuthContext);
	const navigation = useNavigate();
	const { isAuth } = useAuth();
	const { productsCount, totalPrice } = useCart();
	const { t } = useTranslation();

	return (
		<div className={cl.card}>
			<div className={cl.header}>
				<div className={cl.title}>{t('total')}</div>
			</div>
			<div className={cl.body}>
				<Specification
					items={[
						{
							label: t('count.products'),
							value: productsCount.toString(),
						},
						{
							label: t('total.price'),
							value: CURRENCY + totalPrice,
						},
					]}
				/>
			</div>
			<div className={cl.actions}>
				<div className={cl.hr_top + ' hr ' + cl.hr} />
				{!isAuth && (
					<Button
						theme="outline"
						className={cl.btn + ' ' + cl.mg15}
						onClick={() => toggleOpen(true)}
					>
						{t('label.singin')}
					</Button>
				)}

				<Button
					disabled={!isAuth || productsCount === 0}
					className={cl.btn}
					onClick={() => navigation(RoutsPath.order)}
				>
					<>
						{!isAuth || productsCount === 0
							? t('label.pay') + ` Dh${totalPrice}`
							: t('label.great')}
					</>
				</Button>
			</div>
		</div>
	);
};
