import { useQuery } from '@tanstack/react-query';
import { createContext, useContext, useState } from 'react';
import { IOptionsData, MasterListData } from '../@types';
import { apiServices } from '../services/api';

interface IStoreApplication {
	store_options: {
		state: {
			options: IOptionsData | undefined | null;
			isLoading: boolean;
			error: Error | null;
		};
	};
	store_masters: {
		state: {
			masters: MasterListData | undefined | null;
			isLoading: boolean;
			error: Error | null;
			filters: { limit: number; offset: number };
		};
		actions: {
			setFilterMasters: ({
				limit,
				offset,
			}: {
				limit: number;
				offset: number;
			}) => void;
		};
	};
}

const INIT_CONTEXT: IStoreApplication = {
	store_options: {
		state: {
			options: undefined,
			isLoading: true,
			error: null,
		},
	},
	store_masters: {
		state: {
			masters: null,
			isLoading: true,
			error: null,
			filters: { limit: 20, offset: 0 },
		},
		actions: {
			setFilterMasters: ({
				limit,
				offset,
			}: {
				limit: number;
				offset: number;
			}) => void 0,
		},
	},
};

const useOptions = () => {
	return useQuery({
		queryKey: ['options'],
		queryFn: async () => {
			return await apiServices.getOptions();
		},
	});
};

const useMasters = ({
	limit = 20,
	offset = 0,
}: {
	limit: number;
	offset: number;
}) => {
	return useQuery({
		queryKey: [limit, offset, 'masters'],
		queryFn: async () => {
			return await apiServices.getMasters({ limit, offset });
		},
	});
};

export const ApplicationContext =
	createContext<IStoreApplication>(INIT_CONTEXT);

export const ApplicationContextProvider = ({
	children,
}: {
	children?: JSX.Element[] | JSX.Element;
}) => {
	const [filterMasters, setFilterMasters] = useState<{
		limit: number;
		offset: number;
	}>(INIT_CONTEXT.store_masters.state.filters);

	const {
		data: dataOptions,
		isLoading: isLoadingOptions,
		error: errorOptions,
	} = useOptions();

	const {
		data: dataMasters,
		isLoading: isLoadingMasters,
		error: errorMasters,
	} = useMasters(filterMasters);

	const contextValue: IStoreApplication = {
		store_options: {
			state: {
				options: dataOptions,
				isLoading: isLoadingOptions,
				error: errorOptions,
			},
		},
		store_masters: {
			state: {
				masters: dataMasters,
				isLoading: isLoadingMasters,
				error: errorMasters,
				filters: filterMasters,
			},
			actions: {
				setFilterMasters,
			},
		},
	};

	return (
		<ApplicationContext.Provider value={contextValue}>
			{children}
		</ApplicationContext.Provider>
	);
};

export const useApplication = () => {
	const { store_options, store_masters } = useContext(ApplicationContext);
	return { store_options, store_masters };
};
