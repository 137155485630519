import { useMutation } from '@tanstack/react-query';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { IUserStore } from '../@types';
import { TOKEN_ACCESS, TOKEN_REFRESH } from '../const/auth.const';
import { apiServices } from '../services/api';

interface IAuthContext {
	user: IUserStore;
	updateAuthData: (data: IUserStore) => void;
	logout: () => void;
	isLoading: boolean;
}

export const defaultValueAuth = {
	user: { isAuth: false, data: null },
	updateAuthData: (data: IUserStore) => void 0,
	logout: () => void 0,
	isLoading: true,
};

export const AuthContext = createContext<IAuthContext>(defaultValueAuth);

export const useAuth = () => {
	const { user, logout, isLoading, updateAuthData } = useContext(AuthContext);
	const isAuth = useMemo(() => user.isAuth, [user.isAuth]);
	const userMemo = useMemo(() => user.data, [user]);
	const isLoadingGet = useMemo(() => isLoading, [isLoading]);

	return {
		isAuth,
		user: userMemo,
		updateAuthData,
		logout,
		isLoading: isLoadingGet,
	};
};

const AuthProvider = ({ children }: { children: JSX.Element }) => {
	const mutation = useMutation({
		mutationFn: () => {
			return apiServices.getUserInfo();
		},
	});
	const [authData, setAuthData] = useState<IUserStore>(defaultValueAuth.user);
	const [isLoading, setLoading] = useState(defaultValueAuth.isLoading);

	const updateAuthData = (data: IUserStore) => {
		setAuthData((prev) => ({ ...prev, ...data }));
	};

	const clear = () => {
		localStorage.removeItem(TOKEN_ACCESS);
		localStorage.removeItem(TOKEN_REFRESH);
		updateAuthData({ isAuth: false, data: null });
		setLoading(false);
		window.location.replace(window.location.origin);
	};

	useEffect(() => {
		if (authData.isAuth && !mutation.isPending && authData.data === null) {
			mutation.mutate();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authData.isAuth]);

	useEffect(() => {
		if (
			mutation.isSuccess &&
			!mutation.isPending &&
			mutation.data &&
			authData.data === null
		) {
			updateAuthData({ isAuth: true, data: mutation.data });
			setLoading(false);
		}
	}, [mutation.data, mutation.isSuccess, mutation.isPending, authData.data]);

	const getAuthData = async () => {
		try {
			setLoading(true);
			await apiServices.refreshAccessToken();
			updateAuthData({
				isAuth: true,
				data: null,
			});
		} catch (e) {
			clear();
		}
	};

	useEffect(() => {
		const accessToken = localStorage.getItem(TOKEN_ACCESS);
		const refreshToken = localStorage.getItem(TOKEN_REFRESH);

		if (accessToken !== null && refreshToken !== null) {
			getAuthData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<AuthContext.Provider
			value={{
				user: authData,
				updateAuthData,
				logout: clear,
				isLoading,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthProvider;
