import cl from './text-block.module.scss';

export const TextBlock = ({
	title,
	description,
	classes,
}: {
	title: string;
	description?: string;
	classes?: { root?: string; title?: string; description?: string };
}) => {
	const { root, title: clTitle, description: clDescription } = classes || {};
	return (
		<div className={cl.container + ' ' + root}>
			<h2 className={cl.title + ' ' + clTitle}>{title}</h2>
			{description && (
				<p className={cl.desc + ' ' + clDescription}>{description}</p>
			)}
		</div>
	);
};
