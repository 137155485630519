import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Langs } from 'src/@types';
import { PageLayout } from '../../components/layout/page.layout';
import { Breadcrumbs } from '../../components/ui/breadcrumbs/breadcrumbs.component';
import { ButtonGroup } from '../../components/ui/button-group/button-group.component';
import cl from './index.module.scss';
import { MOCK_PRIVACY_POLICY } from './mock';

const PrivacyPolicyPage = () => {
	const { t, i18n } = useTranslation();

	const [tab, setTab] = useState(0);

	const onChange = (e: React.MouseEvent<HTMLButtonElement>) => {
		const btn = e.currentTarget;
		const data = parseInt(btn.dataset.value || '0') || 0;
		setTab(data);
	};

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, []);

	return (
		<PageLayout positionHeader="fixed">
			<div className={cl.page}>
				<div className={'header-offset'} />
				<div className="container">
					<div className={'box20'}></div>
					<Breadcrumbs items={[{ name: 'menu.privacy', translate: true }]} />
					<h1 className={'title-section-small ' + cl.title}>
						{tab === 0 ? t('page.privacy') : t('page.consent')}
					</h1>
					<div className={cl.privacy__container}>
						<ButtonGroup
							columnOff
							active={tab.toString()}
							buttons={[
								{
									label: t('filter.privacy'),
									data: '0',
									onClick: onChange,
								},
								{
									label: t('filter.consent'),
									data: '1',
									onClick: onChange,
								},
							]}
						/>
						<div
							className={cl.privacy__content}
							dangerouslySetInnerHTML={{
								__html: MOCK_PRIVACY_POLICY[i18n.language as Langs][tab],
							}}
						/>
					</div>
				</div>
			</div>
		</PageLayout>
	);
};

export default PrivacyPolicyPage;
