import { Suspense, createContext, lazy, useState } from 'react';
import FormAuthContextProvider from '../../forms/form-auth/form-auth.context';

const Modal = lazy(() => import('./modal-auth.component'));

export const ModalAuthContext = createContext<{
	open: boolean;
	toggleOpen: (open: boolean) => void;
}>({
	open: false,
	toggleOpen: (open: boolean) => void 0,
});

const ModalAuthContextProvider = ({
	children,
}: {
	children: JSX.Element[] | JSX.Element;
}) => {
	const [open, setOpen] = useState<boolean>(false);

	const contextValue = { open, toggleOpen: setOpen };
	return (
		<ModalAuthContext.Provider value={contextValue}>
			<FormAuthContextProvider>
				<>{children}</>
				<Suspense fallback={<div />}>
					<Modal />
				</Suspense>
			</FormAuthContextProvider>
		</ModalAuthContext.Provider>
	);
};

export default ModalAuthContextProvider;
