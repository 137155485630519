import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useEffect } from 'react';
import { CartContextProvider } from '../components/cart/cart.context';
import ModalAuthContextProvider from '../components/modals/modal-auth/modal-auth.context';
import { PagesLoaderContextProvider } from 'src/components/loader/loader.context';
import ScheduleFiltersProvider from 'src/components/schedule/schedule-filters/schedule-filters.context';
import AuthProvider from './application.auth.context';
import { ApplicationContextProvider } from './application.context';
import { useWindowLoader } from 'src/hooks/useWindowLoader';
import '../styles/index.scss';
import { ApplicationRouter } from './application.router';

export const queryClient = new QueryClient();

function App() {
	const { hideLoader } = useWindowLoader();

	useEffect(() => {
		if (
			window.location.pathname !== '/' &&
			window.location.pathname !== '/ru'
		) {
			hideLoader();
		}
	}, []);

	return (
		<div className="App">
			<QueryClientProvider client={queryClient}>
				<PagesLoaderContextProvider>
					<GoogleOAuthProvider
						clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
					>
						<AuthProvider>
							<ApplicationContextProvider>
								<ScheduleFiltersProvider>
									<CartContextProvider>
										<ModalAuthContextProvider>
											<ApplicationRouter />
										</ModalAuthContextProvider>
									</CartContextProvider>
								</ScheduleFiltersProvider>
							</ApplicationContextProvider>
						</AuthProvider>
					</GoogleOAuthProvider>
				</PagesLoaderContextProvider>
			</QueryClientProvider>
		</div>
	);
}

export default App;
