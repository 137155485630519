import { ISvgIconProps } from '.';

export const FacebookIcon = ({
	className = '',
	viewBox = '0 0 26 26',
	refComponent = undefined,
}: ISvgIconProps) => {
	return (
		<svg
			className={className}
			ref={refComponent}
			viewBox={viewBox}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M18.2195 15.3267L18.8397 11.1593H15.1254V8.2436C15.1254 7.07433 15.5157 6.1599 17.216 6.1599H19V2.32979C17.9965 2.1649 16.9094 2 15.9059 2C12.7282 2 10.4843 4.0837 10.4843 7.83136V11.1593H7V15.3267H10.4843V26H15.1185V15.3267H18.2195Z"
				fill="#C2D0FB"
			/>
		</svg>
	);
};
