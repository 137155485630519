import { FacebookIcon, InstaIcon, TgIcon, WtIcon } from '../ui/icons';
import './socials.scss';

export const SocialsMaster = ({
	className = '',
	theme = 'default',
	links,
}: {
	links: {
		fb: string;
		insta: string;
	};
	className?: string;
	theme?: 'default' | 'user';
}) => {
	return (
		<div className={`socials ${className}`}>
			<a
				href={links.fb}
				rel="noindex, nofollow"
				target="_balank"
				className={'round-paper-sad socials__item socials__item_' + theme}
			>
				<FacebookIcon />
			</a>
			<a
				href={links.insta}
				rel="noindex, nofollow"
				target="_balank"
				className={'round-paper-sad socials__item socials__item_' + theme}
			>
				<InstaIcon />
			</a>
		</div>
	);
};

export const Socials = ({
	className = '',
	theme = 'default',
	insta = true,
	wt = true,
	big,
	links,
}: {
	className?: string;
	theme?: 'default' | 'user';
	insta?: boolean;
	wt?: boolean;
	big?: boolean;
	links: string[];
}) => {
	return (
		<div className={`socials ${className}`}>
			<a
				href={links[0]}
				rel="noindex, nofollow"
				target="_blank"
				className={`round-paper-sad  socials__item ${'socials__item_' + theme} ${big ? 'big' : ''}`}
			>
				<TgIcon />
			</a>
			{wt && (
				<a
					href={links[1]}
					rel="noindex, nofollow"
					target="_blank"
					className={`round-paper-sad  socials__item ${'socials__item_' + theme} ${big ? 'big' : ''}`}
				>
					<WtIcon />
				</a>
			)}

			{insta && (
				<a
					href={links[2]}
					rel="noindex, nofollow"
					target="_blank"
					className={`round-paper-sad  socials__item ${'socials__item_' + theme} ${big ? 'big' : ''}`}
				>
					<InstaIcon />
				</a>
			)}
		</div>
	);
};
