import { useEffect, useState } from 'react';

export const useHeaderVisible = () => {
	const [position, setPosition] = useState(0);
	const [visible, setVisible] = useState(true);
	const [lastScrollTime, setLastScrollTime] = useState(0);

	useEffect(() => {
		const handleScroll = () => {
			const currentTime = Date.now();
			const moving = window.pageYOffset;

			if (moving === 0) {
				setVisible(true);
			} else {
				if (currentTime - lastScrollTime > 100) {
					if (moving <= 50) {
						setVisible(true); // Всегда показывать хедер при скролле в самый верх
					} else {
						setVisible(position > moving);
					}
					setPosition(moving);
					setLastScrollTime(currentTime);
				}
			}
		};

		window.addEventListener('scroll', handleScroll, { passive: true });
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [position, lastScrollTime]);

	return {
		visible,
		visibleType: visible ? 'visible' : 'hidden',
	};
};
