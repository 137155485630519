export const localization = {
	af: 'Афганистан',
	al: 'Албания',
	dz: 'Алжир',
	as: 'Американское Самоа',
	ad: 'Андорра',
	ao: 'Ангола',
	ai: 'Ангилья',
	ag: 'Антигуа и Барбуда',
	ar: 'Аргентина',
	am: 'Армения',
	aw: 'Аруба',
	au: 'Австралия',
	at: 'Австрия',
	az: 'Азербайджан',
	bs: 'Багамы',
	bh: 'Бахрейн',
	bd: 'Бангладеш',
	bb: 'Барбадос',
	by: 'Беларусь',
	be: 'Бельгия',
	bz: 'Белиз',
	bj: 'Бенин',
	bm: 'Бермуды',
	bt: 'Бутан',
	bo: 'Боливия',
	ba: 'Босния и Герцеговина',
	bw: 'Ботсвана',
	br: 'Бразилия',
	io: 'Британская территория в Индийском океане',
	vg: 'Британские Виргинские острова',
	bn: 'Бруней',
	bg: 'Болгария',
	bf: 'Буркина-Фасо',
	bi: 'Бурунди',
	kh: 'Камбоджа',
	cm: 'Камерун',
	ca: 'Канада',
	cv: 'Кабо-Верде',
	ky: 'Каймановы острова',
	cf: 'Центральноафриканская Республика',
	td: 'Чад',
	cl: 'Чили',
	cn: 'Китай',
	cx: 'Остров Рождества',
	cc: 'Кокосовые острова',
	co: 'Колумбия',
	km: 'Коморские Острова',
	cd: 'Конго (Киншаса)',
	cg: 'Конго (Браззавиль)',
	ck: 'Острова Кука',
	cr: 'Коста-Рика',
	hr: 'Хорватия',
	cu: 'Куба',
	cy: 'Кипр',
	cz: 'Чехия',
	dk: 'Дания',
	dj: 'Джибути',
	dm: 'Доминика',
	do: 'Доминиканская Республика',
	ec: 'Эквадор',
	eg: 'Египет',
	sv: 'Сальвадор',
	gq: 'Экваториальная Гвинея',
	er: 'Эритрея',
	ee: 'Эстония',
	et: 'Эфиопия',
	fk: 'Фолклендские острова',
	fo: 'Фарерские острова',
	fj: 'Фиджи',
	fi: 'Финляндия',
	fr: 'Франция',
	gf: 'Французская Гвиана',
	pf: 'Французская Полинезия',
	ga: 'Габон',
	gm: 'Гамбия',
	ge: 'Грузия',
	de: 'Германия',
	gh: 'Гана',
	gi: 'Гибралтар',
	gr: 'Греция',
	gl: 'Гренландия',
	gd: 'Гренада',
	gp: 'Гваделупа',
	gu: 'Гуам',
	gt: 'Гватемала',
	gg: 'Гернси',
	gn: 'Гвинея',
	gw: 'Гвинея-Бисау',
	gy: 'Гайана',
	ht: 'Гаити',
	hn: 'Гондурас',
	hk: 'Гонконг',
	hu: 'Венгрия',
	is: 'Исландия',
	in: 'Индия',
	id: 'Индонезия',
	ir: 'Иран',
	iq: 'Ирак',
	ie: 'Ирландия',
	im: 'Остров Мэн',
	il: 'Израиль',
	it: 'Италия',
	ci: 'Кот-д’Ивуар',
	jm: 'Ямайка',
	jp: 'Япония',
	je: 'Джерси',
	jo: 'Иордания',
	kz: 'Казахстан',
	ke: 'Кения',
	ki: 'Кирибати',
	xk: 'Косово',
	kw: 'Кувейт',
	kg: 'Киргизия',
	la: 'Лаос',
	lv: 'Латвия',
	lb: 'Ливан',
	ls: 'Лесото',
	lr: 'Либерия',
	ly: 'Ливия',
	li: 'Лихтенштейн',
	lt: 'Литва',
	lu: 'Люксембург',
	mo: 'Макао',
	mk: 'Северная Македония',
	mg: 'Мадагаскар',
	mw: 'Малави',
	my: 'Малайзия',
	mv: 'Мальдивы',
	ml: 'Мали',
	mt: 'Мальта',
	mh: 'Маршалловы острова',
	mq: 'Мартиника',
	mr: 'Мавритания',
	mu: 'Маврикий',
	yt: 'Майотта',
	mx: 'Мексика',
	fm: 'Федеративные Штаты Микронезии',
	md: 'Молдова',
	mc: 'Монако',
	mn: 'Монголия',
	me: 'Черногория',
	ms: 'Монтсеррат',
	ma: 'Марокко',
	mz: 'Мозамбик',
	mm: 'Мьянма',
	na: 'Намибия',
	nr: 'Науру',
	np: 'Непал',
	nl: 'Нидерланды',
	nc: 'Новая Каледония',
	nz: 'Новая Зеландия',
	ni: 'Никарагуа',
	ne: 'Нигер',
	ng: 'Нигерия',
	nu: 'Ниуэ',
	nf: 'Остров Норфолк',
	kp: 'Северная Корея',
	mp: 'Северные Марианские Острова',
	no: 'Норвегия',
	om: 'Оман',
	pk: 'Пакистан',
	pw: 'Палау',
	ps: 'Палестинские территории',
	pa: 'Панама',
	pg: 'Папуа - Новая Гвинея',
	py: 'Парагвай',
	pe: 'Перу',
	ph: 'Филиппины',
	pn: 'Питкэрн',
	pl: 'Польша',
	pt: 'Португалия',
	pr: 'Пуэрто-Рико',
	qa: 'Катар',
	re: 'Реюньон',
	ro: 'Румыния',
	ru: 'Россия',
	rw: 'Руанда',
	bl: 'Сен-Бартельми',
	sh: 'Остров Святой Елены',
	kn: 'Сент-Китс и Невис',
	lc: 'Сент-Люсия',
	mf: 'Сен-Мартен',
	pm: 'Сен-Пьер и Микелон',
	vc: 'Сент-Винсент и Гренадины',
	ws: 'Самоа',
	sm: 'Сан-Марино',
	st: 'Сан-Томе и Принсипи',
	sa: 'Саудовская Аравия',
	sn: 'Сенегал',
	rs: 'Сербия',
	sc: 'Сейшельские Острова',
	sl: 'Сьерра-Леоне',
	sg: 'Сингапур',
	sx: 'Синт-Мартен',
	sk: 'Словакия',
	si: 'Словения',
	sb: 'Соломоновы острова',
	so: 'Сомали',
	za: 'Южно-Африканская Республика',
	kr: 'Южная Корея',
	ss: 'Южный Судан',
	es: 'Испания',
	lk: 'Шри-Ланка',
	sd: 'Судан',
	sr: 'Суринам',
	sj: 'Шпицберген и Ян-Майен',
	sz: 'Эсватини',
	se: 'Швеция',
	ch: 'Швейцария',
	sy: 'Сирия',
	tw: 'Тайвань',
	tj: 'Таджикистан',
	tz: 'Танзания',
	th: 'Таиланд',
	tl: 'Восточный Тимор',
	tg: 'Того',
	tk: 'Токелау',
	to: 'Тонга',
	tt: 'Тринидад и Тобаго',
	tn: 'Тунис',
	tr: 'Турция',
	tm: 'Туркмения',
	tc: 'Теркс и Кайкос',
	tv: 'Тувалу',
	ug: 'Уганда',
	ua: 'Украина',
	ae: 'Объединенные Арабские Эмираты',
	gb: 'Великобритания',
	us: 'Соединенные Штаты',
	uy: 'Уругвай',
	uz: 'Узбекистан',
	vu: 'Вануату',
	va: 'Ватикан',
	ve: 'Венесуэла',
	vn: 'Вьетнам',
	wf: 'Уоллис и Футуна',
	eh: 'Западная Сахара',
	ye: 'Йемен',
	zm: 'Замбия',
	zw: 'Зимбабве',
	ax: 'Аландские острова',
};
