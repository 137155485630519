import { useId } from 'react';
import { IModalProps } from './modal.model';
import useBodyClass from '../../../hooks/useBodyClass';
import useBodyScrollLock from '../../../hooks/useBodyScrollLock';
import './modal.scss';
import { CloseIcon } from '../icons';
import { PortalWrapper } from '../portal-wrapper/portal-wrapper.container';

const Modal = ({
	children,
	open,
	onClose,
	align = 'center',
	animate = 'left',
	classes,
	wrapperId,
	portal = true,
	closeIcon,
}: IModalProps) => {
	const modalId = useId();
	useBodyClass('is-modal-open', open);
	useBodyScrollLock(open);

	const content = () => (
		<div
			className={`modal modal_${align} ${classes?.root || ''} modal_${open ? 'active' : 'unactive'} ${portal ? '' : 'portal'}`}
		>
			<div
				className={`modal-overlay ${classes?.overlay || ''}`}
				onClick={onClose}
			></div>
			<div
				className={`modal-content ${
					classes?.content || ''
				} modal-content__animate-${animate}`}
			>
				<div
					className={`modal-close ${classes?.closeWrapper || ''}`}
					onClick={onClose}
				>
					{closeIcon || <CloseIcon />}
				</div>
				{children}
			</div>
		</div>
	);
	return portal ? (
		<PortalWrapper wrapperId={wrapperId || modalId}>{content()}</PortalWrapper>
	) : (
		content()
	);
};

export default Modal;
