import SuccessModalContentComponent from '../../modals-content/success-modal-content/success-modal-content.component';
import Modal from '../../ui/modal/modal.component';
import { CloseModalIcon } from '../../ui/icons';
import cl from './modal-success.module.scss';

const ModalSuccessComponent = ({
	open,
	onClose,
	type = 'registration',
}: {
	open: boolean;
	onClose: () => void;
	type: 'registration' | 'profileSave' | 'order';
}) => {
	return (
		<Modal
			open={open}
			onClose={onClose}
			animate="fade"
			classes={{
				content: cl.content,
			}}
			closeIcon={<CloseModalIcon />}
			portal
		>
			<SuccessModalContentComponent type={type} handleClose={onClose} />
		</Modal>
	);
};

export default ModalSuccessComponent;
