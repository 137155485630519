import { ICartData, ICartProduct, IProductDetail } from '../../@types';

export class DtoCartData implements ICartData {
	product: number;
	amount: number;
	color: number | null | undefined;
	size: number | null | undefined;

	constructor(model: { [key: string]: any }) {
		this.product = model['product']['id'] || -1;
		this.amount = model['amount'] || -1;
		this.color = model['color'] || null;
		this.size = model['size'] || null;
	}

	toJSON(): ICartData {
		const result = {
			product: this.product,
			amount: this.amount,
			color: this.color,
			size: this.size,
		};
		if (result['size'] === null || result['size'] === undefined) {
			delete result['size'];
		}
		if (result['color'] === null || result['color'] === undefined) {
			delete result['color'];
		}
		return result;
	}
}

export class DtoCartProduct implements ICartProduct {
	id: number;
	name: string;
	main_image:
		| {
				id: number;
				image: string;
				order: number;
				is_cover: boolean;
				is_active: boolean;
				color: {
					id: number;
					name: string;
					html_code: string;
					order: number;
				};
		  }
		| undefined;
	price: string;

	constructor(model: IProductDetail) {
		this.id = model['id'];
		this.name = model?.name || '';
		this.price = model.price;
		this.main_image = model?.main_image || undefined;
	}

	toJSON(): ICartProduct {
		return {
			id: this.id,
			name: this.name,
			price: this.price,
			main_image: this.main_image,
		};
	}
}
