import { useTranslation } from 'react-i18next';
import { IMasterData, Langs } from '../../@types';
import cl from './masters.module.scss';

interface MastersProps {
	masters: IMasterData[];
	onClickMaster: (master: IMasterData) => void;
}

const Masters = ({
	masters,
	onClickMaster,
}: MastersProps) => {
	const { i18n, t } = useTranslation();

	const handleMasterClick = (master: IMasterData) => () => {
		onClickMaster(master);
	};

	return (
		<div className={cl.masters}>
			<div className={cl.header}>
				{masters.length === 1 && i18n.language === Langs.ru
					? t('label.master').slice(0, -1)
					: t('label.master')}
			</div>
			<ul className={cl.masters__list}>
				{masters.map((item, index) => (
					<li
						key={index}
						className={cl.masters__list__item}
						onClick={handleMasterClick(item)}
					>
						<img
							src={item.image}
							alt={item.name}
							loading="lazy"
							className={cl.avatar}
						/>
						<p className={cl.name}>{item.name}</p>
					</li>
				))}
			</ul>
		</div>
	);
};

export default Masters;
