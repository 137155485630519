import cl from './counter.module.scss';

export const Counter = ({
	amount,
	onAdd,
	onSub,
	disabled = false,
}: {
	amount: number;
	onAdd: () => void;
	onSub: () => void;
	disabled?: boolean;
}) => {
	return (
		<div className={cl.counter}>
			<button onClick={onSub} className={cl.counter__btn} disabled={disabled}>
				<svg
					width="21"
					height="20"
					viewBox="0 0 21 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<rect
						x="0.199219"
						width="20"
						height="20"
						rx="10"
						fill="#C2D0FB"
						fillOpacity="0.1"
					/>
					<path
						d="M6.19922 10H14.1992"
						stroke="#EBE9F0"
						strokeOpacity="0.6"
						strokeWidth="2"
						strokeLinecap="square"
					/>
				</svg>
			</button>
			<div className={cl.counter__value}>{amount}</div>
			<button onClick={onAdd} className={cl.counter__btn} disabled={disabled}>
				<svg
					width="21"
					height="20"
					viewBox="0 0 21 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<rect
						x="0.199219"
						width="20"
						height="20"
						rx="10"
						fill="#C2D0FB"
						fillOpacity="0.1"
					/>
					<path
						d="M6.19922 10H14.1992M10.1992 6V14"
						stroke="#EBE9F0"
						strokeOpacity="0.6"
						strokeWidth="2"
						strokeLinecap="square"
					/>
				</svg>
			</button>
		</div>
	);
};
