import { useTranslation } from 'react-i18next';
import { ICardOrderProps } from './card-order.model';
import { CURRENCY } from 'src/const/settings.const';
import { dateFnsParse, reverseDate } from '../../../utils/date.utils';
import cl from './card-order.module.scss';

export const CardOrder = ({
	className = '',
	data,
	onCancel,
}: ICardOrderProps) => {
	const { t, i18n } = useTranslation();
	const { week, day, month } = dateFnsParse(
		reverseDate(data.date),
		i18n.language,
	);

	return (
		<div className={cl.card + ' ' + className}>
			<div className={cl.row}>
				<div className={cl.name}>
					<h4 className={cl.title}>{data.title}</h4>
					<time className={cl.date} dateTime={data.date}>
						{week}, {day} {month}
					</time>
				</div>
				<div className={cl.count}>
					<span className={cl.count__value}>
						{data.count}
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="17"
							height="16"
							viewBox="0 0 17 16"
							fill="none"
						>
							<path
								d="M4.5 6L8.50001 10L12.5 6"
								stroke="#EBE9F0"
								strokeOpacity="0.6"
								strokeWidth="1.5"
							/>
						</svg>
					</span>
					<span className={cl.count__title}>{t('label.products')}</span>
					<ul className={cl.cout__list}>
						<li className={cl.cout__list__item}>
							<img src="/image/products/1.png" alt="" />
							<p>Glucophone Astral Am3</p>
							<span
								className={cl.color}
								style={{ background: '#83584D' }}
							></span>
							<span>30cm</span>
							<span>{CURRENCY} 320,2</span>
						</li>
						<li className={cl.cout__list__item}>
							<img src="/image/products/1.png" alt="" />
							<p>Glucophone Astral Am3</p>
							<span
								className={cl.color}
								style={{ background: '#83584D' }}
							></span>
							<span>30cm</span>
							<span>{CURRENCY} 320,2</span>
						</li>
						<li className={cl.cout__list__item}>
							<img src="/image/products/1.png" alt="" />
							<p>Glucophone Astral Am3</p>
							<span
								className={cl.color}
								style={{ background: '#83584D' }}
							></span>
							<span>30cm</span>
							<span>{CURRENCY} 320,2</span>
						</li>
					</ul>
				</div>
				<div className={cl.position}>
					<span className={cl.position__chip} data-lang={i18n.language}>
						{data.position}
					</span>
				</div>
				<div className={cl.payment}>
					<span className={cl.payment__chip + ' ' + [cl[data.statusPayment]]}>
						{data.statusPaymentTitle}
					</span>
				</div>
				<div className={cl.order}>
					<span
						className={cl.order__chip + ' ' + [cl[data.status]]}
						data-status={data.status}
						data-lang={i18n.language}
					>
						{data.statusTitle}
					</span>
				</div>
				<div className={cl.price}>
					{CURRENCY} {data.price}
				</div>
				{onCancel && (
					<div
						className={cl.icon}
						role="button"
						onClick={() => onCancel(data.id.toString())}
					>
						<svg
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<rect
								width="20"
								height="20"
								rx="10"
								fill="rgba(184, 14, 146)"
								fillOpacity="0.2"
							/>
							<path
								d="M6 14L13.9997 6.00025"
								stroke="white"
								strokeWidth="2"
								strokeLinecap="square"
							/>
							<path
								d="M6 6L13.9997 13.9997"
								stroke="white"
								strokeWidth="2"
								strokeLinecap="square"
							/>
						</svg>
					</div>
				)}
			</div>
		</div>
	);
};
