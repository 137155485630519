import { ISvgIconProps } from '.';

export const CalendarIcon = ({
	className = '',
	viewBox = '0 0 15 16',
	refComponent = undefined,
}: ISvgIconProps) => {
	return (
		<svg
			className={className}
			ref={refComponent}
			viewBox={viewBox}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1.875 4.6875C1.875 3.58293 2.77043 2.6875 3.875 2.6875H11.125C12.2296 2.6875 13.125 3.58293 13.125 4.6875V12.25C13.125 13.3546 12.2296 14.25 11.125 14.25H3.875C2.77043 14.25 1.875 13.3546 1.875 12.25V4.6875Z"
				stroke="currentColor"
				strokeWidth="1.5"
			/>
			<path
				d="M1.875 6.125H13.125"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M5 1.75L5 3.625"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10 1.75V3.625"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<circle cx="7.5" cy="9.875" r="0.625" fill="currentColor" />
			<circle cx="10" cy="9.875" r="0.625" fill="currentColor" />
			<circle cx="5" cy="9.875" r="0.625" fill="currentColor" />
		</svg>
	);
};
