import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { BreakpointsSwiper } from 'src/@types/global';
import { ArrowRightIcon } from '../ui/icons';
import cl from './slider-card-info.module.scss';
import 'swiper/css';
import 'swiper/css/pagination';

const BREAKPOINTS: BreakpointsSwiper = {
	0: {
		slidesPerView: 1.2,
		spaceBetween: 15,
	},
	540: {
		slidesPerView: 1.5,
	},
	680: {
		slidesPerView: 1.8,
	},
	768: {
		slidesPerView: 2.5,
		spaceBetween: 22,
	},
	996: {
		slidesPerView: 3,
		spaceBetween: 24,
	},

	1530: {
		slidesPerView: 4,
	},
};

export const SliderCardInfo = ({
	items,
	dots = false,
	breakpoints = BREAKPOINTS,
	arrowOff = false,
	classes,
	hideFooter = false,
}: {
	items: {
		preview: { type: 'jpg' | 'png' | 'webp'; uri: string }[];
		title: string;
		subtitle?: string;
	}[];
	dots?: boolean;
	breakpoints?: BreakpointsSwiper;
	arrowOff?: boolean;
	classes?: {
		slide?: string;
	};
	hideFooter?: boolean;
}) => {
	return (
		<div className={cl.section}>
			{arrowOff === false ? (
				<>
					<div
						className={
							cl.navigation__btn +
							' ' +
							cl.navigation__btn__prev +
							' swiper-card-info-button-prev'
						}
						role="button"
					>
						<ArrowRightIcon className={cl.navigation__btn__icon} />
					</div>

					<div
						className={
							cl.navigation__btn +
							' ' +
							cl.navigation__btn__next +
							' swiper-card-info-button-next'
						}
						role="button"
					>
						<ArrowRightIcon className={cl.navigation__btn__icon} />
					</div>
				</>
			) : null}

			<div className="container">
				<Swiper
					breakpoints={breakpoints}
					slidesPerView={3.5}
					spaceBetween={15}
					pagination={
						!dots
							? false
							: {
									clickable: true,
								}
					}
					modules={[Pagination, Navigation]}
					className="swiper__card__info"
					navigation={{
						nextEl: '.swiper-card-info-button-next',
						prevEl: '.swiper-card-info-button-prev',
						disabledClass: 'swiper-card-info-button-disabled',
					}}
				>
					{items.map((item, index) => (
						<SwiperSlide
							key={index}
							className={
								cl.slide +
								' ' +
								(classes?.slide ? classes?.slide : cl.slide_default)
							}
						>
							<div
								className={
									cl.swiper__card__info__slide +
									' ' +
									(hideFooter ? cl.transparent : '')
								}
							>
								<img
									src="/image/bg-card-post-wawe.png"
									alt=""
									loading="lazy"
									decoding="async"
									className={cl.slide__bg}
								/>
								<picture
									className={
										cl.swiper__card__info__slide__img +
										' ' +
										(!hideFooter ? cl.small : '')
									}
								>
									{item.preview.map((img) => (
										<source
											type={'image/' + img.type}
											srcSet={img.uri}
											key={img.type}
										/>
									))}
									<img
										src={item.preview[item.preview.length - 1].uri}
										className={
											cl.swiper__card__info__slide__img +
											' ' +
											(!hideFooter ? cl.small : '')
										}
										alt=""
										loading="lazy"
									/>
								</picture>

								<div
									className={
										cl.swiper__card__info__slide__footer +
										' ' +
										(hideFooter ? cl.absolute : '')
									}
								>
									<h3 className={cl.swiper__card__info__slide__title}>
										{item.title}
									</h3>
									{item.subtitle && (
										<p className={cl.swiper__card__info__slide__subtitle}>
											{item.subtitle}
										</p>
									)}
								</div>
							</div>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		</div>
	);
};
