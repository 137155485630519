import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RoutsPath } from 'src/@types';
import { BreakpointsSwiper } from 'src/@types/global';
import { PageLayout } from '../../components/layout/page.layout';
import { Banner } from 'src/components/banner/banner.component';
import { CardElevate } from 'src/components/card/card-elevate/card-elevate.component';
import { CardMissionInfo } from 'src/components/card/card-mission-info/card-mission-info.component';
import { SectionGoTeam } from 'src/components/sections/section-go-team/section-go-team.component';
import { SliderCardInfo } from 'src/components/slider-card-info/slider-card-info.component';
import { SliderReview } from 'src/components/slider-review/slider-review.component';
import { Button } from 'src/components/ui/buttons/button/button.component';
import { HeaderSection } from 'src/components/ui/header-section/header-section.component';
import { TextBlock } from 'src/components/ui/text-block/text-block.components';
import { createLangHref } from 'src/utils/href.utils';
import cl from './index.module.scss';
import {
	useLearningToPlayInstrumentsPost,
	useLearningToPlayInstrumentsReviews,
} from './mock';

const BREAKPOINTS_POSTS: BreakpointsSwiper = {
	0: {
		slidesPerView: 1.2,
		spaceBetween: 15,
	},
	540: {
		slidesPerView: 1.5,
	},
	680: {
		slidesPerView: 1.8,
	},
	768: {
		slidesPerView: 2.5,
	},
	996: {
		slidesPerView: 3,
		spaceBetween: 32,
	},
};

const LearningToPlayInstrumentsPage = () => {
	const { t, i18n } = useTranslation();
	const reviews = useLearningToPlayInstrumentsReviews();
	const posts = useLearningToPlayInstrumentsPost();
	const navigation = useNavigate();
	const scrollToContact = () =>
		navigation(
			createLangHref(
				i18n.language,
				RoutsPath.learning_to_play_instruments + RoutsPath.contacts,
			),
		);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, []);

	return (
		<PageLayout positionHeader="fixed">
			<div className={cl.page}>
				<Banner
					title={t('learning.title')}
					preview={[
						{
							type: 'webp',
							uri: '/image/learning/banner.webp',
						},
						{
							type: 'jpg',
							uri: '/image/learning/banner.jpg',
						},
					]}
					actions={
						<Button className={cl.banner__btn} onClick={scrollToContact}>
							{t('btn.contact')}
						</Button>
					}
				/>
				<div className="container position_relative">
					<TextBlock
						title={t('learning.text.title')}
						description={t('learning.text.description')}
						classes={{
							title: cl['text-info-title'],
							description: cl['text-info-desc'],
						}}
					/>
				</div>
				{/* MISSION ONE */}
				<div className={cl.mission}>
					<div className="container">
						<div className={cl.mission__content}>
							<div className={cl.mission__content__left}>
								<h2 className={cl.mission__content__title}>
									{t('learning.mission.one.title')}
								</h2>
								<p className={cl.mission__content__desc}>
									{t('learning.mission.one.description')}
								</p>

								<div className={cl.mission__content__item}>
									<h4 className={cl.mission__content__item__title}>
										{t('learning.mission.one.items.one.title')}
									</h4>
									<p className={cl.mission__content__item__desc}>
										{t('learning.mission.one.items.one.description')}
									</p>
								</div>
								<div className={cl.mission__content__item}>
									<h4 className={cl.mission__content__item__title}>
										{t('learning.mission.one.items.two.title')}
									</h4>
									<p className={cl.mission__content__item__desc}>
										{t('learning.mission.one.items.two.description')}
									</p>
								</div>
								<div className={cl.mission__content__item}>
									<h4 className={cl.mission__content__item__title}>
										{t('learning.mission.one.items.three.title')}
									</h4>
									<p className={cl.mission__content__item__desc}>
										{t('learning.mission.one.items.three.description')}
									</p>
								</div>
							</div>
							<div className={cl.mission__content__right}>
								<img
									src="/image/learning/p2.png"
									className={cl.mission__content__right__img}
									loading="lazy"
									alt=""
								/>
								<img
									src="/image/learning/decor.png"
									className={cl.decor__user__one}
									loading="lazy"
									decoding="async"
									alt=""
								/>
								<div className={cl.mission__content__right__info}>
									<p className={cl.mission__content__right__name}>
										{t('learning.mission.one.name')}
									</p>
									<p className={cl.mission__content__right__desc}>
										{t('learning.mission.one.info')}
									</p>
								</div>
							</div>
						</div>
						<div className={cl.mission__cards}>
							<CardMissionInfo
								text={t('learning.mission.one.cards.one')}
								icon={
									<img
										className={cl.mission__cards__icon}
										src="/image/mission-icons/circle-white.svg"
										loading="lazy"
										decoding="async"
										alt=""
									/>
								}
								purple
							/>
							<CardMissionInfo
								text={t('learning.mission.one.cards.two')}
								icon={
									<img
										className={cl.mission__cards__icon}
										src="/image/mission-icons/sun-white.svg"
										loading="lazy"
										decoding="async"
										alt=""
									/>
								}
								purple
							/>
							<CardMissionInfo
								text={t('learning.mission.one.cards.three')}
								icon={
									<img
										className={cl.mission__cards__icon}
										src="/image/mission-icons/wind-white.svg"
										loading="lazy"
										decoding="async"
										alt=""
									/>
								}
								purple
							/>
							<CardMissionInfo
								text={t('learning.mission.one.cards.four')}
								icon={
									<img
										className={cl.mission__cards__icon}
										src="/image/mission-icons/flower-white.svg"
										loading="lazy"
										alt=""
										decoding="async"
									/>
								}
								purple
							/>
						</div>
					</div>
				</div>
				{/* MISSION TWO */}
				<div className={cl.mission}>
					<div className="container">
						<div className={cl.mission__content}>
							<div className={cl.mission__content__left}>
								<h2 className={cl.mission__content__title}>
									{t('learning.mission.two.title')}
								</h2>
								<p className={cl.mission__content__desc}>
									{t('learning.mission.two.description')}
								</p>

								<div className={cl.mission__content__item}>
									<h4 className={cl.mission__content__item__title}>
										{t('learning.mission.two.items.one.title')}
									</h4>
									<p className={cl.mission__content__item__desc}>
										{t('learning.mission.two.items.one.description')}
									</p>
								</div>
								<div className={cl.mission__content__item}>
									<h4 className={cl.mission__content__item__title}>
										{t('learning.mission.two.items.two.title')}
									</h4>
									<p className={cl.mission__content__item__desc}>
										{t('learning.mission.two.items.two.description')}
									</p>
								</div>
								<div className={cl.mission__content__item}>
									<h4 className={cl.mission__content__item__title}>
										{t('learning.mission.two.items.three.title')}
									</h4>
									<p className={cl.mission__content__item__desc}>
										{t('learning.mission.two.items.three.description')}
									</p>
								</div>
							</div>
							<div className={cl.mission__content__right}>
								<img
									src="/image/learning/p1.png"
									className={cl.mission__content__right__img}
									loading="lazy"
									alt=""
								/>
								<img
									src="/image/learning/decor.png"
									className={cl.decor__user__one + ' ' + cl.rotate}
									loading="lazy"
									decoding="async"
									alt=""
								/>
								<div className={cl.mission__content__right__info}>
									<p className={cl.mission__content__right__name}>
										{t('learning.mission.two.name')}
									</p>
									<p className={cl.mission__content__right__desc}>
										{t('learning.mission.two.info')}
									</p>
								</div>
							</div>
						</div>
						<div className={cl.mission__cards + ' ' + cl.three}>
							<CardMissionInfo
								text={t('learning.mission.two.cards.one')}
								icon={
									<img
										className={cl.mission__cards__icon}
										src="/image/mission-icons/sky-white.svg"
										loading="lazy"
										decoding="async"
										alt=""
									/>
								}
								purple
							/>
							<CardMissionInfo
								text={t('learning.mission.two.cards.two')}
								icon={
									<img
										className={cl.mission__cards__icon}
										src="/image/mission-icons/sun-white.svg"
										loading="lazy"
										decoding="async"
										alt=""
									/>
								}
								purple
							/>
							<CardMissionInfo
								text={t('learning.mission.two.cards.three')}
								icon={
									<img
										className={cl.mission__cards__icon}
										src="/image/mission-icons/month-white.svg"
										loading="lazy"
										decoding="async"
										alt=""
									/>
								}
								purple
							/>
						</div>
					</div>
				</div>
				<div className={'container ' + cl.work}>
					<HeaderSection
						title={t('learning.curse.title')}
						description={t('learning.curse.description')}
						classes={{
							description: cl.curse__desc,
							title: cl.curse__title,
							root: cl.curse__root,
						}}
						smallTitle
					/>

					<div className={cl.variants}>
						<SliderCardInfo
							items={posts}
							breakpoints={BREAKPOINTS_POSTS}
							classes={{
								slide: 'slide-variants',
							}}
							arrowOff
						/>
					</div>
				</div>

				<section className={cl.section__grid}>
					<picture className={cl.section__grid__bg}>
						<source type="image/png" srcSet="/image/bg-right-2x.png" />
						<img
							src={'/image/bg-right-2x.png'}
							className={cl.section__grid__bg}
							alt=""
							loading="lazy"
							decoding="async"
						/>
					</picture>

					<div className={'container position_relative ' + cl.zIndex}>
						<h3 className={cl.section_title + ' ' + cl.section_title_position}>
							{t('retreats.section.title')}
						</h3>
						<div className={cl.row__group__start}>
							<div className={cl.row + ' ' + cl.end}>
								<CardElevate
									title={t('learning.elevate.one.title')}
									image="g1"
									folder="learning"
								/>
							</div>
							<div className={cl.row + ' ' + cl.center + ' ' + cl.topY}>
								<CardElevate
									title={t('learning.elevate.two.title')}
									image="g2"
									folder="learning"
								/>
							</div>
						</div>

						<div className={cl.row + ' ' + cl.row__group__center}>
							<CardElevate
								title={t('learning.elevate.three.title')}
								image="g3"
								folder="learning"
							/>
						</div>
						<div className={cl.row__group__end}>
							<div className={cl.row + ' ' + cl.center}>
								<CardElevate
									title={t('learning.elevate.four.title')}
									image="g4"
									folder="learning"
								/>
							</div>
							<div className={cl.row + ' ' + cl.end + ' ' + cl.topY}>
								<CardElevate
									title={t('learning.elevate.five.title')}
									image="g5"
									folder="learning"
								/>
							</div>
						</div>
					</div>
				</section>

				<div className={cl.reviews}>
					<div className={cl.reviews__content}>
						<TextBlock
							title={t('retreats.reviews.title')}
							description={t('retreats.reviews.description')}
							classes={{
								root: cl['text-reviews-root'],
								title: cl['text-info-title'],
								description: cl['text-info-desc'],
							}}
						/>
						<SliderReview items={reviews} classes={{ root: cl.reviews }} />
					</div>
				</div>
				<SectionGoTeam classes={{ desc: cl.contact__desc }} contact />
			</div>
		</PageLayout>
	);
};

export default LearningToPlayInstrumentsPage;
