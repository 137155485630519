import { Langs } from 'src/@types/lang';
import { IProductColor, IProductSize } from '../catalog';

export enum DeliveryType {
	COURIER = 'COURIER',
	PICKUP = 'PICKUP',
}

export type PaymentStatus = 'NOT_PAYED' | 'PAYED';

export type OrderStatus =
	| 'NEW'
	| 'ASSEMBLED'
	| 'DISPATCHING'
	| 'RECEIVING'
	| 'DELIVERY'
	| 'DELIVERED';

export interface IOrderRequest {
	delivery_type: DeliveryType;
	delivery_address: string;
	lang: Langs;
}

export interface IOrderResponseItem {
	product: string;
	color: IProductColor;
	size: IProductSize;
	amount: number;
	price: string;
}

export interface IOrderProfileData {
	id: number;
	status: OrderStatus;
	status_human: string;
	date_created: string;
	total: string;
	payment_status: PaymentStatus;
	payment_status_human: string;
	payment_link: string;
	delivery_address: string;
	delivery_type: DeliveryType;
	delivery_type_human: string;
	lang: Langs;
	items_count: string;
}

export interface IRecordsData {
	date: string;
	id: number;
	is_active: boolean;
	max_users: number;
	event_location: {
		pk: number;
		address: string;
		title: string;
	};
	status: 'success' | 'cancel' | 'wait' | 'blocked' | 'completed';
	time_finish: string;
	title: string;
	type: string;
	type_human: string;
	users_cnt: number;
}

export interface IOrderResponse {
	id: number;
	status: OrderStatus;
	status_human: string;
	date_created: string;
	total: string;
	payment_status: PaymentStatus;
	payment_status_human: string;
	payment_link: string;
	delivery_address: string;
	delivery_type: DeliveryType;
	delivery_type_human: string;
	lang: Langs;
	items: IOrderResponseItem[];
}
