import { ISvgIconProps } from '.';

export const ProfileIcon = ({
	className = '',
	viewBox = '0 0 30 30',
	refComponent = undefined,
}: ISvgIconProps) => {
	return (
		<svg
			className={className}
			ref={refComponent}
			viewBox={viewBox}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.4996 13.6667C18.4977 13.6667 20.9281 11.2789 20.9281 8.33333C20.9281 5.38782 18.4977 3 15.4996 3C12.5015 3 10.071 5.38782 10.071 8.33333C10.071 11.2789 12.5015 13.6667 15.4996 13.6667ZM15.5 27C20.7467 27 25 24.6122 25 21.6667C25 18.7211 20.7467 16.3333 15.5 16.3333C10.2533 16.3333 6 18.7211 6 21.6667C6 24.6122 10.2533 27 15.5 27Z"
				fill="currentColor"
			/>
		</svg>
	);
};
