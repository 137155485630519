import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { RoutsPath } from '../../../@types';
import { ICardProduct } from './card-product.model';
import { CURRENCY } from '../../../const/settings.const';
import { createLangHref } from '../../../utils/href.utils';
import cl from './card-product.module.scss';

const Price = ({ price }: { price: number | string }) => {
	return (
		<p className={cl.price}>
			<span className={cl.currency}>{CURRENCY}</span>
			{price}
		</p>
	);
};

const mapBreak = <T,>(index: number, arr: T[]) => {
	const result = [];
	for (let i = 0; i < arr.length; i++) {
		if (index === i) {
			break;
		}

		result.push(arr[i]);
	}

	return result;
};

export const CardProduct = ({ data, className = '' }: ICardProduct) => {
	const { i18n } = useTranslation();
	const href = createLangHref(i18n.language, RoutsPath.catalog + '/' + data.id);
	return (
		<Link className={cl.card + ' ' + className} to={href}>
			<img
				src={
					data.main_image
						? process.env.REACT_APP_API_URL + data.main_image.image
						: '/image/products/empty.jpg'
				}
				alt={data.name || ''}
				className={cl.preview}
			/>
			<div className={cl.body}>
				<div className={cl.header}>
					<div className={cl.tth}>
						<div className={cl.tth__colors__row}>
							{data.colors.map((item) => (
								<span
									style={{ backgroundColor: item.html_code }}
									key={item.html_code}
								></span>
							))}
						</div>

						<div className={cl.tth__sizes__row}>
							<div className={cl.tth__sizes}>
								{mapBreak(4, data.sizes).map((item, index) => (
									<span key={index} className={cl.tth__sizes__item}>
										{item.name}
									</span>
								))}
								{data.sizes.length > 4 && <span className={cl.exept}>...</span>}
							</div>
						</div>
					</div>
					<h3 className={cl.header__title}>{data.name}</h3>
				</div>
				<Price price={data.price} />
			</div>
		</Link>
	);
};
