import { useRef } from 'react';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
	CardTeam,
	CardTeamSkeleton,
} from '../card/card-team/card-team.component';
import { ArrowRightIcon } from '../ui/icons';
import cl from './slider-users.module.scss';

const BREAKPOINTS = {
	0: {
		slidesPerView: 1.2,
		spaceBetween: 16,
	},
	540: {
		slidesPerView: 1.4,
	},
	680: {
		slidesPerView: 2.1,
	},
	768: {
		slidesPerView: 2.6,
	},
	996: {
		slidesPerView: 3,
		spaceBetween: 32,
	},
	2000: {
		slidesPerView: 4,
	},
};

export const SliderUsers = ({
	slides,
	translate = false,
	isLoading = false,
	onClickCard,
}: {
	slides: {
		preview: { type: 'jpg' | 'png' | 'webp'; uri: string }[] | string;
		title: string;
		position: string;
		id?: number;
		description?: string;
	}[];
	translate?: boolean;
	isLoading?: boolean;
	onClickCard: (id?: number) => void;
}) => {
	const prevRef = useRef<HTMLDivElement | null>(null);
	const nextRef = useRef<HTMLDivElement | null>(null);

	return (
		<div className={cl.container}>
			<div
				className={
					cl.navigation__btn + ' ' + cl.navigation__btn__prev + ' master-prev'
				}
				ref={prevRef}
				role="button"
			>
				<ArrowRightIcon className={cl.navigation__btn__icon} />
			</div>
			<div
				className={
					cl.navigation__btn + ' ' + cl.navigation__btn__next + ' master-next'
				}
				ref={nextRef}
				role="button"
			>
				<ArrowRightIcon className={cl.navigation__btn__icon} />
			</div>
			<Swiper
				breakpoints={BREAKPOINTS}
				slidesPerView={4}
				className="swiper-team"
				modules={[Navigation]}
				navigation={{
					nextEl: nextRef.current,
					prevEl: prevRef.current,
				}}
				onBeforeInit={(swiper: any) => {
					swiper.params.navigation.prevEl = prevRef.current;
					swiper.params.navigation.nextEl = nextRef.current;
				}}
			>
				{!isLoading &&
					slides.map((item, index) => (
						<SwiperSlide key={index}>
							<CardTeam
								preview={item.preview}
								title={item.title}
								position={item.position}
								translate={translate}
								onClick={() => onClickCard(item.id)}
								description={item.description}
							/>
						</SwiperSlide>
					))}
				{isLoading &&
					[1, 2, 3, 4, 5].map((item) => (
						<SwiperSlide key={item + '-loading'}>
							<CardTeamSkeleton />
						</SwiperSlide>
					))}
			</Swiper>
		</div>
	);
};
