import { useId } from 'react';
import cl from './button-group.module.scss';

export const ButtonGroup = ({
	buttons,
	active,
	classes,
	loading,
	columnOff = true,
}: {
	loading?: {
		isLoading: boolean;
		count: number;
	};
	classes?: {
		root?: string;
		button?: string;
		buttonLoad?: string;
	};
	active?: string;
	buttons: {
		label: string;
		data?: string;
		onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
	}[];
	columnOff?: boolean;
}) => {
	const { root = '', button = '', buttonLoad = '' } = classes || {};
	const id = useId();
	return (
		<div
			className={cl.group + ' ' + root + ' ' + (columnOff ? cl.columnOff : '')}
		>
			{loading !== undefined && loading.isLoading === true
				? [...Array(loading.count)].map((_, index) => (
						<button
							type="button"
							className={
								cl.group__item +
								' ' +
								cl.group__item__load +
								' ' +
								' skeleton skeleton-image ' +
								buttonLoad
							}
							key={index + '_loader_' + id}
						></button>
					))
				: buttons.map((item, index) => (
						<button
							type="button"
							className={
								cl.group__item +
								' ' +
								button +
								' ' +
								(active === item.data ? cl.active : '')
							}
							data-value={item.data ? item.data : index}
							onClick={item.onClick}
							key={index + '-key-' + id}
						>
							{item.label}
						</button>
					))}
		</div>
	);
};
