import React, { Suspense, useState } from 'react';

const MenuModal = React.lazy(() => import('./menu-dropdown.component'));

export const MenuDropDownContext = React.createContext<{
	open: boolean;
	onToggleModal: (value: boolean) => void;
}>({
	open: false,
	onToggleModal: (value: boolean) => void 0,
});

export const MenuDropDownContextProvider = ({
	children,
}: {
	children?: JSX.Element[] | JSX.Element;
}) => {
	const [open, setOpen] = useState<boolean>(false);
	const onToggleModal = (value: boolean) => setOpen(value);

	const contextValue = { open, onToggleModal };

	return (
		<MenuDropDownContext.Provider value={contextValue}>
			{children}
			<Suspense fallback={<div />}>
				<MenuModal portal />
			</Suspense>
		</MenuDropDownContext.Provider>
	);
};
