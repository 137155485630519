import { useQuery } from '@tanstack/react-query';
import { createContext, useContext, useMemo, useState } from 'react';
import {
	GenreListData,
	MasterListData,
	PlaceListData,
	Schedule,
} from '../../../@types';
import { useApplication } from '../../../application/application.context';
import { apiServices } from '../../../services/api';

const useGenres = ({
	limit = 20,
	offset = 0,
}: {
	limit: number;
	offset: number;
}) => {
	return useQuery({
		queryKey: [limit, offset, 'genres'],
		queryFn: async () => {
			return await apiServices.getGenres({ limit, offset });
		},
	});
};

const usePlaces = ({
	limit = 20,
	offset = 0,
}: {
	limit: number;
	offset: number;
}) => {
	return useQuery({
		queryKey: [limit, offset, 'places'],
		queryFn: async () => {
			return await apiServices.getPlaces({ limit, offset });
		},
	});
};

export const DEFAULT_MASTER = {
	values: '-1',
	label: 'option.all.masters',
};

export const DEFAULT_GENRE = {
	values: '-1',
	label: 'option.all.practices',
};

export const DEFAULT_PLACES = {
	values: '-1',
	label: 'option.all.places',
};

//TODO: сделать фильтры как у мастеров и жанра
export interface IScheduleFiltersContext {
	dateRange: [Date | null, Date | null];
	eventType: Schedule;
	onChangeEvent: (e: React.MouseEvent<HTMLButtonElement>) => void;
	setDateRange: (date: [Date | null, Date | null]) => void;
	filterMaster: {
		isLoading: boolean;
		options: MasterListData;
		onChangeMaster: (value: { label: string; values: string }) => void;
		master: { label: string; values: string };
	};
	filterGenre: {
		isLoading: boolean;
		options: GenreListData;
		onChangeGenre: (value: { label: string; values: string }) => void;
		genre: { label: string; values: string };
		error: Error | null;
	};
	filterPlace: {
		isLoading: boolean;
		options: PlaceListData;
		onChangePlace: (value: { label: string; values: string }) => void;
		place: { label: string; values: string };
		error: Error | null;
	};
	resetFilter: () => void;
}
//TODO: сделать фильтры как у мастеров и жанра
const defaultValue = {
	dateRange: [null, null] as [Date | null, Date | null],
	eventType: Schedule.ALL,
	onChangeEvent: (e: React.MouseEvent<HTMLButtonElement>) => void 0,
	setDateRange: (date: [Date | null, Date | null]) => void 0,
	resetFilter: () => void 0,
	filterMaster: {
		isLoading: false,
		options: [],
		onChangeMaster: (value: { label: string; values: string }) => void 0,
		master: DEFAULT_MASTER,
	},
	filterGenre: {
		isLoading: false,
		options: [],
		onChangeGenre: (value: { label: string; values: string }) => void 0,
		genre: DEFAULT_GENRE,
		error: null,
	},
	filterPlace: {
		isLoading: false,
		options: [],
		onChangePlace: (value: { label: string; values: string }) => void 0,
		place: DEFAULT_PLACES,
		error: null,
	},
};

export const ScheduleFiltersContext =
	createContext<IScheduleFiltersContext>(defaultValue);

export const useScheduleFilters = () => {
	const {
		eventType,
		setDateRange,
		onChangeEvent,
		dateRange,
		filterMaster,
		filterGenre,
		filterPlace,
		resetFilter,
	} = useContext(ScheduleFiltersContext);

	const eventTypeMemo = useMemo(() => {
		return eventType;
	}, [eventType]);
	const dateRangeMemo = useMemo(() => {
		return dateRange;
	}, [dateRange]);

	return {
		eventType: eventTypeMemo,
		setDateRange,
		onChangeEvent,
		dateRange: dateRangeMemo,
		filterMaster,
		filterGenre,
		filterPlace,
		resetFilter,
	};
};

const ScheduleFiltersProvider = ({ children }: { children: JSX.Element }) => {
	const {
		data: dataGenres,
		isLoading: isLoadingGenres,
		error: errorGenres,
	} = useGenres({ limit: 20, offset: 0 });

	const {
		data: dataPlace,
		isLoading: isLoadingPlace,
		error: errorPlaces,
	} = usePlaces({ limit: 20, offset: 0 });

	const { store_masters } = useApplication();
	const { masters = [], isLoading } = store_masters.state;

	const [master, setMaster] = useState(DEFAULT_MASTER);
	const [genre, setGenre] = useState(DEFAULT_GENRE);
	const [place, setPlace] = useState(DEFAULT_PLACES);

	const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
		null,
		null,
	]);

	const [eventType, setEventType] = useState<Schedule>(Schedule.ALL);

	const onChangeEvent = (e: React.MouseEvent<HTMLButtonElement>) => {
		const btn = e.currentTarget;
		const data = btn.dataset.value || Schedule.ALL;
		setEventType(data as Schedule);
	};

	const onChangeMaster = (value: { label: string; values: string }) => {
		if (value === null) {
			setMaster(DEFAULT_MASTER);
		} else {
			setMaster(value);
		}
	};

	const onChangeGenre = (value: { label: string; values: string }) => {
		if (value === null) {
			setGenre(DEFAULT_GENRE);
		} else {
			setGenre(value);
		}
	};

	const onChangePlace = (value: { label: string; values: string }) => {
		if (value === null) {
			setPlace(DEFAULT_PLACES);
		} else {
			setPlace(value);
		}
	};

	const resetFilter = () => {
		setMaster(DEFAULT_MASTER);
		setGenre(DEFAULT_GENRE);
		setPlace(DEFAULT_PLACES);
		setEventType(Schedule.ALL);
		setDateRange([null, null]);
	};

	return (
		<ScheduleFiltersContext.Provider
			value={{
				dateRange,
				setDateRange,
				eventType,
				onChangeEvent,
				filterMaster: {
					options: masters || [],
					isLoading: isLoading,
					master,
					onChangeMaster,
				},
				filterGenre: {
					options: dataGenres || [],
					isLoading: isLoadingGenres,
					genre,
					onChangeGenre,
					error: errorGenres,
				},
				filterPlace: {
					options: dataPlace || [],
					isLoading: isLoadingPlace,
					place,
					onChangePlace,
					error: errorPlaces,
				},
				resetFilter: resetFilter,
			}}
		>
			{children}
		</ScheduleFiltersContext.Provider>
	);
};

export default ScheduleFiltersProvider;
