import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useWindowSize } from 'src/hooks/useWindowSize';
import { ArrowRightIcon } from '../ui/icons';
import cl from './carousel-images.module.scss';

interface ISwiper {
	activeIndex: number;
	previousIndex: number;
	slideTo?: (index: number) => void;
}

export const CarouselImagesSkeleton = ({
	classes,
}: {
	classes?: {
		root?: string;
	};
}) => {
	const { root = '' } = classes || {};
	return <div className={cl.container + ` ${root} skeleton`} />;
};

export const CarouselImages = ({
	images,
	navigation = true,
	dotsImage = false,
	absPath = '',
	classes,
	vertical = false,
}: {
	images: string[];
	navigation?: boolean;
	dotsImage?: boolean;
	absPath?: string;
	vertical?: boolean;
	classes?: {
		root?: string;
		slider?: string;
		slide?: string;
	};
}) => {
	const { root = '', slider = cl.slider, slide = '' } = classes || {};
	const prevRef = useRef<HTMLDivElement | null>(null);
	const nextRef = useRef<HTMLDivElement | null>(null);
	const [active, setActive] = useState(0);
	const refSwiper = useRef<ISwiper | null>(null);
	const { width = window.innerWidth } = useWindowSize();
	const isHideDots = useMemo(() => {
		return width <= 680;
	}, [width]);

	const slideDotToGo = useCallback(
		(index: number) => {
			if (refSwiper.current && refSwiper.current.slideTo) {
				refSwiper.current.slideTo(index);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[refSwiper.current, active],
	);

	const onSlideChange = (sw: ISwiper) => {
		setActive(sw.activeIndex);
	};

	return (
		<div
			className={
				cl.container +
				` ${root} ` +
				(!dotsImage ? cl.overflow : '') +
				' ' +
				(vertical ? cl.vertical : '')
			}
		>
			<Swiper
				slidesPerView={isHideDots ? 1.2 : 1}
				spaceBetween={16}
				modules={[Navigation]}
				className={slider}
				navigation={{
					nextEl: '.slide-next-image',
					prevEl: '.slide-prev-image',
				}}
				onSwiper={(sw: any) => (refSwiper.current = sw)}
				onSlideChange={onSlideChange}
			>
				{images.map((item, index) => (
					<SwiperSlide className={cl.slide + ' ' + slide} key={index}>
						<div className={cl.img__wrap}>
							<img src={absPath + item} alt="" loading={'lazy'} />
						</div>
					</SwiperSlide>
				))}
			</Swiper>

			{dotsImage && !isHideDots && (
				<div className={cl.dots__image}>
					<Swiper
						direction={vertical ? 'vertical' : undefined}
						spaceBetween={10}
						slidesPerView={5}
						className={cl.slider}
					>
						{images.map((item, index) => (
							<SwiperSlide
								className={cl.slide__dot + ' slider-dot-custom '}
								key={index}
							>
								<img
									src={absPath + item}
									alt=""
									className={
										cl.slide__dot__preview +
										' ' +
										(active === index ? cl.dot_active : '')
									}
									loading={'lazy'}
									onClick={() => slideDotToGo(index)}
								/>
							</SwiperSlide>
						))}
					</Swiper>
				</div>
			)}

			{navigation && (
				<div className={cl.navigation}>
					<div
						className={cl.navigation__btn + ' ' + cl.prev + ' slide-prev-image'}
						ref={prevRef}
						role="button"
					>
						<ArrowRightIcon className={cl.navigation__btn__icon} />
					</div>
					<ul className={cl.navigation__dots}>
						{images.map((_, index) => (
							<li
								className={`${cl.navigation__dot} ${
									active === index ? cl.active : ''
								}`}
								key={index + '-dot'}
							></li>
						))}
					</ul>
					<div
						className={cl.navigation__btn + ' ' + cl.next + ' slide-next-image'}
						ref={nextRef}
						role="button"
					>
						<ArrowRightIcon className={cl.navigation__btn__icon} />
					</div>
				</div>
			)}
		</div>
	);
};

export const CarouselImagesMemo = memo(CarouselImages);
