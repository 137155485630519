import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderStatus, RoutsPath } from '../../@types';
import { AsideNavigation } from '../../components/aside/aside-profile/aside-profile.component';
import { CardOrder } from '../../components/card/card-order/card-order.component';
import ContentLayout from '../../components/layout/content-layout/content-layout.component';
import { PageLayout } from '../../components/layout/page.layout';
import { Breadcrumbs } from '../../components/ui/breadcrumbs/breadcrumbs.component';
import { ButtonGroup } from '../../components/ui/button-group/button-group.component';
import { queryClient } from 'src/application/application.component';
import { apiServices } from 'src/services/api';
import cl from './index.module.scss';

const useOrders = ({
	limit = 10,
	offset = 0,
	status = 'NEW',
}: {
	limit: number;
	offset: number;
	status?: OrderStatus;
}) => {
	return useQuery({
		queryKey: [limit, offset, status, 'orders'],
		queryFn: async () => {
			return await apiServices.getOrders({ limit, offset });
		},
	});
};

const OrdersPage = () => {
	const [record, setRecord] = useState<OrderStatus>('NEW');
	const { t } = useTranslation();

	const { data, isLoading } = useOrders({
		status: record,
		limit: 10,
		offset: 0,
	});

	const onChangeRecord = (e: React.MouseEvent<HTMLButtonElement>) => {
		const target = e.currentTarget;
		const data = target.dataset.value || 'NEW';
		setRecord(data as OrderStatus);
	};

	const mutation = useMutation({
		mutationKey: ['cancel_participate', 'participate'],
		mutationFn: (data: { slug: string }) => {
			return apiServices.cancelOrders(data.slug);
		},
		onSuccess() {
			queryClient.invalidateQueries({
				queryKey: [10, 0, record, 'orders'],
			});
		},
	});

	const orderCancel = (slug: string | number) => {
		mutation.mutate({ slug: slug.toString() });
	};

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, []);

	const data_filters = useMemo(() => {
		return data ? data.results.filter((item) => item.status === record) : [];
	}, [data, isLoading, record]);

	return (
		<PageLayout positionHeader="fixed">
			<div className={cl.page}>
				<div className={'header-offset'} />
				<div className="container">
					<div className={'box20'}></div>
					<Breadcrumbs
						items={[
							{
								name: t('page.profile'),
								translate: true,
								href: RoutsPath.profile,
							},
							{ name: 'page.profile.orders', translate: true },
						]}
					/>
					<h1 className="title-section-small padding-bottom_40">
						{t('page.profile.orders')}
					</h1>
					<ContentLayout aside={<AsideNavigation />} small mobileTabs>
						<>
							<div className={cl.tab}>
								<ButtonGroup
									active={record}
									classes={{
										button: cl.tab__item,
										root: cl.tab__item,
									}}
									buttons={[
										{
											label: t('filter.active'),
											onClick: onChangeRecord,
											data: 'NEW',
										},
										{
											label: t('filter.closed'),
											onClick: onChangeRecord,
											data: 'DELIVERED',
										},
									]}
								/>
							</div>

							{isLoading && (
								<ul className={cl.list}>
									<li className={cl.list__item}>
										<div className={cl.list__item__loading + ' skeleton'}></div>
									</li>
									<li className={cl.list__item}>
										<div className={cl.list__item__loading + ' skeleton'}></div>
									</li>
									<li className={cl.list__item}>
										<div className={cl.list__item__loading + ' skeleton'}></div>
									</li>
								</ul>
							)}

							{!isLoading && data && data_filters.length === 0 && (
								<div className={cl.order__empty}>
									{t('profile.orders.empty')}
								</div>
							)}

							{data && data_filters.length > 0 && !isLoading && (
								<ul className={cl.list}>
									{data_filters.map((item) => (
										<li className={cl.list__item} key={item.id}>
											<CardOrder
												onCancel={orderCancel}
												data={{
													title: '№ ' + item.id,
													date: item.date_created,
													count: parseInt(item.items_count),
													position: item.delivery_type_human,
													statusPayment: item.payment_status,
													statusPaymentTitle: item.payment_status_human,
													status: item.status,
													statusTitle: item.status_human,
													price: item.total,
													id: item.id,
												}}
											/>
										</li>
									))}
								</ul>
							)}
						</>
					</ContentLayout>
				</div>
			</div>
		</PageLayout>
	);
};

export default OrdersPage;
