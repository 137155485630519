import { useEffect } from 'react';

const useBodyScrollLock = (isOpen: boolean) => {
	useEffect(() => {
		if (isOpen) {
			if (document.documentElement.scrollHeight > window.innerHeight) {
				document.body.classList.add('scroll-lock');
			}
		} else {
			document.body.classList.remove('scroll-lock');
		}

		return () => document.body.classList.remove('scroll-lock');
	}, [isOpen]);
};

export default useBodyScrollLock;