import { ISvgIconProps } from '.';

export const ToSlugIcon = ({
	className = '',
	viewBox = '0 0 475 414',
	refComponent = undefined,
}: ISvgIconProps) => {
	return (
		<svg
			className={className}
			ref={refComponent}
			viewBox={viewBox}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M154.087 111.229V149.849H0.373047V111.229H154.087ZM45.1858 40.1569H90.7668V322.838C90.7668 335.711 92.6757 345.326 96.4702 351.704C100.265 358.083 105.153 362.32 111.136 364.438C117.119 366.557 123.451 367.628 130.155 367.628C135.184 367.628 139.281 367.348 142.517 366.766C145.729 366.184 148.313 365.695 150.246 365.323L159.511 406.248C156.415 407.412 152.108 408.599 146.567 409.81C141.027 411.02 134.02 411.649 125.523 411.649C112.649 411.649 100.055 408.879 87.7638 403.338C75.4723 397.798 65.2991 389.37 57.2445 378.033C49.1898 366.696 45.1858 352.426 45.1858 335.176V40.1569Z"
				fill="currentColor"
			/>
			<path
				d="M340.228 414C313.456 414 289.991 407.622 269.831 394.888C249.694 382.154 233.981 364.322 222.713 341.392C211.446 318.485 205.812 291.714 205.812 261.055C205.812 230.396 211.446 203.182 222.713 180.159C233.981 157.113 249.671 139.234 269.831 126.477C289.967 113.72 313.433 107.365 340.228 107.365C367.022 107.365 390.464 113.743 410.601 126.477C430.738 139.211 446.451 157.113 457.718 180.159C468.986 203.206 474.619 230.163 474.619 261.055C474.619 291.947 468.986 318.462 457.718 341.392C446.451 364.299 430.738 382.131 410.601 394.888C390.464 407.645 366.999 414 340.228 414ZM340.228 373.052C360.574 373.052 377.311 367.837 390.441 357.408C403.571 346.979 413.301 333.267 419.61 316.274C425.919 299.28 429.061 280.866 429.061 261.055C429.061 241.244 425.919 222.76 419.61 205.627C413.301 188.516 403.594 174.665 390.441 164.12C377.311 153.574 360.574 148.29 340.228 148.29C319.881 148.29 303.144 153.574 290.014 164.12C276.884 174.665 267.154 188.516 260.845 205.627C254.536 222.76 251.394 241.221 251.394 261.055C251.394 280.889 254.536 299.28 260.845 316.274C267.154 333.267 276.884 346.979 290.014 357.408C303.144 367.837 319.881 373.052 340.228 373.052Z"
				fill="currentColor"
			/>
		</svg>
	);
};
