import { Langs } from 'src/@types';
import cl from './header-section.module.scss';

export const HeaderSection = ({
	title,
	description,
	smallTitle = false,
	lang = Langs.en,
	classes,
}: {
	title: string;
	description: string;
	smallTitle?: boolean;
	lang?: string;
	classes?: {
		root?: string;
		title?: string;
		description?: string;
	};
}) => {
	const {
		root = '',
		title: clTitle = '',
		description: clDescription = '',
	} = classes || {};
	return (
		<div
			className={cl.header + ' ' + root + ' ' + (smallTitle ? cl.padding : '')}
		>
			<h2
				className={`title-section ${clTitle}  ` + (smallTitle ? cl.small : '')}
				data-lang={lang}
			>
				{title}
			</h2>
			<p
				className={`desc-section ${clDescription} ` + cl.desc}
				data-lang={lang}
			>
				{description}
			</p>
		</div>
	);
};
