import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { ICardEventProps } from './card-event.model';
import { Fade } from '../../ui/fade/fade.component';
import cl from './card-event.module.scss';

export const CardEvent = ({
	data,
	onClick,
	onClickSpeaker,
	className = '',
	isWrite = false,
}: ICardEventProps) => {
	const date_split_start = data.date.split(' ');
	const time_finish = data.time_finish ? data.time_finish.split(':') : null;
	const time_start = date_split_start[1].split(':');
	return (
		<div
			className={cl.card + ' ' + className}
			onClick={onClick}
			data-id={data.id}
			data-date={date_split_start[0]}
		>
			<Fade
				fade={data.user_status !== null && data.user_status.status === 'BOOK'}
				className={cl.tag_position}
			>
				<div className={cl.tag}>
					<svg
						width="10"
						height="10"
						viewBox="0 0 10 10"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M1.25 5.5L3.75 8L8.75 3"
							stroke="white"
							strokeWidth="1.2"
						/>
					</svg>
					<span>{t('you.write')}</span>
				</div>
			</Fade>

			<div className={cl.title}>{data.title}</div>
			<div className={cl.time_location_wrapper}>
				<div className={cl.event_location}>{data.event_location.title}</div>
				<div className={cl.duration}>
					{time_start[0]}:{time_start[1]}
					{time_finish && ` — ${time_finish[0]}:${time_finish[1]}`}
				</div>
			</div>

			<div className={cl.info}>
				<ul className={cl.info__users}>
					{data.masters.map((item, index) => (
						<li
							key={index}
							data-id={item.id}
							data-event={data.id}
							data-date={date_split_start[0]}
							className={cl.info__users__item}
							onClick={onClickSpeaker}
						>
							<img
								src={item.image}
								alt={item.name}
								loading="lazy"
								decoding="async"
							/>
						</li>
					))}
				</ul>
				<div className={cl.info__count}>
					<svg
						width="16"
						height="10"
						viewBox="0 0 16 10"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M3.42911 3.43513C4.37588 3.43513 5.14338 2.66615 5.14338 1.71757C5.14338 0.768981 4.37588 0 3.42911 0C2.48235 0 1.71484 0.768981 1.71484 1.71757C1.71484 2.66615 2.48235 3.43513 3.42911 3.43513Z"
							fill="#EBE9F0"
							fillOpacity="0.4"
						/>
						<path
							d="M12.5707 3.43513C13.5175 3.43513 14.285 2.66615 14.285 1.71757C14.285 0.768981 13.5175 0 12.5707 0C11.624 0 10.8564 0.768981 10.8564 1.71757C10.8564 2.66615 11.624 3.43513 12.5707 3.43513Z"
							fill="#EBE9F0"
							fillOpacity="0.4"
						/>
						<path
							d="M11.4293 10.0001V8.28251C11.4293 8.01533 11.3151 7.74815 11.0865 7.59548C10.6294 7.2138 10.0198 6.94662 9.41032 6.79395C8.99127 6.67945 8.49604 6.56494 8.00081 6.56494C7.54367 6.56494 7.04843 6.64128 6.5913 6.79395C5.98178 6.94662 5.41035 7.25197 4.91512 7.59548C4.68655 7.78632 4.57227 8.01533 4.57227 8.28251V10.0001H11.4293Z"
							fill="#EBE9F0"
							fillOpacity="0.4"
						/>
						<path
							d="M7.99943 6.10652C8.94619 6.10652 9.7137 5.33754 9.7137 4.38895C9.7137 3.44037 8.94619 2.67139 7.99943 2.67139C7.05266 2.67139 6.28516 3.44037 6.28516 4.38895C6.28516 5.33754 7.05266 6.10652 7.99943 6.10652Z"
							fill="#EBE9F0"
							fillOpacity="0.4"
						/>
						<path
							d="M15.6567 4.92409C15.1995 4.54241 14.59 4.27524 13.9805 4.12256C13.5614 4.00806 13.0662 3.89355 12.571 3.89355C12.1138 3.89355 11.6186 3.96989 11.1615 4.12256C10.9329 4.1989 10.7043 4.27524 10.4757 4.38974V4.42791C10.4757 5.07677 10.2091 5.68746 9.79004 6.10731C10.5138 6.33632 11.0853 6.64166 11.5424 6.98517C11.6567 7.09968 11.771 7.17601 11.8472 7.32869H15.9995V5.61112C15.9995 5.34394 15.8852 5.07677 15.6567 4.92409Z"
							fill="#EBE9F0"
							fillOpacity="0.4"
						/>
						<path
							d="M4.4571 6.98517C4.99043 6.60349 5.59995 6.29815 6.20947 6.10731C5.79043 5.64929 5.52376 5.07677 5.52376 4.42791C5.52376 4.38974 5.52376 4.38974 5.52376 4.35157C5.29519 4.27524 5.06662 4.16073 4.83805 4.12256C4.41901 4.00806 3.92378 3.89355 3.42854 3.89355C2.9714 3.89355 2.47617 3.96989 2.01903 4.12256C1.40951 4.3134 0.838088 4.58058 0.342854 4.92409C0.114285 5.07677 0 5.34394 0 5.61112V7.32869H4.11425C4.22853 7.17601 4.30472 7.09968 4.4571 6.98517Z"
							fill="#EBE9F0"
							fillOpacity="0.4"
						/>
					</svg>
					<div className={cl.info__count__values}>
						{data.users_cnt}
						<span>/</span>
						{data.max_users}
					</div>
				</div>
			</div>
		</div>
	);
};

export const CardEventLoader = ({ className = '' }: { className?: string }) => {
	return <div className={cl.card + ' skeleton ' + className}></div>;
};

export const CardEventEmpty = () => {
	const { t } = useTranslation();
	return (
		<div className={cl.card_empty}>
			<p className={cl.card_empty_title}>{t('no.events')}</p>
			<p className={cl.card_empty_desc}>{t('no.events.desc')}</p>
		</div>
	);
};
