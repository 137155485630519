import React from 'react';
import { useTranslation } from 'react-i18next';
import { Preview } from 'src/@types';
import { HeaderSection } from '../../ui/header-section/header-section.component';
import { CustomSlider } from './custom-slider/custom-slider.component';
import './section-posts.module.scss';
import cl from './section-posts.module.scss';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const Post = ({
	title,
	preview,
	className = '',
}: {
	preview: Preview;
	title: string;
	className?: string;
}) => {
	return (
		<article className={cl.card__post + ' ' + className}>
			<picture className={cl.card__post__image}>
				{preview.map((img) => (
					<source type={'image/' + img.type} srcSet={img.uri} key={img.type} />
				))}
				<img
					src={preview[preview.length - 1].uri}
					className={cl.card__post__image}
					alt=""
					loading="lazy"
					decoding="async"
				/>
			</picture>
			<p className={cl.card__post__info}>{title}</p>
		</article>
	);
};

const Grid = ({
	posts,
	reverse,
}: {
	posts: { preview: Preview; title: string }[];
	reverse?: {
		right?: boolean;
		left?: boolean;
		center?: boolean;
	};
}) => {
	return (
		<div className={cl.grid}>
			<div className={cl.col}>
				<Post
					title={posts[0].title}
					preview={posts[0].preview}
					className={reverse?.left ? cl.order : ''}
				/>
				<Post title={posts[1].title} preview={posts[1].preview} />
			</div>
			<div className={cl.col + ' ' + (posts.length === 6 ? cl.six : '')}>
				<Post
					title={posts[2].title}
					preview={posts[2].preview}
					className={reverse?.center ? cl.order : ''}
				/>
				{posts.length === 6 && (
					<Post title={posts[5].title} preview={posts[5].preview} />
				)}
			</div>
			<div className={cl.col + ' ' + (posts.length === 6 ? cl.six : '')}>
				<Post
					title={posts[3].title}
					preview={posts[3].preview}
					className={reverse?.right ? cl.order : ''}
				/>
				<Post title={posts[4].title} preview={posts[4].preview} />
			</div>
		</div>
	);
};

const Row = ({ posts }: { posts: { preview: Preview; title: string }[] }) => {
	return (
		<div className={cl.row}>
			{posts.map((item, index) => (
				<div className={cl.row__item} key={index + '-row'}>
					<Post title={item.title} preview={item.preview} />
				</div>
			))}
		</div>
	);
};

const Slider = ({
	posts,
}: {
	posts: { preview: Preview; title: string }[];
}) => {
	return <CustomSlider posts={posts} perPage={3} />;
};

export const SectionPosts = ({
	posts,
	title,
	description,
	reverse = {
		right: false,
		left: false,
		center: false,
	},
}: {
	posts: { preview: Preview; title: string }[];
	title: string;
	description: string;
	reverse?: {
		right?: boolean;
		left?: boolean;
		center?: boolean;
	};
}) => {
	const { i18n } = useTranslation();

	const InnerComponent = () => {
		return (
			<>
				<Slider posts={posts} />

				<section className={'container ' + cl.section}>
					<HeaderSection
						title={title}
						description={description}
						lang={i18n.language}
						smallTitle
					/>
					{posts.length === 5 || posts.length === 6 ? (
						<Grid posts={posts} reverse={reverse} />
					) : (
						<Row posts={posts} />
					)}
				</section>
			</>
		);
	};

	// Возвращаем InnerComponent
	return <InnerComponent />;
};
