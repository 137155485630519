import { ISvgIconProps } from '.';

export const BurgerIcon = ({
	className = '',
	viewBox = '0 0 30 30',
	refComponent = undefined,
}: ISvgIconProps) => {
	return (
		<svg
			className={className}
			ref={refComponent}
			viewBox={viewBox}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M3.5 6C5.41737 6.36364 7.33475 6.55528 9.25 6.68796C11.1674 6.83047 13.0847 6.88206 15 6.88943C16.9174 6.89189 18.8347 6.84029 20.75 6.69779C22.6674 6.56757 24.5847 6.37346 26.5 6L26.5 9.99755C24.5826 9.62408 22.6674 9.42998 20.75 9.29976C18.8326 9.15971 16.9153 9.10565 15 9.11057C13.0826 9.11548 11.1653 9.16953 9.25 9.31204C7.33263 9.44226 5.41525 9.63391 3.5 10L3.5 6.00245L3.5 6Z"
				fill="currentColor"
			/>
			<path
				d="M3.5 13C5.41737 13.3636 7.33475 13.5553 9.25 13.688C11.1674 13.8305 13.0847 13.8821 15 13.8894C16.9174 13.8919 18.8347 13.8403 20.75 13.6978C22.6674 13.5676 24.5847 13.3735 26.5 13L26.5 16.9975C24.5826 16.6241 22.6674 16.43 20.75 16.2998C18.8326 16.1597 16.9153 16.1057 15 16.1106C13.0826 16.1155 11.1653 16.1695 9.25 16.312C7.33263 16.4423 5.41525 16.6339 3.5 17L3.5 13.0025L3.5 13Z"
				fill="currentColor"
			/>
			<path
				d="M3.5 20C5.41737 20.3636 7.33475 20.5553 9.25 20.688C11.1674 20.8305 13.0847 20.8821 15 20.8894C16.9174 20.8919 18.8347 20.8403 20.75 20.6978C22.6674 20.5676 24.5847 20.3735 26.5 20L26.5 23.9975C24.5826 23.6241 22.6674 23.43 20.75 23.2998C18.8326 23.1597 16.9153 23.1057 15 23.1106C13.0826 23.1155 11.1653 23.1695 9.25 23.312C7.33263 23.4423 5.41525 23.6339 3.5 24L3.5 20.0025L3.5 20Z"
				fill="currentColor"
			/>
		</svg>
	);
};
