import { Preview } from 'src/@types';
import bg from './assets/bg.jpg';
import cl from './card-mission.module.scss';

export const CardMission = ({
	preview,
	title,
	subtitle,
}: {
	preview: Preview;
	title: string;
	subtitle: string;
}) => {
	return (
		<div
			className={cl.swiper__misson__slide}
			style={{ backgroundImage: `url(${bg})` }}
		>
			<picture className={cl.swiper__misson__slide__img}>
				{preview.map((img) => (
					<source type={'image/' + img.type} srcSet={img.uri} key={img.type} />
				))}
				<img
					src={preview[1].uri}
					className={cl.swiper__misson__slide__img}
					alt=""
					loading="lazy"
				/>
			</picture>

			<div className={cl.swiper__misson__slide__footer}>
				<h3 className={cl.swiper__misson__slide__title}>{title}</h3>
				<p className={cl.swiper__misson__slide__subtitle}>{subtitle}</p>
			</div>
		</div>
	);
};
