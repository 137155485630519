import { ISvgIconProps } from '.';

export const TgIcon = ({
	className = '',
	viewBox = '0 0 22 20',
	refComponent = undefined,
}: ISvgIconProps) => {
	return (
		<svg
			className={className}
			ref={refComponent}
			viewBox={viewBox}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M20.7151 0.50075C20.3276 0.17102 19.849 -0.000488281 19.3653 -0.000488281C19.0843 -0.000488281 18.8022 0.0574862 18.5345 0.175247L1.80368 7.54828C1.00476 7.90035 0.489727 8.72105 0.500155 9.62509C0.510584 10.5291 1.04474 11.3365 1.85176 11.6681L5.64529 13.228C5.65804 13.2334 5.67136 13.2376 5.68411 13.2431L6.76111 16.6611C6.89146 17.2028 7.21589 17.6884 7.69095 18.0018C8.04087 18.2325 8.43714 18.346 8.8311 18.346C9.3247 18.346 9.8154 18.1679 10.2111 17.8194L11.8188 16.4009L14.92 19.4053C15.3209 19.7936 15.8423 20.0007 16.3736 20.0007C16.5925 20.0007 16.8139 19.9657 17.0288 19.8932C17.7646 19.6456 18.3144 19.0031 18.4667 18.2132L21.459 2.66392C21.6149 1.8553 21.3287 1.02312 20.7157 0.501958L20.7151 0.50075ZM16.373 17.7741L11.8842 13.426L8.8311 16.1201L9.70764 12.67L16.1401 5.91291C16.3179 5.72631 16.165 5.4467 15.9576 5.4467C15.9147 5.4467 15.8695 5.45878 15.8243 5.48595L6.42799 11.1572L2.63445 9.59731L19.3653 2.22489L16.373 17.7741Z"
				fill="currentColor"
			/>
		</svg>
	);
};
