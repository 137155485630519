import cl from './location-info.module.scss';

interface LocationInfoProps {
	eventLocation: {
		title: string;
		address: string;
		map_link?: string;
	};
}

const LocationInfo = ({ eventLocation }: LocationInfoProps) =>
	eventLocation.map_link ? (
		<a
			href={eventLocation.map_link}
			target="_blank"
			rel="noopener noreferrer"
			className={cl.locations_wrapper}
		>
			<div className={cl.event_location}>{eventLocation.title}</div>
			<div className={cl.event_location_address}>{eventLocation.address}</div>
		</a>
	) : (
		<div className={cl.locations_wrapper}>
			<div className={cl.event_location}>{eventLocation.title}</div>
			<div className={cl.event_location_address}>{eventLocation.address}</div>
		</div>
	);

export default LocationInfo;
