import { ISvgIconProps } from '.';

export const WtIcon = ({
	className = '',
	viewBox = '0 0 26 26',
	refComponent = undefined,
}: ISvgIconProps) => {
	return (
		<svg
			className={className}
			ref={refComponent}
			viewBox={viewBox}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1.43542 12.6905C1.43542 6.2359 6.71243 1 13.2177 1C19.7295 1 25.0065 6.23589 25 12.6969C25 19.1515 19.7295 24.3874 13.2177 24.3874C11.1576 24.3874 9.21446 23.8587 7.52477 22.9301L1 25L3.1251 18.726C2.0528 16.9656 1.43542 14.9022 1.43542 12.6905ZM3.411 12.6968C3.411 7.32549 7.81067 2.96009 13.2177 2.96009C18.6311 2.96009 23.0308 7.32549 23.0243 12.7032C23.0243 18.0681 18.6181 22.4335 13.2112 22.4335C11.2225 22.4335 9.36388 21.8403 7.81717 20.8215L4.04788 22.0208L5.27615 18.4034C4.10637 16.7978 3.411 14.8247 3.411 12.6968Z"
				fill="currentColor"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.5279 7.53857C10.3005 6.93244 10.047 7.02271 9.87155 7.01627C9.69608 7.00982 9.49462 6.97758 9.29316 6.97113C9.0917 6.96468 8.76676 7.02916 8.47431 7.31288C8.18837 7.60305 7.38252 8.293 7.33053 9.76318C7.27204 11.2334 8.29885 12.6906 8.44182 12.897C8.58479 13.1033 10.398 16.2823 13.4069 17.5912C16.4223 18.9002 16.4353 18.5004 16.9877 18.4746C17.5401 18.4488 18.7944 17.8169 19.0738 17.1334C19.3533 16.4435 19.3728 15.8502 19.3013 15.7213C19.2298 15.5923 19.0348 15.5085 18.7359 15.3537C18.4434 15.1925 16.9877 14.4187 16.7148 14.3091C16.4418 14.1995 16.2469 14.1415 16.0324 14.4316C15.8179 14.7218 15.22 15.3731 15.0381 15.5665C14.8561 15.76 14.6806 15.7793 14.3817 15.6181C14.0893 15.4569 13.1274 15.1087 12.0161 14.0447C11.1518 13.2194 10.5864 12.2199 10.4239 11.9169C10.2615 11.6138 10.4239 11.459 10.5799 11.3107C10.7229 11.1818 10.8919 10.9754 11.0478 10.8013C11.2038 10.6337 11.2623 10.5112 11.3663 10.3177C11.4768 10.1243 11.4313 9.94373 11.3598 9.79542C11.3078 9.64066 10.7619 8.14469 10.5279 7.53857Z"
				fill="currentColor"
			/>
		</svg>
	);
};
