import { ISvgIconProps } from '.';

export const ArrowRightTwoIcon = ({
	className = '',
	viewBox = '0 0 24 24',
	refComponent = undefined,
}: ISvgIconProps) => {
	return (
		<svg
			width="24"
			className={className}
			ref={refComponent}
			height="24"
			viewBox={viewBox}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12 24C12 17.3726 17.3726 12 24 12"
				stroke="currentColor"
				strokeWidth="4"
			/>
			<path
				d="M12 1.90719e-07C12 6.62742 17.3726 12 24 12"
				stroke="currentColor"
				strokeWidth="4"
			/>
			<path d="M24 12L0 12" stroke="currentColor" strokeWidth="4" />
		</svg>
	);
};
